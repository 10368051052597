import React, { useState,useEffect } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable'; // To create tables easily in jsPDF
import moment from 'moment';

const PdfDownload = ({ reportData, reportName, totalSummary }) => {


    const [isDownloading, setIsDownloading] = useState(false);

    const handlePdfDownload = () => {

        if (isDownloading || !reportData || reportData.length === 0) return; // Prevent multiple downloads or invalid data
        setIsDownloading(true);


        // Check if reportData is empty
        if (!reportData || reportData.length === 0) {
            alert('No data available for download.');
            setIsDownloading(false); // Reset downloading state
            return;
        }

        // Create a new jsPDF instance
        const doc = new jsPDF('l', 'pt'); // 'l' for landscape

        // Define headers and rows based on reportName
        let headers = [];
        let rows = [];

        switch (reportName) {
            case 'Stoppage Report':
                headers = [
                    'Display Number',
                    'Start Stop Time',
                    'End Stop Time',
                    'Duration',
                    'Duration In Minutes',
                    'Location Co-ordinates'
                ];
                rows = reportData.map(item => [
                    item.displayNumber,
                    item.startStopTime,
                    item.endStopTime,
                    item.duration,
                    item.durationInMinutes,
                    `${item.lat}, ${item.lon}`
                ]);
                break;

            case 'Idle Report':
                headers = [
                    'Display Number',
                    'Start Idle Time',
                    'End Idle Time',
                    'Duration',
                    'Duration In Minutes',
                    'Latitude',
                    'Longitude'
                ];
                rows = reportData.map(item => [
                    item.displayNumber,
                    item.startIdleTime,
                    item.endIdleTime,
                    item.duration,
                    item.durationInMinutes,
                    item.lat,
                    item.lon,
                ]);
                break;

            case 'Moving Report':
                headers = [
                    'Vehicle Number',
                    'Moving Start Time',
                    'Moving End Time',
                    'Distance',
                    'Duration',
                    'Duration In Minutes',
                    'Start Location Coordinates',
                    'End Location Coordinates',
                    'Stoppage Duration',
                    'Stoppage Duration In Minutes'
                ];
                rows = reportData.map(item => [
                    item.displayNumber,
                    item.startMovingTime,
                    item.endMovingTime,
                    item.distance,
                    item.duration,
                    item.durationInMinutes,
                    `(${item.startLocation.lat}, ${item.startLocation.lon})`,
                    `(${item.endLocation.lat}, ${item.endLocation.lon})`,
                    item.stoppageDuration,
                    item.stoppageDurationInMinutes
                ]);
                
                // Add total summary row
                rows.push([
                    'Total',
                    '',
                    '',
                    totalSummary.totalDistance,
                    totalSummary.totalMovingDuration,
                    totalSummary.totalMovingDurationInMinutes, 
                    '',
                    '',
                    totalSummary.totalStoppageDuration,
                    totalSummary.totalStoppageDurationInMinutes
                ]);
                break;
                case 'Movement Summary Report':
                    headers = [
                                'VehicleNumber',
                                'StartDate',
                                'EndDate',                                
                                'MovingTime',
                                'IdleTime',
                                'StoppedTime',
                                'DistanceInTravelled',
                                
                                'FuelConsumed',
                                'FuelEfficiency'
                            ];
                            rows = reportData.map(item => [
                              item.displayNumber,
                              
                             moment(item.startTime).format('YYYY-MM-DD'),
                             moment(item.endTime).format('YYYY-MM-DD'),
                             item.moving,
                              item.idle,
                             item.stopped,
                             item.totalDistance,
                             item.FuelConsumed || 'N/A',
                             item.FuelEfficiency || 'N/A'
                            ]);
            break;
            case 'Distance Report':
                headers = [
                    'Vehicle Number',
                    'Date',
                    'Distance',
                ];
                rows = reportData.map(item => ([
                    item.vehicle,
                    item.date,  // Ensure this matches your data property name
                    item.totalDistance,
                ]));
            
                // Add total summary as the last row
                rows.push([
                    'Total',
                    '',  // If you don't need a date for total, leave this empty
                    totalSummary  // Total distance across all dates
                ]);
                break;
                case 'Geofence Report':
                    headers = [
                        'Vehicle Number',
                        'Geofence Name',
                        'Entry Time',
                        'Exit Time',
                        'Total Duration'
                    ];
                    rows = reportData.map(item => ([
                        item.display_number, // Use totalSummary for vehicle number
                        item.geofence_name, // Use totalSummary for total distance
                        item.entryTime, // Assuming item contains date
                        item.exitTime,
                        item.totalDuration // Assuming item contains distance
                    ]));
                
               break;


            // Add other cases for different report types similarly...

            default:
                console.error('Unknown report name');
                setIsDownloading(false); // Reset downloading state
                return; // Exit if unknown report name
        }

       // Calculate column widths based on number of headers
       const pageWidth = doc.internal.pageSize.getWidth();
       const margin = 20; // Adjust as needed
       const availableWidth = pageWidth - margin * 2; // Adjust for margins
       const columnWidth = availableWidth / headers.length; // Divide available width by the number of columns

       // Create column styles
       const columnStyles = {};
       headers.forEach((_, index) => {
           columnStyles[index] = { cellWidth: columnWidth }; // Set uniform column width
       });

       doc.autoTable({
           head: [headers],
           body: rows,
           startY: 20, // Start position for the table
           theme: 'grid', // Use a grid theme to have borders
           styles: {
               cellPadding: 5,
               fontSize: 10,
           },
           columnStyles: columnStyles, // Apply the calculated column styles
           margin: { top: 20,right :20 }, // Adjust margins as needed
           pageBreak: 'auto', // Handle page breaks automatically
        });
        

        // Save the generated PDF
        doc.save(`${reportName}.pdf`);

           setIsDownloading(false);
    };

    
    useEffect(() => {
        // Only call handleExcelDownload when reportData is valid and available
        if (!isDownloading && reportData && reportData.length > 0) {
            handlePdfDownload();
        }
    }, [reportData]); // Call when reportData changes
    return null;
};

export default PdfDownload;
