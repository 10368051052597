import React, { useState,useEffect } from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment-timezone';

const ExcelDownload = ({ reportData, reportName ,totalSummary}) => {

    const [isDownloading, setIsDownloading] = useState(false);



    console.log(reportData,"reportData");
    console.log(reportName,"reportName");
 
    const handleExcelDownload = () => {
     
            if (isDownloading || !reportData || reportData.length === 0) return; // Prevent multiple downloads or invalid data
            setIsDownloading(true);

   

         // Check if reportData is empty
    if (!reportData || !reportData.dateWiseDistances === 0) {
        toast.error('No data available for download.'); // Use toast for user notification
        setIsDownloading(false); // Reset downloading state
        return; // Exit the function if there's no data
    }
        let formattedData = [];
        let headers = [];

        switch (reportName) {
            case 'Stoppage Report':
                headers = [
                    'Display Number',
                    'StartStopTime',
                    'EndStopTime',
                    'Duration',
                    'durationInMinutes',
                   'Location Co-ordinates'
                ];
                formattedData = reportData.map(item => ({
                    'Display Number': item.displayNumber,
                    'StartStopTime':  item.startStopTime,
                    'EndStopTime':  item.endStopTime,
                    'Duration':  item.duration,
                    'durationInMinutes':item.durationInMinutes,
                   'Location Co-ordinates':`${item.lat}, ${item.lon}`,   
                   
                }));
                break;
            case 'Idle Report':      
                       
                headers = [
                    'Display Number',
                    'Start Idle Time',
                    'End Idle Time',
                    'Duration',
                    'Duration In Minutes',
                    'Latitude',
                    'Longitude'
                ];
                formattedData = reportData.map(item => ({
                    'Display Number': item.displayNumber,
                    'Start Idle Time': item.startIdleTime,
                    'End Idle Time': item.endIdleTime,
                    'Duration': item.duration,
                    'Duration In Minutes': item.durationInMinutes,
                    'Latitude': item.lat,
                    'Longitude': item.lon,
                }));
                break;
                case 'Moving Report':
                    // headers = [
                    //     'Display Number',
                    //     'MovingStartTime',
                    //     'MovingEndTime',
                    //     'Distance',
                    //     'Duration',
                    //     'DurationInMinutes',                        
                    //     'Start Location',
                    //     'End Location',  
                    //     'TotalDistance',                      
                    //     'Stoppage Duration',
                    //     'StoppageDurationInMinutes',
                    //     'TotalMovingDuration',
                    //     'TotalMovingDurationInMinutes',
                    //      'TotalStoppageDurationInMinutes',
                        
                       
                    // ];
                    formattedData = reportData.map(item => ({
                                VehicleNumber: item.displayNumber,
                                MovingStartTime: item.startMovingTime,       
                                MovingEndTime: item.endMovingTime,
                                Distance: item.distance,
                                Duration: item.duration,       
                                DurationInMinutes: item.durationInMinutes,
                                StartLocationCoordinates: `(${item.startLocation.lat}, ${item.startLocation.lon})`,
                                EndLocationCoordinates: `(${item.endLocation.lat}, ${item.endLocation.lon})`,
                                StoppageDuration: item.stoppageDuration,
                                StoppageDurationInMinutes: item.stoppageDurationInMinutes
                    }));
    
                    // Add total summary at the end
                    formattedData.push({
                        VehicleNumber: 'Total',
                        MovingStartTime: '',
                        MovingEndTime: '',
                        Distance: totalSummary.totalDistance,
                        Duration: totalSummary.totalMovingDuration,
                        DurationInMinutes: totalSummary.totalMovingDurationInMinutes,
                        StartLocationCoordinates: '',
                        EndLocationCoordinates: '',
                        StoppageDuration: totalSummary.totalStoppageDuration,
                        StoppageDurationInMinutes: totalSummary.totalStoppageDurationInMinutes
                    });
                    break;
    
            case 'Movement Summary Report':
                // headers = [
                //     'Display Number',
                //     'Start Time',
                //     'End Time',
                //     'Idle Time',
                //     'Moving Time',
                //     'Stopped Time',
                //     'Total Distance',
                //     'Fuel Efficiency',
                //     'Vehicle Number'
                // ];
                formattedData = reportData.map(item => ({
                    VehicleNumber: item.displayNumber,
                //   Date: item.date,
                StartDate: moment(item.startTime).format('YYYY-MM-DD'),
                EndDate: moment(item.endTime).format('YYYY-MM-DD'),
                MovingTime: item.moving,
                IdleTime: item.idle,
               StoppedTime: item.stopped,
               DistanceInTravelled: item.totalDistance,
               FuelConsumed: item.FuelConsumed || 'N/A',
               FuelEfficiency: item.FuelEfficiency || 'N/A'
                }));
                break;
            case 'Distance Report':
                formattedData = reportData.map(item => ({
                    'Date': moment(item.date).format('YYYY-MM-DD'),
                    'Vehicle Number': item.vehicle,
                    'Total Distance': item.totalDistance
                }));

                // Add total distance for all dates at the end
                formattedData.push({
                    'Date': 'Total',
                    'Vehicle Number': '',
                    'Total Distance': totalSummary
                });
               
               
                break;
            case 'Geofence Report':
                headers = [
                    'Vehicle Number',
                    'Geofence Name',
                    'EntryTime',
                    'exitTime',
                    'duration'
                    
                ];
                formattedData =reportData.map(item =>({
                     'Vehicle Number' :item.display_number,
                    'Geofence Name':item.geofence_name,
                    'EntryTime':item.entryTime,
                    'exitTime':item.exitTime,
                    'duration':item.totalDuration
                }))
                 break;
            default:
                console.error('Unknown report name');
                setIsDownloading(false); // Reset downloading state
                return; // Exit if unknown report name
        }

        // Create worksheet and workbook
        const worksheet = XLSX.utils.json_to_sheet(formattedData, { header: headers });
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, reportName);

        // Write the workbook to a file
        XLSX.writeFile(workbook, `${reportName}.xlsx`);

        setIsDownloading(false);
    };

    useEffect(() => {
        // Only trigger download if data is available
        if (reportData && reportData.length > 0) {
            handleExcelDownload();
        } else {
            toast.error('No data available for download.');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportData, reportName]);
return null;
};

export default ExcelDownload;
