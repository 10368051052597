import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import config from "../../config";

const TripAlerts = () => {
  const [alerts, setAlerts] = useState([]);
  const [error, setError] = useState(null);
  const [showAlerts, setShowAlerts] = useState(false);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    fetchAlerts();
    // const intervalId = setInterval(fetchAlerts, 60000); // Check every 60 seconds

    // return () => clearInterval(intervalId);
  }, []);

  const fetchAlerts = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${config.apiUrl}/trip/check-trip-alerts`
      );
     
      setAlerts(response.data);
      console.log("Fetched Alerts:", response.data);
    
    } catch (err) {
      console.error("Error details:", err.response || err.message || err);
      setError("Error fetching trip alerts.");
    }
  };

  const handleClose = async (id) => {
    try {
      await axios.post(`${config.apiUrl}/trip/update-trip-alert/${id}`);

      setAlerts((prevAlerts) => {
        const updatedAlerts = prevAlerts.filter((alert) => alert.id !== id);
        console.log("Updated Alerts:", updatedAlerts);
        return updatedAlerts;
      });

      console.log("Alert closed and updated in the backend with id:", id);
    } catch (err) {
      console.error(
        "Error updating alert in the backend:",
        err.response || err.message || err
      );
      setError("Error closing alert.");
    }
  };

  const handleToggleAlerts = () => {
    setShowAlerts((prevShowAlerts) => !prevShowAlerts);
  };

  return (
    <div className="relative">
      <button
        onClick={handleToggleAlerts}
        className="fixed top-8 right-24 bg-yellow-300 text-white rounded-full p-3 shadow-lg hover:bg-yellow-400"
        aria-label="Toggle alerts"
      >
        <FontAwesomeIcon icon={faBell} className="text-xl" />
        {alerts.length > 0 && (
          <span className="absolute top-0 right-0 bg-red-500 text-white rounded-full text-xs w-5 h-5 flex items-center justify-center">
            {alerts.length}
          </span>
        )}
      </button>

      {showAlerts && (
        <>
          {/* Backdrop */}
          <div
         className="fixed inset-0 bg-black opacity-50 z-20"
            onClick={handleToggleAlerts}
          ></div>

          {/* Modal Content */}
          <div className="fixed top-16 right-24 w-96 bg-white p-4 shadow-lg border rounded z-30 min-h-20 overflow-y-auto">
            {/* Loading Spinner */}
            {/* {loading && <p className="text-center">Loading...</p>}

            {/* Error Message */}
            {/* {error && <p className="text-red-600 mb-4">{error}</p>} */} 

            {/* Alerts List */}
            {alerts.length === 0 ? (
              <p>No active alerts.</p>
            ) : (
              alerts.map((alert) => (
                <div
                  key={alert.id}
                  className={`p-4 border rounded-lg ${
                    alert.reached_source || alert.reached_destination
                      ? "bg-green-100 border-green-400"
                      : "bg-red-100 border-red-400"
                  } relative shadow-xl`}
                >
                  {/* Close Button */}
                  <button
                    onClick={() => handleClose(alert.id)}
                    className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                    aria-label={`Close alert ${alert.id}`}
                  >
                    &times;
                  </button>

                  {/* Alert Content */}
                  <p
                    className={`font-bold text-lg mb-2 ${
                      alert.reached_source || alert.reached_destination
                        ? "text-green-800"
                        : "text-red-800"
                    }`}
                  >
                    <span className="text-blue-600 text-sm">{alert.vehicle}</span>{" "}
                    {alert.reached_source && !alert.reached_destination ? (
                      <>
                        <span className="text-green-600">has reached</span>{" "}
                        <span className="font-semibold text-sm">{alert.source_address}</span>,{" "}
                        <span className="text-red-600">but has not reached</span>{" "}
                        <span className="font-semibold text-sm">{alert.destination_address}</span>
                      </>
                    ) : alert.reached_destination && !alert.reached_source ? (
                      <>
                        <span className="text-red-600">has not reached</span>{" "}
                        <span className="font-semibold text-sm">{alert.source_address}</span>,{" "}
                        <span className="text-green-600">but has reached</span>{" "}
                        <span className="font-semibold text-sm">{alert.destination_address}</span>
                      </>
                    ) : alert.reached_source && alert.reached_destination ? (
                      <>
                        <span className="text-green-600">has reached both</span>{" "}
                        <span className="font-semibold text-sm">{alert.source_address}</span>{" "}
                        <span className="text-green-600">and</span>{" "}
                        <span className="font-semibold text-sm">{alert.destination_address}</span>
                      </>
                    ) : (
                      <>
                        <span className="text-red-600">has not reached either</span>{" "}
                        <span className="font-semibold text-sm">{alert.source_address}</span>{" "}
                        <span className="text-red-600">or</span>{" "}
                        <span className="font-semibold text-sm">{alert.destination_address}</span>
                      </>
                    )}
                  </p>
                </div>
              ))
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default TripAlerts;
