
import React, { useState, useEffect } from 'react';
import {  useNavigate } from 'react-router-dom';
import {  FaSignOutAlt } from 'react-icons/fa';
import config from "../config";
import profileImg from '../assests/profile.png';

const UserProfile = ({ user }) => {
  const navigate = useNavigate();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleLogout = async () => {
    const logoutEventData = {
      userId: user.user.user_id,
      userFirstName: user.user.user_first_name,
      userLastName: user.user.user_last_name,
      userRole: user.user.user_role,
    };
    setIsButtonDisabled(true);
    try {
      const response = await fetch(`${config.apiUrl}/user/logout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(logoutEventData),
      });

      const result = await response.json();

      if (response.status === 200) {
        localStorage.removeItem("user");
        sessionStorage.removeItem('authentication')
        navigate("/");
        setIsButtonDisabled(false);
      } else {
        console.error("Error logging logout event:", result.message);
      }
    } catch (error) {
      console.error("Error logging logout event:", error.message);
    }
  };

  return (
    <div className="flex-grow-0 font-serif">
      <div className="flex items-center">
        <div>
          <p className="text-xs sm:text-sm">
            <p>{user ? ` ${user.user.user_role}` : "Guest"}</p>
          
          <p className="text-[10px] sm:text-sm">{` ${user.user.user_first_name} ${user.user.user_last_name} `}</p>
          </p>
          </div>
      
        <img
          src={profileImg}
          alt="Profile"
          className="w-7 h-7 sm:w-14 sm:h-14 mr-1 rounded-full"
        />
        <div className="mt-2 mr-9">
          <button
            className="bg-transparent border-none focus:outline-none"
            onClick={handleLogout}
            disabled={isButtonDisabled}
          >
            <FaSignOutAlt />
          </button>
        </div>
      </div>
    </div>
  );
};

const ProfileModule = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user"));
    if (userData) {
      setUser(userData);
    }
  }, []);

  return (
    <div >         
          <div >
            {user && <UserProfile user={user} />}
          </div>
    </div>
  );
};


export default ProfileModule;