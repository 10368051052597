// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './Components/Login';
import MapHome from './Components/Vehicle';
import Sidebar from './Components/Sidebar';
import VehicleInventory from './Components/VehicleInventory';
import VehicleGroups from './Components/VehicleGroups';
import Drivers from './Components/Drivers';
import VehicleDetails from './Components/VehicleDetails';
import Dashboard from './Components/Dashboard';


import Trip from './Components/Trip/Trip';
import TripSchedule from './Components/Trip/TripSchedule';
import HistoryReport from './Components/Reports/HistoryReport';
import TripRoute from './Components/Trip/TripRoute'
import Report from './Components/Reports/Report';
import GeofenceApp from './Components/Geofence/GeofenceApp';
import GeofenceMap from './Components/Geofence/GeofenceMap';

function App() {
  return (
    <Router>
      <Routes  >
        <Route path="/" element={<Login />} />
        <Route path="/*" element={<Sidebar />}>
        <Route path="AllVehicles" element={<MapHome />} /> 
        <Route path="Drivers" element={<Drivers />} /> 
        <Route path="VehicleInventory" element={<VehicleInventory />} /> 
        <Route path="VehicleGroups" element={<VehicleGroups />} />
        <Route path="VehicleDetails" element={<VehicleDetails />} />
        <Route path="geofence" element={<GeofenceMap />} />
        <Route path="dashboard" element={<Dashboard />} />
 <Route path="Routes" element={<TripRoute />} />
 
       
        <Route path="Alltrips" element={<Trip />} />
        <Route path="TripsSchedules" element={<TripSchedule />} />
        <Route path="DayWiseHistoryReport" element={<HistoryReport />} />
       
   
        <Route path="Report" element={<Report />} />
 
        </Route>
      </Routes>
    </Router>
  );
}

export default App;