import React, { useState, useEffect } from "react";
import ProfileModule from "../UserProfile";
import axios from 'axios';
import {
  FaSearch,
  FaUpload,
  FaPlus,
  FaDownload,
  FaInfoCircle,
  FaLessThan,
  FaCalendarAlt,
} from "react-icons/fa";
import Select from "react-select";
import { MapContainer, TileLayer, Marker, Popup, Polyline } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import L from 'leaflet';
import { config } from "@fortawesome/fontawesome-svg-core";

function TripRoute() {
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [time, setTime] = useState(null);
  const [open, setOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showreachTooltip, setShowreachTooltip] = useState(false);
  const [showCheckpoints, setShowCheckpoints] = useState(false);
  const [checkpoints, setCheckpoints] = useState([
    {
      address: "",
      arrivalTime: null,
      departureTime: null,
      days: "",
      showDaysInput: false,
      suggestions: [],
      selectedOption: "", // To track which option is selected
    },
  ]);
  const [destinationAddress, setDestinationAddress] = useState("");
  const [arrivalTime, setArrivalTime] = useState('');
  const [departureTime, setDepartureTime] = useState('');
  const [showDaysInput, setShowDaysInput] = useState(false);
  const [days, setDays] = useState("");
  const [isshowActive, setIsShowActive] = useState(false);
  const [sourceArrivalTime, setSourceArrivalTime] = useState(null);
  const [sourceDepartureTime, setSourceDepartureTime] = useState(null);
  const [sourceSuggestions, setSourceSuggestions] = useState([]);
  const [destinationSuggestions, setDestinationSuggestions] = useState([]);
  const [loadingSource, setLoadingSource] = useState(false);
  const [loadingDestination, setLoadingDestination] = useState(false);
  
  const [noSourceSuggestions, setNoSourceSuggestions] = useState(false);
  const [noDestinationSuggestions, setNoDestinationSuggestions] = useState(false);
  // State for destination
  const [destinationArrivalTime, setDestinationArrivalTime] = useState(null);
  const [destinationDepartureTime, setDestinationDepartureTime] = useState(null);

  const [sourceAddress, setSourceAddress] = useState("");
  const [position, setPosition] = useState([9.939093, 78.121719]);
  const [suggestions, setSuggestions] = useState([]);
  const [noSuggestions, setNoSuggestions] = useState(false);
  const [isUserInput, setIsUserInput] = useState(true);
  const [callApi, setCallApi] = useState(false);
  const [streetName, setStreetName] = useState("");
  const [hasInitialized, setHasInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [routeName, setRouteName] = useState('');
  const [tags, setTags] = useState('');
  const [scheduledDistance, setScheduledDistance] = useState('');
  const [paths, setPaths] = useState([{ pathno: 1, active: true, name: "Main Path" }]);
  const [source, setSource] = useState(null);
  const [destination, setDestination] = useState(null);

  const OPENCAGE_API_KEY = '062d4cf071d04326b6d1312fbfda0cbd';
  const OPENCAGE_URL = 'https://api.opencagedata.com/geocode/v1/json';

  const getLatLonFromAddress = async (address) => {
    try {
      const response = await axios.get(OPENCAGE_URL, {
        params: {
          q: address,
          key: OPENCAGE_API_KEY,
        },
      });

      const results = response.data.results;
      if (results.length > 0) {
        const { lat, lng } = results[0].geometry;
        return { lat, lon: lng };
      } else {
        throw new Error('No results found');
      }
    } catch (error) {
      console.error('Error fetching latitude and longitude:', error.message);
      throw error;
    }
  };
  useEffect(() => {
    const fetchLatLon = async () => {
      if (sourceAddress.trim()) {
        try {
          const { lat, lon } = await getLatLonFromAddress(sourceAddress);
          setSource({ lat, lon });
        } catch (error) {
          console.error('Error fetching source address coordinates:', error);
        }
      }
      if (destinationAddress.trim()) {
        try {
          const { lat, lon } = await getLatLonFromAddress(destinationAddress);
          setDestination({ lat, lon });
        } catch (error) {
          console.error('Error fetching destination address coordinates:', error);
        }
      }
    };
  
    fetchLatLon();
  }, [sourceAddress, destinationAddress]);

  const route = source && destination ? [source, destination] : [];
  const routePath = route.map(point => [point.lat, point.lon]);

  

  useEffect(() => {
    if (!callApi && !hasInitialized) {
      if ((isUserInput || streetName.length > 10) && !!streetName) {
        const handler = setTimeout(() => {
          fetchSuggestions();
        }, 1000);

        return () => {
          clearTimeout(handler);
        };
      }
    }
  }, [streetName, isUserInput, callApi]);

  const fetchSuggestions = async (query, setSuggestions, setLoading, setNoSuggestions) => {
    if (query.length < 3) {
      setSuggestions([]);
      setNoSuggestions(false);
      return;
    }
  
    setLoading(true);
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(query)}&addressdetails=1&limit=5`
      );
      const data = await response.json();
      setLoading(false);
  
      if (data.length > 0) {
        setSuggestions(data);
        setNoSuggestions(false);
      } else {
        setSuggestions([]);
        setNoSuggestions(true);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching suggestions:", error);
    }
  };
  

  const handleSourceAddressChange = (e) => {
    const value = e.target.value;
    setSourceAddress(value);
    fetchSuggestions(value, setSourceSuggestions, setLoadingSource, setNoSourceSuggestions);
  };

  // Function to handle destination address change
  const handleDestinationAddressChange = (e) => {
    const value = e.target.value;
    setDestinationAddress(value);
    fetchSuggestions(value, setDestinationSuggestions, setLoadingDestination, setNoDestinationSuggestions);
  };

  const handleSourceSelect = (address) => {
    setSourceAddress(address);
    setSourceSuggestions([]);
  };

  const handleDestinationSelect = (address) => {
    setDestinationAddress(address);
    setDestinationSuggestions([]);
  };





  const handleOpenModal = () => {
    setIsFilterModalOpen(true);
  };
 

  const handleCloseModal = () => {
    setIsFilterModalOpen(false);
    setShowModal(false);
  };

  const handleToggle = () => {
    event.preventDefault();
    setIsActive(!isActive);
  };

  const CustomInput = ({ value, onClick }) => (
    <div className="relative">
      <input
        type="text"
        value={value}
        onClick={onClick}
        className="mt-1 block w-full p-2 pr-10 border border-gray-300 rounded-md"
        readOnly
        placeholder="Select Time"
      />
      <FaCalendarAlt
        onClick={onClick}
        className="absolute right-3 top-2/4 transform -translate-y-1/2 text-gray-500 cursor-pointer"
      />
    </div>
  );

  // const handleArrivalChange = (date) => {
  //   setArrivalTime(date);
  // };

  // const handleDepartureChange = (date) => {
  //   // Ensure departure time cannot be before arrival time
  //   if (!arrivalTime || date >= arrivalTime) {
  //     setDepartureTime(date);
  //   } else {
  //     // Optionally, show an error or warning
  //     alert("Departure time cannot be before arrival time.");
  //   }
  // };

  const toggleCheckpoints = () => {
    setShowCheckpoints(!showCheckpoints);
  };

  const handleCheckpointChange = (index, field, value) => {
    const newCheckpoints = [...checkpoints];
    newCheckpoints[index][field] = value;
    setCheckpoints(newCheckpoints);
  };

  const addCheckpoint = () => {
    if (checkpoints[checkpoints.length - 1].address) {
      setCheckpoints([
        ...checkpoints,
        {
          address: "",
          arrivalTime: null,
          departureTime: null,
          days: "",
          showDaysInput: false,
          suggestions: [],
          selectedOption: "", // Reset the option for the new checkpoint
        },
      ]);
    } else {
      alert("Please fill in the current checkpoint before adding a new one.");
    }
  };

  const handleOptionChange = (index, option) => {
    const newCheckpoints = [...checkpoints];
    newCheckpoints[index].selectedOption = option;
    // Ensure other options are not selected
    setCheckpoints(newCheckpoints);
  };

  const handleRemoveCheckpoint = (index) => {
    // Remove the checkpoint at the specified index
    const newCheckpoints = checkpoints.filter((_, i) => i !== index);
    setCheckpoints(newCheckpoints);
  };

  // const toggleDaysInput = (index) => {
  //   const newCheckpoints = [...checkpoints];
  //   newCheckpoints[index].showDaysInput = !newCheckpoints[index].showDaysInput;
  //   setCheckpoints(newCheckpoints);
  // };

 
  const handleCheckpointAddressChange = (index, value) => {
    const newCheckpoints = [...checkpoints];
    newCheckpoints[index].address = value;
    setCheckpoints(newCheckpoints);
  
    // Fetch suggestions for the specific checkpoint
    fetchSuggestions(value, 
      (suggestions) => {
        const updatedCheckpoints = [...checkpoints];
        updatedCheckpoints[index].suggestions = suggestions;
        setCheckpoints(updatedCheckpoints);
      },
      setLoading, 
      setNoSuggestions
    );
  };
  

  const handleSuggestionSelect = (index, address) => {
    const newCheckpoints = [...checkpoints];
    newCheckpoints[index].address = address;
    newCheckpoints[index].suggestions = [];
    setCheckpoints(newCheckpoints);
  };

  const handleDaysInputToggle = () => {
    setShowDaysInput(!showDaysInput);
  };
  const handleRoutesToggle = (index) => {
    const updatedPaths = [...paths];
    updatedPaths[index].active = !updatedPaths[index].active;
    setPaths(updatedPaths);
  };

  const handleRenamePath = (index) => {
    const newPathName = prompt("Enter new path name:", paths[index].name);
    if (newPathName) {
      const updatedPaths = [...paths];
      updatedPaths[index].name = newPathName;
      setPaths(updatedPaths);
    }
  };

  const handleEditPath = (index) => {
    // Handle path editing logic here
    console.log(`Editing ${paths[index].name}`);
  };

  const addNewPath = () => {
    setPaths([...paths, { name: `Path: ${paths.length + 1}`, active: false }]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const response = await axios.post(`${config.apiUrl}/routes`, {
            active: isActive,
            route_name: routeName,
            tags,
            scheduled_distance: parseFloat(scheduledDistance),
            source_address: [sourceAddress, sourceArrivalTime, sourceDepartureTime],
            checkpoints: checkpoints.map((cp) => ({
              address: cp.address,
              selectedOption: cp.selectedOption,
              custom_no_days: cp.selectedOption === "customDays" ? cp.days : null,
              scheduled_time_of_arrival: cp.arrivalTime,
              scheduled_time_of_departure: cp.departureTime,
            })),
            destination: [destinationAddress, destinationArrivalTime, destinationDepartureTime],
            path: paths,
       
      });
      console.log(response.data);
      // Handle successful response (e.g., show a success message, clear form, etc.)
    } catch (error) {
      console.error('Error submitting form:', error);
      // Handle error (e.g., show an error message)
    }
  };

 


  return (
    <div>
      <div className="flex justify-between items-center h-[10vh] border-b-2 border-gray-300">
        <div className="flex flex-col items-center">
          <h3>TRIP</h3>
          <h2 className="text-lg font-semibold text-gray-500 mb-2 text-center">
            Routes
          </h2>
        </div>
        <div className="pl-2">
          <ProfileModule />
        </div>
      </div>
      <div className="flex justify-start items-center mt-4 px-4">
        <div className="relative flex items-center w-full max-w-md mr-5">
          <input
            type="text"
            className="w-full p-2 border border-gray-300 rounded-md pl-10"
            placeholder="Search for routes, checkpoints, etc."
          />
          <FaSearch className="absolute right-3 text-gray-500" />
        </div>
        <button
          className="bg-blue-500 text-white p-2 rounded-3xl mr-8 flex items-center"
          onClick={handleOpenModal}
        >
          <FaLessThan className="mr-2" />
          Filter
        </button>
        <button className="bg-white text-black p-2 rounded-3xl flex items-center mr-8 ">
          <FaUpload className="mr-2" /> Bulk Upload
        </button>
        <button
          className="bg-blue-500 text-white p-2 rounded-3xl flex items-center mr-8"
          onClick={() => setShowModal(true)}
        >
          <FaPlus className="mr-2" /> Add Vehicle Route
        </button>
        <button className="bg-gray-500 text-white p-2 rounded-3xl flex items-center">
          <FaDownload className="mr-2" /> Download
        </button>
      </div>
      {isFilterModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-end">
          <div className="bg-white w-96 h-full shadow-xl p-6">
            <div className="flex justify-between items-center mb-10">
              <h2 className="text-2xl font-semibold">Filters</h2>
              <button
                className="text-red-500 text-2xl font-bold "
                onClick={handleCloseModal}
              >
                &times;
              </button>
            </div>
            <div className="mt-4 space-y-4">
              <div>
                <label className="block text-sm font-medium">
                  Route Status
                </label>
                <select className="mt-1 p-2 w-full border rounded mb-10">
                  <option>Active Routes</option>
                  <option>Inactive Routes</option>
                  <option>All Routes</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium mb-2">
                  CreatedBy
                </label>
                <select className="mt-1 p-2 w-full border rounded mb-10">
                  <option>1TransportName</option>
                  <option>2TransportName</option>
                  <option>3TransportName</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium mb-2">Tags</label>
                <select className="mt-1 p-2 w-full border rounded mb-24">
                  <option>Test1Tag</option>
                  <option>Test2Tag</option>
                  <option>Test3Tag</option>
                </select>
              </div>
              <div className="flex justify-between mb-2">
                <button className="px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded-lg mb-4">
                  Clear All
                </button>
                <button className="px-4 py-2 bg-blue-500 text-white hover:bg-blue-600 rounded-lg mb-4">
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showModal && (
        <div className="fixed inset-0 flex outline-none focus:outline-none bg-white bg-opacity-80 ">
          <div className="w-2/4 mt-16 ml-16 overflow-y-auto p-4 bg-white shadow-lg rounded-lg">
            {/* Form Fields Here */}
            <h2 className="text-lg font-bold mb-6">Your Route Details</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4 flex items-center">
                <label className="mr-2 text-sm font-medium text-gray-700">
                  {isActive ? "Active Routes" : "Inactive Routes"}
                </label>
                <div
                  onClick={handleToggle}
                  className={`w-12 h-6 flex items-center rounded-full p-1 cursor-pointer transition-colors duration-300 ${
                    isActive ? "bg-blue-500" : "bg-gray-300"
                  }`}
                  aria-label={
                    isActive ? "Deactivate Routes" : "Activate Routes"
                  }
                >
                  <div
                    className={`bg-white w-5 h-5 rounded-full shadow-md transform transition-transform duration-300 ${
                      isActive ? "translate-x-6" : ""
                    }`}
                  />
                </div>
              </div>
              <div>
                <div className="mb-6">
                  <label
                    htmlFor="routeName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Name Your Route
                  </label>
                  <input
                    id="routeName"
                    type="text"
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                    placeholder="Enter Your route name"
                    value={routeName}
                    onChange={(e) => setRouteName(e.target.value)}
                  />
                </div>

                <div className="mb-6">
                  <label
                    htmlFor="tags"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Tags
                  </label>
                  <input
                    id="tags"
                    type="text"
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                    placeholder="Enter your tags here"
                    value={tags}
                    onChange={(e) => setTags(e.target.value)}
                  />
                </div>

                <div className="mb-6">
                  <label
                    htmlFor="scheduledDistance"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Scheduled Distance
                  </label>
                  <input
                    id="scheduledDistance"
                    type="text"
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                    placeholder="Enter your Scheduled Distance"
                    value={scheduledDistance}
                    onChange={(e) => setScheduledDistance(e.target.value)}
                    onKeyDown={(e) => {
                      const allowedKeys = [
                        "Backspace",
                        "ArrowLeft",
                        "ArrowRight",
                        "Delete",
                        "Tab",
                      ];
                      if (
                        !/[0-9]/.test(e.key) &&
                        !allowedKeys.includes(e.key)
                      ) {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
                <hr className="my-6 border-t border-gray-300" />
              </div>

              <div>
                <h2 className="text-lg font-bold mb-4">Starting Point</h2>
                <div className="mb-6">
        <label className="block text-sm font-medium text-gray-700">
          Source Address *
        </label>
        <input
          type="text"
          className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
          placeholder="Enter source address"
          value={sourceAddress}
          onChange={handleSourceAddressChange}
        />
        {loadingSource && <div className="p-2 text-gray-500">Loading...</div>}
        {!loadingSource && noSourceSuggestions && (
          <div className="p-2 text-gray-500">No suggestions found</div>
        )}
        {sourceSuggestions.map((suggestion) => (
          <div
            key={suggestion.place_id}
            onClick={() => handleSourceSelect(suggestion.display_name)}
            className="suggestion p-2 cursor-pointer hover:bg-gray-100"
          >
            {suggestion.display_name}
          </div>
        ))}
      </div>

                <div className="mb-6 relative">
        <label className="block text-sm font-medium text-gray-700">
          Scheduled Time of Arrival at Source
          <div
            className="relative inline-flex items-center justify-center w-4 h-4 bg-blue-500 rounded-full text-white cursor-pointer ml-2"
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            <FaInfoCircle className="text-xs" />
            {showTooltip && (
              <span className="tooltip absolute -top-10 left-1/2 transform -translate-x-1/2 w-64 p-1 bg-black text-white text-sm rounded-sm">
                Specify the time at which the vehicle has to reach the starting point in 00:00 am/pm
              </span>
            )}
          </div>
        </label>
        <DatePicker
          selected={sourceArrivalTime}
          onChange={(date) => setSourceArrivalTime(date)}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption="Time"
          dateFormat="h:mm aa"
          customInput={<CustomInput />}
        />
      </div>

      <div className="mb-6 relative">
        <label className="block text-sm font-medium text-gray-700">
          Scheduled Time of Departure from Source
          <div
            className="relative inline-flex items-center justify-center w-4 h-4 bg-blue-500 rounded-full text-white cursor-pointer ml-2"
            onMouseEnter={() => setShowreachTooltip(true)}
            onMouseLeave={() => setShowreachTooltip(false)}
          >
            <FaInfoCircle className="text-xs" />
            {showreachTooltip && (
              <span className="tooltip absolute -top-10 left-1/2 transform -translate-x-1/2 w-64 p-1 bg-black text-white text-sm rounded-sm">
                Specify the time at which the vehicle has to leave this point in 00:00 am/pm
              </span>
            )}
          </div>
        </label>
        <DatePicker
          selected={sourceDepartureTime}
          onChange={(date) => setSourceDepartureTime(date)}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption="Time"
          dateFormat="h:mm aa"
          customInput={<CustomInput />}
        />
        <hr className="my-6 border-t border-gray-300" />
      </div>
                <div>
                  <div>
                  <h2 className="text-lg font-bold mb-1">Checkpoints / Stops</h2>
      <h5 className="text-sm font-normal mb-6">
        You can add stops or checkpoints between the starting point's location
        and the destination for additional tasks assigned to the driver, or
        driver breaks for a more efficient trip.
      </h5>
      <button
        type="button"
        onClick={toggleCheckpoints}
        className="bg-blue-500 text-white p-2 rounded-md"
      >
        {showCheckpoints ? "− Remove Checkpoint" : "+ Add Checkpoints / Stops"}
      </button>
      {showCheckpoints && (
        <div className="mt-4 p-4 border border-gray-300 rounded-md bg-gray-100">
          {checkpoints.map((checkpoint, index) => (
            <div
              key={index}
              className="mt-4 p-4 border border-gray-300 rounded-md bg-gray-50 relative"
            >
              <button
              
                className="absolute top-2 right-2 text-gray-900 p-1"
                onClick={() => handleRemoveCheckpoint(index)}
              >
                ×
              </button>
              <h2 className="text-lg font-bold mb-4">
                Checkpoint {index + 1}
              </h2>
              <h3 className="font-medium text-gray-700">Checkpoint Address</h3>
              <input
                type="text"
                value={checkpoint.address}
                onChange={(e) =>
                  handleCheckpointAddressChange(index, e.target.value)
                }
                className="mt-2 block w-full p-2 border border-gray-300 rounded-md mb-4"
                placeholder="Select Geofence or Enter Address"
              />
              {checkpoint.suggestions.length > 0 && (
                <div className="border border-gray-300 rounded-md bg-white absolute z-10 w-full mt-1 max-h-40 overflow-y-auto">
                  {checkpoint.suggestions.map((suggestion) => (
                    <div
                      key={suggestion.place_id}
                      onClick={() =>
                        handleSuggestionSelect(index, suggestion.display_name)
                      }
                      className="p-2 cursor-pointer hover:bg-gray-100"
                    >
                      {suggestion.display_name}
                    </div>
                  ))}
                </div>
              )}
              <div className="flex mb-4">
                <button
                  className={`bg-gray-500 text-white p-2 rounded-3xl mr-8 flex items-center ${
                    checkpoint.selectedOption === "sameday" ? "bg-blue-500" : ""
                  }`}
                  onClick={() => handleOptionChange(index, "sameday")}
                >
                  Same Day
                </button>
                <button
                  className={`bg-gray-500 text-white p-2 rounded-3xl mr-8 flex items-center ${
                    checkpoint.selectedOption === "nextday" ? "bg-blue-500" : ""
                  }`}
                  onClick={() => handleOptionChange(index, "nextday")}
                >
                  Next Day
                </button>
                <button
                  className={`bg-gray-500 text-white p-2 rounded-3xl mr-8 flex items-center ${
                    checkpoint.selectedOption === "customDays" ? "bg-blue-500" : ""
                  }`}
                  onClick={() => handleOptionChange(index, "customDays")}
                >
                  Custom No. Days
                </button>
              </div>
              {checkpoint.selectedOption === "customDays" && (
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Number of Days to Arrive:
                  </label>
                  <input
                    type="number"
                    value={checkpoint.days}
                    onChange={(e) =>
                      handleCheckpointChange(index, "days", e.target.value)
                    }
                    className="mt-2 block w-full p-2 border border-gray-300 rounded-md"
                    placeholder="Enter number of days"
                  />
                </div>
              )}
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Scheduled Time of Arrival
              </label>
              <div className="relative">
                <DatePicker
                  selected={checkpoint.arrivalTime}
                  onChange={(date) =>
                    handleCheckpointChange(index, "arrivalTime", date)
                  }
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  customInput={
                    <input className="mt-2 block w-full p-2 border border-gray-300 rounded-md" />
                  }
                />
              </div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Scheduled Time of Departure
              </label>
              <div className="relative">
                <DatePicker
                  selected={checkpoint.departureTime}
                  onChange={(date) =>
                    handleCheckpointChange(index, "departureTime", date)
                  }
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  customInput={
                    <input className="mt-2 block w-full p-2 border border-gray-300 rounded-md" />
                  }
                />
              </div>
            </div>
          ))}
          <button
            type="button"
            onClick={addCheckpoint}
            className="mt-4 bg-blue-500 text-white p-2 rounded-md flex-center"
          >
            + Add Another Checkpoint
          </button>
        </div>
      )}
      <hr className="my-6 border-t border-gray-300" />
                  </div>
                  <div className="mb-6 relative">
        <h2 className="text-lg font-bold mb-1">Destination / Endpoint</h2>
        <h3 className="font-medium text-gray-700">Destination Address</h3>
      <input
        type="text"
        value={destinationAddress}
        onChange={handleDestinationAddressChange}
        className="mt-2 block w-full p-2 border border-gray-300 rounded-md mb-4"
        placeholder="Enter destination address"
      />
      {loadingDestination && <div className="p-2 text-gray-500">Loading...</div>}
      {!loadingDestination && noDestinationSuggestions && (
        <div className="p-2 text-gray-500">No suggestions found</div>
      )}
      {destinationSuggestions.map((suggestion) => (
        <div
          key={suggestion.place_id}
          onClick={() => handleDestinationSelect(suggestion.display_name)}
          className="suggestion p-2 cursor-pointer hover:bg-gray-100"
        >
          {suggestion.display_name}
        </div>
      ))}
   

        <div className="flex mb-4">
          <button type="button" className="bg-gray-500 text-white p-2 rounded-3xl mr-2 flex items-center">
            Same Day
          </button>
          <button className="bg-gray-500 text-white p-2 rounded-3xl mr-2 flex items-center">
            Next Day
          </button>
          <button
            type="button"
            className="bg-gray-500 text-white p-2 rounded-3xl flex items-center"
            onClick={handleDaysInputToggle}
          >
            Custom No. Days
          </button>
        </div>

        {showDaysInput && (
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Number of Days to Arrive:
            </label>
            <input
              type="number"
              value={days}
              onChange={(e) => setDays(e.target.value)}
              className="mt-2 block w-full p-2 border border-gray-300 rounded-md"
              placeholder="Enter number of days"
            />
          </div>
        )}

        <label className="block text-sm font-medium text-gray-700 mb-2">
          Scheduled Time of Arrival at Destination
        </label>
        <div className="relative">
          <DatePicker
            selected={destinationArrivalTime}
            onChange={(date) => setDestinationArrivalTime(date)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="h:mm aa"
            customInput={<CustomInput />}
          />
        </div>

        <label className="block text-sm font-medium text-gray-700 mb-2">
          Scheduled Time of Departure from Destination
        </label>
        <div className="relative">
          <DatePicker
            selected={destinationDepartureTime}
            onChange={(date) => setDestinationDepartureTime(date)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="h:mm aa"
            customInput={<CustomInput />}
          />
        </div>
        <hr className="my-6 border-t border-gray-300" />
      </div>

                  <div className="mb-4">
                    <h2 className="text-lg font-bold mb-10">
                      Desired Routes for the Trip
                    </h2>
                    <div className="border border-gray-950">
                      {paths.map((path, index) => (
                        <div
                          key={index}
                          className="flex items-center h-12 justify-between px-4"
                        >
                          <label className="text-sm font-medium text-gray-700">
                            {path.name}
                          </label>

                          <div
                            onClick={() => handleToggle(index)}
                            className={`w-12 h-6 flex items-center rounded-full p-1 cursor-pointer transition-colors duration-300 ${
                              path.active ? "bg-blue-500" : "bg-gray-300"
                            }`}
                          >
                            <div
                              className={`bg-white w-5 h-5 rounded-full shadow-md transform transition-transform duration-300 ${
                                path.active ? "translate-x-6" : ""
                              }`}
                            />
                          </div>
                          <br />
                          <div className="flex">
                            <button
                             type="button"
                              onClick={() => handleRenamePath(index)}
                              className="bg-yellow-500 text-white p-2 rounded-md mr-2"
                            >
                              Rename Path
                            </button>
                            <button
                             type="button"
                              onClick={() => handleEditPath(index)}
                              className="bg-green-500 text-white p-2 rounded-md"
                            >
                              Edit Path
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                    <button
                     type="button"
                      onClick={addNewPath}
                      className="mt-4 bg-blue-500 text-white p-2 rounded-md"
                    >
                      Add More Path
                    </button>
                  </div>
                  <div className="mt-6">
          <button
            type="submit"
            className="bg-blue-500 text-white p-2 rounded-md float-right"
          >
            Create Route
          </button>
        </div>
                </div>
              </div>
            </form>
          </div>

          <div className="w-2/4 mt-16">
          <div style={{ height: '500px', width: '100%' }}>
        <MapContainer center={[9.939093, 78.121719]} zoom={13} style={{ height: '100%', width: '100%' }}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {source && <Marker position={[source.lat, source.lon]}><Popup>Source</Popup></Marker>}
          {destination && <Marker position={[destination.lat, destination.lon]}><Popup>Destination</Popup></Marker>}
          {route.length > 0 && <Polyline positions={routePath} color="blue" />}
          
        </MapContainer>
      </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default TripRoute;
