import React, { useState, useEffect } from 'react';
import config from '../config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProfileModule from "./UserProfile";
import { FaEdit, FaTrash } from 'react-icons/fa';



const VehicleGroups = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [assignedVehicles, setAssignedVehicles] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedVehicleNames, setSelectedVehicleNames] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedAssignmentId, setSelectedAssignmentId] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false); 

  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedAssignmentId(null);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!groupName || selectedVehicleNames.length === 0) {
      toast.error('Group name or selected vehicles are missing or invalid');
      return;
    }

    setIsSubmitting(true); // Start loading

    const formData = {
      groupName: groupName,
      selectedVehicles: selectedVehicleNames
    };

    const method = selectedAssignmentId ? 'PUT' : 'POST';
    const endpoint = selectedAssignmentId
      ? `${config.apiUrl}/vehicle/assignments/${selectedAssignmentId}`
      : `${config.apiUrl}/vehicle/submit`;

    fetch(endpoint, {
      method: method,
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
      .then(response => {
        if (!response.ok) {
          return response.json().then(error => {
            throw new Error(error.error || 'Network response was not ok');
          });
        }
        return response.json();
      })
      .then(data => {
        if (method === 'POST') {
          toast.success('Vehicle Group Created successfully');
        } else if (method === 'PUT') {
          toast.success('Vehicle Group Updated successfully');
        }
        setGroupName('');
        setAssignedVehicles('');
        setSelectedVehicleNames([]);
        closeModal();
        fetchAssignments();
      })
      .catch(error => {
        toast.error('Failed to submit data: ' + error.message);
      })
      .finally(() => {
        setIsSubmitting(false); // End loading
      });
  };

  useEffect(() => {
    fetch(`${config.apiUrl}/vehicle/getSuggestions`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setSuggestions(data);
      })
      .catch(error => {
        // toast.error('Error fetching vehicle data: ' + );
        console.log(error.message);
      });
  }, []);

  const handleInputChange = (e) => {
    const inputValue = e.target.value || '';
    setAssignedVehicles(inputValue);
  };

  const handleSuggestionClick = (vehicleName) => {
    const isSelected = selectedVehicleNames.includes(vehicleName);
    let updatedSelectedVehicleNames;
    if (isSelected) {
      updatedSelectedVehicleNames = selectedVehicleNames.filter(name => name !== vehicleName);
    } else {
      updatedSelectedVehicleNames = [...selectedVehicleNames, vehicleName];
    }
    setSelectedVehicleNames(updatedSelectedVehicleNames);
    setAssignedVehicles(updatedSelectedVehicleNames.join(', '));
  };

  useEffect(() => {
    fetchAssignments();
  }, []);

  const fetchAssignments = () => {
    fetch(`${config.apiUrl}/vehicle/assignments`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setAssignments(data);
        setLoading(false);
      })
      .catch(error => {
        // toast.error('Error fetching assignments: ' + error.message);
        console.log(error.message);
      });
  };

  const handleEdit = (assignment) => {
    setSelectedAssignmentId(assignment.id);
    setGroupName(assignment.groupName);
    const vehiclesArray = Array.isArray(assignment.vehicles) ? assignment.vehicles : [assignment.vehicles];
    setSelectedVehicleNames(vehiclesArray);
    setAssignedVehicles(vehiclesArray.join(', '));
    openModal();
  };

  const handleDelete = (id) => {
     // Show a confirmation dialog
     const isConfirmed = window.confirm("Are you sure you want to delete this vehicle?");
    
     if (!isConfirmed) {
       return; // Exit the function if the user cancels the confirmation
     }

    fetch(`${config.apiUrl}/vehicle/assignments/${id}`, {
      method: 'DELETE'
    })
      .then(response => {
        if (!response.ok) {
          return response.json().then(error => {
            throw new Error(error.error || 'Network response was not ok');
          });
        }
        toast.success('Vehicle Group deleted successfully');
        fetchAssignments();
      })
      .catch(error => {
        toast.error('Failed to delete assignment: ' + error.message);
      });
  };

  return (
    <div className={`${isModalVisible ? 'overflow-hidden' : ''}`}>
      <div className="flex justify-between h-[10vh] border-b-2 border-white">
        <div className="ml-2 flex justify-start items-center">
          <h2 className="text-lg font-semibold text-gray-500 mb-2 text-center">
            Vehicle Groups
          </h2>
        </div>
        <div className="relative pl-4">
          <div className="absolute left-0 top-2 bottom-4 w-0.5 bg-gray-500"></div>
          <ProfileModule className="pl-2" />
        </div>
      </div>
      <div className="float-right mt-2">
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded-full mr-10"
          onClick={openModal}
        >
          + Add Vehicle Group
        </button>
      </div>

      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="mt-16 overflow-x-auto">
          <table className="w-full border-collapse border border-blue-500">
            <thead className="bg-blue-500 text-white">
              <tr>
                <th className="border border-blue-500 py-2 px-4">Group Name</th>
                <th className="border border-blue-500 py-2 px-4">Selected Vehicles</th>
                <th className="border border-blue-500 py-2 px-4">Actions</th>
              </tr>
            </thead>
            <tbody>
              {assignments.map((assignment, index) => (
                <tr key={index} className="text-center">
                  <td className="border border-blue-500 py-2 px-4">{assignment.groupName}</td>
                  <td className="border border-blue-500 py-2 px-4">{assignment.vehicles.join(', ')}</td>
                  <td className="border border-blue-500 py-4 px-4">
                    <button 
                     className="text-blue-500 cursor-pointer mr-4"
                     onClick={() => handleEdit(assignment)}>
                      <FaEdit /> {/* Icon for edit action */}
                    </button>
                    <button
                      className="text-red-500 cursor-pointer"
                      onClick={() => handleDelete(assignment.id)}>
                      <FaTrash /> {/* Icon for delete action */}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {isModalVisible && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-5 rounded shadow-lg w-1/2">
            <div className="flex justify-end">
              <button onClick={closeModal} className="text-black">
                ✖
              </button>
            </div>
            <h2 className="text-xl mb-4">Add Vehicle Group</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700">Group Name</label>
                <input
                  type="text"
                  className="w-full px-3 py-2 border rounded"
                  placeholder="Enter group name"
                  value={groupName}
                  onChange={(e) => setGroupName(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Assign Vehicles</label>
                <input
                  type="text"
                  className="w-full px-3 py-2 border rounded"
                  placeholder="Assign vehicles"
                  value={assignedVehicles}
                  onChange={handleInputChange}
                />
                {suggestions.map((vehicleName, index) => (
                  <div key={index}>
                    <input
                      type="checkbox"
                      id={`vehicleCheckbox${index}`}
                      name={`vehicleCheckbox${index}`}
                      checked={selectedVehicleNames.includes(vehicleName)}
                      onChange={() => handleSuggestionClick(vehicleName)}
                    />
                    <label htmlFor={`vehicleCheckbox${index}`}>{vehicleName}</label>
                  </div>
                ))}
              </div>
              <div className="flex justify-end">
                <button
                  type="submit"
                  className={`bg-blue-500 text-white px-4 py-2 rounded ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Submitting...' : 'Submit'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default VehicleGroups;