import React, { useState, useEffect } from 'react';
import ProfileModule from './UserProfile';

import ApexCharts from 'react-apexcharts';
import axios from 'axios';
import config from '../config';
import moment from 'moment-timezone';
import Select from 'react-select';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Dashboard() {
    const [data, setData] = useState([]);
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [selectedRange, setSelectedRange] = useState('Today');
    const [suggestions, setSuggestions] = useState([]);
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [totals, setTotals] = useState([]);
    const [durations, setDurations] = useState({ moving: 0, idle: 0, stopped: 0 });

    const [vehicleStatus, setVehicleStatus] = useState({ moving_vehicles: 0, stopped_vehicles: 0  ,idle_vehicles:0});
    const [geofences, setGeofences] = useState([]);
    const [selectedGeofence, setSelectedGeofence] = useState(null); // Initialize as null
    const [dropdownOptions, setDropdownOptions] = useState([]);
     const [geofenceReport, setGeofenceReport] = useState([]);
  const [totalEntryCount, setTotalEntryCount] = useState(0);
  const [totalExitCount, setTotalExitCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');


    

const handleGeofenceChange = (option) => {
  console.log('Geofence options:', dropdownOptions); // Check options
  console.log('Selected option:', option); // Check selected option
  setSelectedGeofence(option);
};

useEffect(() => {
  console.log('Dropdown Options:', dropdownOptions);
  console.log('Selected Geofence:', selectedGeofence);
}, [dropdownOptions, selectedGeofence]);
 

    useEffect(() => {
      axios.get(`${config.apiUrl}/geo/geofences`)
          .then(response => {
              const geofenceOptions = response.data.map(geofence => ({
                  value: geofence.id,
                  label: geofence.name
              }));
              setDropdownOptions(geofenceOptions);
          })
          .catch(error => {
              console.error('Error fetching geofences:', error);
          });
  }, []);


    useEffect(() => {
        // Fetch the vehicle status data from the backend
        axios.get(`${config.apiUrl}/vehicle/vehiclestatus`)
            .then(response => {
                // Update state with the fetched data
                setVehicleStatus(response.data);
            })
            .catch(error => {
                console.error('Error fetching vehicle status:', error);
            });
    }, []);
    
    useEffect(() => {
        fetchSuggestions();
    }, []);

    useEffect(() => {
        
            fetchChartData(); // Fetch chart data when vehicle or range changes
        
    }, [selectedRange]);

    // const parseTime = (timeStr) => {
    //     const [hours, minutes, seconds] = timeStr.split(':').map(Number);
    //     return (hours * 3600) + (minutes * 60) + seconds; // Return total seconds
    // };
    
   
    // Fetch suggestions from backend
    const fetchSuggestions = async () => {
        try {
            const response = await axios.get(`${config.apiUrl}/vehicle/getSuggestions`);
            const formattedSuggestions = response.data.map(suggestion => ({
                label: suggestion,
                value: suggestion
            }));
            setSuggestions(formattedSuggestions);
        } catch (error) {
            console.error('Error fetching suggestions:', error);
        }
    };


    const timeStringToMinutes = (timeString) => {
      if (!timeString) return 0;
  
      const timeParts = timeString.split(' ');
      let hours = 0;
      let minutes = 0;
  
      if (timeParts.length > 0) {
          if (timeParts[1] === 'h') {
              hours = parseInt(timeParts[0]) || 0;
          }
          if (timeParts[3] === 'm') {
              minutes = parseInt(timeParts[2]) || 0;
          }
      }
  
      return hours * 60 + minutes;
  };
  

    
    

    const handleDateRangeChange = () => {
        let fromDate, toDate;
    
        if (selectedRange === 'Today') {
            fromDate = moment().subtract(5, 'hours').subtract(30, 'minutes').startOf('day').toDate();
            toDate = moment().subtract(5, 'hours').subtract(30, 'minutes').endOf('day').subtract(1, 'minute').toDate();
        } else if (selectedRange === 'Yesterday') {
            fromDate = moment().subtract(1, 'days').subtract(5, 'hours').subtract(30, 'minutes').startOf('day').toDate();
            toDate = moment().subtract(1, 'days').subtract(5, 'hours').subtract(30, 'minutes').endOf('day').subtract(1, 'minute').toDate();
        } else if (selectedRange === 'Last 7 Days') {
            fromDate = moment().subtract(6, 'days').subtract(5, 'hours').subtract(30, 'minutes').startOf('day').toDate();
            toDate = moment().subtract(5, 'hours').subtract(30, 'minutes').endOf('day').subtract(1, 'minute').toDate();
        } else if (selectedRange === 'Last 15 Days') {
            fromDate = moment().subtract(14, 'days').subtract(5, 'hours').subtract(30, 'minutes').startOf('day').toDate();
            toDate = moment().subtract(5, 'hours').subtract(30, 'minutes').endOf('day').subtract(1, 'minute').toDate();
        } else if (selectedRange === 'Last 30 Days') {
            fromDate = moment().subtract(29, 'days').subtract(5, 'hours').subtract(30, 'minutes').startOf('day').toDate();
            toDate = moment().subtract(5, 'hours').subtract(30, 'minutes').endOf('day').subtract(1, 'minute').toDate();
        } else if (selectedRange === 'Custom') {
            // Handle custom date range case
            if (fromDate == null || toDate == null) {
                // Ensure fromDate and toDate are set by the user if 'Custom' is selected
                return { fromDate: null, toDate: null };
            }
        } else {
            fromDate = moment().subtract(5, 'hours').subtract(30, 'minutes').startOf('day').toDate();
            toDate = moment().subtract(5, 'hours').subtract(30, 'minutes').endOf('day').subtract(1, 'minute').toDate();
        }
    
        setFromDate(fromDate);
        setToDate(toDate);
    
        return { fromDate, toDate };
    };
    
    const fetchChartData = async () => {
        // Assuming handleDateRangeChange() returns an object with `fromDate` and `toDate`
        const { fromDate, toDate } = handleDateRangeChange(); 

        if (!fromDate || !toDate) {
            console.log('Custom date range not set');
            return; // Return early if custom date range is not set
        }

        try {
            const response = await axios.post(`${config.apiUrl}/report/chartData`, {
                fromDate: fromDate,
                toDate: toDate,
            });

            const { data = [] } = response.data;
            console.log(response, "resss");

            if (data.length === 0) {
                toast.warn('Data not found for this vehicle');
                setTotals([]); // Reset chart data
                setDurations({ moving: 0, idle: 0, stopped: 0 }); // Reset donut chart data
                return; // Exit early if no data
            }

         

          
            const totals = data.map(item => ({
                date: moment(item.date).format("DD-MM-YYYY"),
                distance: parseFloat(item.totalDistance) || 0,
            }));

                    
            const movingTimeInMinutes = data.reduce((acc, item) => acc + timeStringToMinutes(item.moving || ''), 0);
            const idleTimeInMinutes = data.reduce((acc, item) => acc + timeStringToMinutes(item.idle || ''), 0);
            const stoppedTimeInMinutes = data.reduce((acc, item) => acc + timeStringToMinutes(item.stopped || ''), 0);
            setTotals(totals);
            setDurations({
                moving: movingTimeInMinutes,
                idle: idleTimeInMinutes,
                stopped: stoppedTimeInMinutes,
            });
            console.log('totals:', totals);
            console.log(`Total Moving Time: ${convertMinutesToHMS(movingTimeInMinutes)}`);
            console.log(`Total Idle Time: ${convertMinutesToHMS(idleTimeInMinutes)}`);
            console.log(`Total Stopped Time: ${convertMinutesToHMS(stoppedTimeInMinutes)}`);



        } catch (error) {
            console.error('Error fetching data', error);
        }
    };
    // Convert total minutes to hours and minutes
    function convertMinutesToHMS(minutes) {
      if (minutes === undefined || minutes === null) {
        return 'N/A'; // Return default value if input is invalid
      }
      const hours = Math.floor(minutes / 60);
      const mins = minutes % 60;
      return `${hours}h ${mins}m`; // Format the time as hours and minutes
    }


    const fetchGeofenceReport = async () => {
      try {
        const response = await axios.post(`${config.apiUrl}/geo/geofenceData`, {
          fromDate: fromDate || moment().subtract(5, 'hours').subtract(30, 'minutes').startOf('day').toDate(),
          toDate: toDate || moment().subtract(5, 'hours').subtract(30, 'minutes').endOf('day').subtract(1, 'minute').toDate(),
          vehicleNumber: selectedVehicle?.label,
          geofenceId: selectedGeofence?.value
        });
  
        const geoData = response.data.processedResults || [];
  
        if (geoData.length === 0) {
          // Set default values if there's no data
          setGeofenceReport([]);
          setTotalEntryCount(0);
          setTotalExitCount(0);
          setErrorMessage('Data not found for this vehicle.');
        } else {
          // Ensure that entryCount and exitCount are defaulted to 0 if they are undefined
          const processedGeoData = geoData.map(data => ({
            ...data,
            entryCount: data.entryCount || 0,
            exitCount: data.exitCount || 0
          }));
  
          setGeofenceReport(processedGeoData);
  
          const entryCount = processedGeoData.reduce((total, data) => total + data.entryCount, 0);
          const exitCount = processedGeoData.reduce((total, data) => total + data.exitCount, 0);
          setTotalEntryCount(entryCount);
          setTotalExitCount(exitCount);
          setErrorMessage('');
        }
  
      } catch (error) {
        console.error('Error fetching geofence report data', error);
        setErrorMessage('Error fetching geofence report data.');
      }
    };

    useEffect(() => {
      if (selectedGeofence && selectedVehicle) {
        fetchGeofenceReport();
      } else {
        // Ensure default values are set when selections are not made yet
        setGeofenceReport([]);
        setTotalEntryCount(0);
        setTotalExitCount(0);
        setErrorMessage('');
      }
    }, [selectedGeofence, fromDate, toDate, selectedVehicle]);
  

    


    

    const areaChartData = {
        options: {
            chart: {
                type: 'area',
                zoom: {
                    enabled: true,
                    type: 'x',
                },
                toolbar: {
                    show: true,
                    tools: {
                        zoomin: true,
                        zoomout: true,
                        reset: true,
                    },
                },
            },
            title: {
                text: 'Daily Distance Data',
                align: 'left',
                style: {
                    fontSize: '16px',
                    fontWeight: 'bold',
                    color: '#333'
                }
            },
            xaxis: {
                categories: totals.map(item => item.date || 'N/A'),  // Fallback to 'N/A' if date is undefined
                labels: {
                    style: {
                        colors: '#999',
                        fontSize: '12px',
                    },
                },
            },
            yaxis: {
                title: {
                    text: 'Distance (km)',
                    style: {
                        fontSize: '14px',
                        fontWeight: 'bold',
                        color: '#333'
                    }
                },
                labels: {
                    style: {
                        colors: '#999',
                        fontSize: '12px',
                    },
                },
            },
            tooltip: {
                shared: true,
                intersect: false,
                theme: 'dark',
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'light',
                    type: 'vertical',
                    shadeIntensity: 0.5,
                    gradientToColors: ['#2196F3'],
                    stops: [0, 100],
                },
            },
            colors: ['#2196F3'],
        },
        series: [{
            name: 'Total Distance',
            data: totals.map(item => item.distance || 0),  // Fallback to 0 if distance is undefined
        }],
    };
    


    const pieChartData = {
      series: [
        durations.moving ?? 0,
        durations.idle ?? 0,
        durations.stopped ?? 0
      ],
      options: {
        chart: {
          type: 'donut', // Donut chart type
          toolbar: {
            show: true
          }
        },
        colors: ['#4CAF50', '#FFC107', '#F44336'], // Colors for each segment
        labels: ['Moving', 'Idle', 'Stopped'], // Labels for the chart
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
          labels: {
            colors: '#333' // Color of the legend labels
          }
        },
        title: {
          text: 'Travelling Report',
          align: 'center',
          style: {
            fontSize: '16px',
            fontWeight: 'bold',
            color: '#333' // Title color
          }
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return convertMinutesToHMS(val); // Format the tooltip value
            }
          }
        },
        plotOptions: {
          pie: {
            donut: {
              size: '70%', // Increase size of donut hole
              labels: {
                show: true, // Show labels inside the donut
                name: {
                  show: true // Show name of the slice
                },
                value: {
                  show: true, // Show value of the slice
                  formatter: function (val) {
                    return convertMinutesToHMS(val); // Format value labels
                  }
                },
                total: {
                  show: true, // Show total value in the center
                  label: 'Total', // Label for the total value
                  color: '#FFF', // Color of the total label
                  fontSize: '20px', // Font size of the total label
                  fontWeight: 600 // Font weight of the total label
                }
              }
            },
            expandOnClick: true
          },
          dataLabels: {
            enabled: true, // Enable data labels
            formatter: function (val, opts) {
              // Customize callouts with value and label
              return `${opts.w.globals.labels[opts.seriesIndex]}: ${convertMinutesToHMS(val)}`;
            },
            style: {
              colors: ['#ffffff'], // Color of the callouts
              fontSize: '14px', // Font size of the callouts
            }
          }
        }
      },
    };
    






  console.log(pieChartData.series);

    
    
    





// const stackedBarChartData = {
//     series: [
//         {
//             name: 'Moving',
//             data: [durations.moving || 0]
//         },
//         {
//             name: 'Idle',
//             data: [durations.idle || 0]
//         },
//         {
//             name: 'Stopped',
//             data: [durations.stopped || 0]
//         }
//     ],
//     options: {
//         chart: {
//             type: 'bar',
//             stacked: true,
//             toolbar: {
//                 show: true
//             },
//         },
//         plotOptions: {
//             bar: {
//                 horizontal: false,
//                 columnWidth: '50%',
//                 endingShape: 'rounded',
//             }
//         },
//         colors: ['#4CAF50', '#FFC107', '#F44336'],
//         dataLabels: {
//             enabled: true,
//             formatter: function (val) {
//                 return convertMinutesToHMS(val);
//             },
//             style: {
//                 fontSize: '14px',
//                 fontWeight: 'bold',
//                 color: '#fff',
//             },
//         },
//         xaxis: {
//             categories: ['Daily'],
//             title: {
//                 text: 'Days'
//             }
//         },
//         yaxis: {
//             title: {
//                 text: 'Time (Minutes)'
//             }
//         },
//         title: {
//             text: 'Daily Travelling Covered',
//             align: 'left',
//             style: {
//                 fontSize: '16px',
//                 fontWeight: 'bold',
//                 color: '#333'
//             }
//         },
//         tooltip: {
//             shared: true,
//             intersect: false,
//             y: {
//                 formatter: function (val) {
//                     return convertMinutesToHMS(val);
//                 }
//             }
//         },
//         legend: {
//             position: 'top',
//             horizontalAlign: 'left'
//         }
//     },
// };













const handleVehicleChange = (selectedOption) => {
    if (selectedOption) {
        setSelectedVehicle(selectedOption);
    } else {
        setSelectedVehicle(null);
    }
};;

    return (
<div className="bg-gray-100 min-h-screen p-6">

  <div className="flex justify-between items-center h-[10vh] border-b-2 border-gray-300 sticky top-0 z-10 bg-white shadow-lg">
    <h1 className="text-2xl font-bold text-gray-700 ml-5">Analytics Dashboard</h1>
    <ProfileModule />
  </div>

  {/* <div className="flex flex-wrap justify-center gap-6 mt-6 mb-6">
  <div className="bg-white shadow-lg rounded-lg p-6 w-80 hover:bg-blue-50 transition duration-300 ease-in-out border border-gray-200">
  <h2 className="text-xl font-semibold mb-4 text-blue-700">Vehicles Status</h2>
  <div className="grid grid-cols-2 gap-4">
    <div className="text-center bg-blue-50 p-4 rounded-lg shadow-sm">
      <h3 className="text-lg font-medium text-gray-700">Moving</h3>
      <p className="text-3xl font-bold text-blue-600">{vehicleStatus.moving_vehicles}</p>
    </div>
    <div className="text-center bg-yellow-50 p-4 rounded-lg shadow-sm">
      <h3 className="text-lg font-medium text-gray-700">Idle</h3>
      <p className="text-3xl font-bold text-yellow-600">{vehicleStatus.idle_vehicles}</p>
    </div>
    <div className="text-center bg-red-50 p-4 rounded-lg shadow-sm">
      <h3 className="text-lg font-medium text-gray-700">Stopped</h3>
      <p className="text-3xl font-bold text-red-600">{vehicleStatus.stopped_vehicles}</p>
    </div>
    <div className="text-center bg-gray-50 p-4 rounded-lg shadow-sm">
      <h3 className="text-lg font-medium text-gray-700">Offline</h3>
      <p className="text-3xl font-bold text-gray-600">{vehicleStatus.offline_vehicles}</p>
    </div>
  </div>
</div>

    {errorMessage && <div className="bg-red-100 text-red-800 p-4 rounded-lg">{errorMessage}</div>}
    {geofenceReport.length > 0 && selectedGeofence && (
      <div className="bg-green-50 shadow-lg rounded-lg p-6 w-64 hover:bg-green-200 transition duration-300 ease-in-out">
        <h2 className="text-xl font-semibold mb-4 text-green-800">Geofence Report</h2>
        <div className="flex justify-between">
          <div className="text-center">
            <h3 className="text-lg font-medium text-gray-600">ENTRY</h3>
            <p className="text-3xl font-bold text-green-600">{totalEntryCount}</p>
          </div>
          <div className="text-center">
            <h3 className="text-lg font-medium text-gray-600">EXIT</h3>
            <p className="text-3xl font-bold text-orange-600">{totalExitCount}</p>
          </div>
        </div>
      </div>
    )}
  </div>
  
  <div className="flex flex-wrap gap-4 mt-6">
    <div className="flex-1">
      <Select
        options={suggestions}
        onChange={handleVehicleChange}
        placeholder="Select Vehicle"
        className="w-full"
      />
    </div>
    <div className="flex-1">
      <Select
        options={[
          { label: 'Today', value: 'Today' },
          { label: 'Yesterday', value: 'Yesterday' },
          { label: 'Last 7 Days', value: 'Last 7 Days' },
          { label: 'Last 15 Days', value: 'Last 15 Days' },
          { label: 'Last 30 Days', value: 'Last 30 Days' },
          { label: 'Custom', value: 'Custom' },
        ]}
        onChange={(selectedOption) => setSelectedRange(selectedOption.value)}
        placeholder="Select Date Range"
        className="w-full"
      />
      {selectedRange === 'Custom' && (
        <div className="flex flex-col sm:flex-row sm:space-x-4 mt-4">
          <input
            type="date"
            value={fromDate ? moment(fromDate).format('YYYY-MM-DD') : ''}
            onChange={(e) => setFromDate(new Date(e.target.value))}
            className="border border-gray-300 rounded-lg p-2 w-full sm:w-1/2"
          />
          <input
            type="date"
            value={toDate ? moment(toDate).format('YYYY-MM-DD') : ''}
            onChange={(e) => setToDate(new Date(e.target.value))}
            className="border border-gray-300 rounded-lg p-2 w-full sm:w-1/2"
          />
        </div>
      )}
    </div>
    <div className="flex-1">
      <Select
        options={dropdownOptions}
        value={selectedGeofence}
        onChange={handleGeofenceChange}
        placeholder="Select Geofence"
        className="w-full"
      />
    </div>
  </div>

  <div className="flex mt-8 gap-4 flex-wrap">
    <div className="w-full md:w-1/2 px-2">
      <ApexCharts
        options={areaChartData.options}
        series={totals.length ? areaChartData.series : []}
        type="area"
        height={350}
      />
    </div>
    <div className="w-full md:w-1/2 px-2">
      <ApexCharts
        options={pieChartData.options}
        series={pieChartData.series}
        type="pie"
        height={350}
      />
    </div>
  </div> */}

<div className="p-6 space-y-6 h-[500px] overflow-y-auto ">

  <div className="bg-purple-50 shadow-lg rounded-lg p-6 w-full mb-6 border border-gray-200 hover:shadow-xl transition-shadow duration-300">
    <h2 className="text-2xl font-bold mb-6 text-blue-800">Vehicles Status</h2>
    <div className="flex justify-between space-x-4">
      <div className="flex-1 text-center bg-blue-50 p-6 rounded-lg shadow-lg hover:bg-blue-100 transition-colors duration-300">
        <h3 className="text-lg font-semibold text-blue-700">Moving</h3>
        <p className="text-4xl font-extrabold text-blue-700">{vehicleStatus.moving_vehicles}</p>
      </div>
      <div className="flex-1 text-center bg-yellow-50 p-6 rounded-lg shadow-lg hover:bg-yellow-100 transition-colors duration-300">
        <h3 className="text-lg font-semibold text-yellow-700">Idle</h3>
        <p className="text-4xl font-extrabold text-yellow-700">{vehicleStatus.idle_vehicles}</p>
      </div>
      <div className="flex-1 text-center bg-red-50 p-6 rounded-lg shadow-lg hover:bg-red-100 transition-colors duration-300">
        <h3 className="text-lg font-semibold text-red-700">Stopped</h3>
        <p className="text-4xl font-extrabold text-red-700">{vehicleStatus.stopped_vehicles}</p>
      </div>
      <div className="flex-1 text-center bg-gray-50 p-6 rounded-lg shadow-lg hover:bg-gray-100 transition-colors duration-300">
        <h3 className="text-lg font-semibold text-gray-700">Offline</h3>
        <p className="text-4xl font-extrabold text-gray-700">{vehicleStatus.offline_vehicles}</p>
      </div>
    </div>
  </div>

  <div className="flex flex-col md:flex-row gap-6">
    <div className="flex-1 md:w-1/3 space-y-6">
      <Select
        options={suggestions}
        onChange={handleVehicleChange}
        placeholder="Select Vehicle"
        className="w-full border border-gray-300 rounded-lg shadow-sm hover:border-blue-500 transition-colors duration-300"
      />
      <Select
        options={[
          { label: 'Today', value: 'Today' },
          { label: 'Yesterday', value: 'Yesterday' },
          { label: 'Last 7 Days', value: 'Last 7 Days' },
          { label: 'Last 15 Days', value: 'Last 15 Days' },
          { label: 'Last 30 Days', value: 'Last 30 Days' },
          { label: 'Custom', value: 'Custom' },
        ]}
        onChange={(selectedOption) => setSelectedRange(selectedOption.value)}
        placeholder="Select Date Range"
        className="w-full border border-gray-300 rounded-lg shadow-sm hover:border-blue-500 transition-colors duration-300 mb-10"
      />
      {selectedRange === 'Custom' && (
        <div className="flex flex-col sm:flex-row gap-6 mt-6">
          <input
            type="date"
            value={fromDate ? moment(fromDate).format('YYYY-MM-DD') : ''}
            onChange={(e) => setFromDate(new Date(e.target.value))}
            className="border border-gray-300 rounded-lg p-3 w-full sm:w-1/2 shadow-sm hover:border-blue-500 transition-colors duration-300"
          />
          <input
            type="date"
            value={toDate ? moment(toDate).format('YYYY-MM-DD') : ''}
            onChange={(e) => setToDate(new Date(e.target.value))}
            className="border border-gray-300 rounded-lg p-3 w-full sm:w-1/2 shadow-sm hover:border-blue-500 transition-colors duration-300"
          />
        </div>
      )}
      <Select
        options={dropdownOptions}
        value={selectedGeofence}
        onChange={handleGeofenceChange}
        placeholder="Select Geofence"
        className="w-full border mb-10 border-gray-300 rounded-lg shadow-sm hover:border-blue-500 transition-colors duration-300"
      />
    </div>

    <div className="flex-1 md:w-2/3">
      {errorMessage && (
        <div className="bg-red-100 text-red-800 p-4 rounded-lg mb-6 border border-red-200 hover:bg-red-200 transition-colors duration-300">
          {errorMessage}
        </div>
      )}

      {geofenceReport.length > 0 && selectedGeofence && (
        <div className="bg-green-50 shadow-lg rounded-lg p-6 w-full mb-6 border border-gray-200 hover:shadow-xl transition-shadow duration-300">
          <h2 className="text-2xl font-bold mb-4 text-green-800">Geofence Report</h2>
          <div className="flex justify-between space-x-4">
            <div className="text-center bg-green-100 p-6 rounded-lg shadow-lg hover:bg-green-200 transition-colors duration-300">
              <h3 className="text-lg font-semibold text-green-700">ENTRY</h3>
              <p className="text-4xl font-extrabold text-green-700">{totalEntryCount}</p>
            </div>
            <div className="text-center bg-orange-100 p-6 rounded-lg shadow-lg hover:bg-orange-200 transition-colors duration-300">
              <h3 className="text-lg font-semibold text-orange-700">EXIT</h3>
              <p className="text-4xl font-extrabold text-orange-700">{totalExitCount}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  </div>
  <div className="flex flex-wrap gap-6 mt-12">
    <div className="flex-1 min-w-0 px-4 mb-6">
      <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
        <ApexCharts
          options={areaChartData.options}
          series={totals.length ? areaChartData.series : []}
          type="area"
          height={350}
        />
      </div>
    </div>

    <div className="flex-1 min-w-0 px-4 mb-6">
      <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
        <ApexCharts
          options={pieChartData.options}
          series={pieChartData.series}
          type="pie"
          height={400}
        />
      </div>
    </div>
  </div>


  </div>
</div>

);
}


export default Dashboard;