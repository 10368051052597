import React, {useState,useEffect} from 'react';
import axios from 'axios';
import moment from 'moment';
import config from '../../config'; 
import ProfileModule from '../UserProfile';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { FaPen, FaTrash } from 'react-icons/fa'; // Font Awesome icons



function TripSchedule() {
    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
    const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
    const [isRecurringModalOpen, setIsRecurringModalOpen] = useState(false);
    const [isGeofenceModalOpen, setIsGeofenceModalOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [vehicleValue, setVehicleValue] = useState('');
    const [routeValue, setRouteValue] = useState('');
    const [selectedFrequency, setSelectedFrequency] = useState('daily');
    const [selectedDays, setSelectedDays] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(true);
    const [schedules, setSchedules] = useState([]);
    const [editSchedule, setEditSchedule] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [vehicleValueMainPage, setVehicleValueMainPage] = useState(null);  // State for main page vehicle
    const [vehicleValueModal, setVehicleValueModal] = useState(null); 
   
    const [inputValue, setInputValue] = useState('');
    // const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [filteredSchedules, setFilteredSchedules] = useState([]);

   // Filter schedules by selected vehicle
   useEffect(() => {
    if (vehicleValueMainPage) {
      const filtered = schedules.filter((schedule) => schedule.vehicle === vehicleValueMainPage.value);
      setFilteredSchedules(filtered);
    } else {
      setFilteredSchedules(schedules); // Show all schedules if no vehicle is selected
    }
  }, [vehicleValueMainPage, schedules]);

  useEffect(() => {
    if (vehicleValueMainPage) {
      setInputValue(vehicleValueMainPage.value);
    }
  }, [vehicleValueMainPage]);

 

  // // Function to filter suggestions based on input
  // const filterSuggestions = (input) => {
  //   const lowercasedInput = input.toLowerCase();
  //   const filtered = suggestions.filter(suggestion =>
  //     suggestion.label.toLowerCase().includes(lowercasedInput)
  //   );
  //   setFilteredSuggestions(filtered);
  // };




    // Handle day checkbox change
    const handleDayChange = (day) => {
        setSelectedDays((prevDays) =>
            prevDays.includes(day)
                ? prevDays.filter((d) => d !== day)
                : [...prevDays, day]
        );
    };
    

    useEffect(() => {
      if (selectedFrequency === 'daily') {
        setSelectedDays(['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY']);
      } else if (selectedFrequency === 'weekly') {
        setSelectedDays(prevDays =>
          prevDays.filter(day => ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'].includes(day))
        );
      }
    }, [selectedFrequency]);

    useEffect(() => {
      fetchSuggestions();
      fetchSchedules();
    }, []);

    // const toggleFilterModal = () => {
    //     setIsFilterModalOpen(!isFilterModalOpen);
    //   };
    
      const toggleScheduleModal = () => {
        setIsScheduleModalOpen(!isScheduleModalOpen);
        setIsEditing(false)
      }

      const closeModal = () => {
        setIsScheduleModalOpen(false);
        setIsFilterModalOpen(false);
        setIsRecurringModalOpen(false);
        setIsGeofenceModalOpen(false);
         
      };


      const openRecurringModal = () => {
        setIsRecurringModalOpen(true);
      };
    
    
      // const openGeofenceModal = () => {
      //   setIsGeofenceModalOpen(true);
      // };

      const handleDelete = async (id) => {
        const isConfirmed = window.confirm("Are you sure you want to delete this vehicle?");
    
        if (!isConfirmed) {
          return; // Exit the function if the user cancels the confirmation
        }
      
        try {
          await axios.delete(`${config.apiUrl}/trip/delete-trip-schedule/${id}`);
          // Remove the deleted schedule from the state
          setSchedules(schedules.filter(schedule => schedule.id !== id));
          toast.success("Trip Deleted Successfully");
        } catch (error) {
          console.error('Error deleting schedule:', error);
        }
      };


      const handleCreateOrUpdateTripSchedule = async () => {
        const tripData = {
          vehicle: vehicleValueModal?.label || '',
          startTime: selectedDate.toTimeString().split(' ')[0],
          frequency: selectedFrequency,
          days: selectedDays.length > 0 ? selectedDays : [],
        };
      
        try {
          if (isEditing) {
            await axios.put(`${config.apiUrl}/trip/edit-trip-schedule/${editSchedule.id}`, tripData);
            toast.success("Trip Schedule updated successfully");
          } else {
            const response = await axios.post(`${config.apiUrl}/trip/create-trip-schedule`, tripData);
            
            // Success message from the server response
            if (response.status === 200) {
              toast.success("Trip Schedule created successfully");
            }
          }
      
          // Re-fetch schedules after creating/updating
          await fetchSchedules();
          closeModal();
          resetFormFields();
        } catch (error) {
          console.error('Error creating or updating the trip schedule!', error);
          
          // Display error message based on server response
          if (error.response && error.response.status === 404) {
            toast.error(error.response.data.message || 'No trip found for this vehicle. Please assign a vehicle to a trip first.');
          } else {
            toast.error('There was an error creating or updating the trip schedule!');
          }
        }
      };
      
      


      const resetFormFields = () => {
        setVehicleValueModal('');          // Reset vehicle value to empty string
        setRouteValue('');            // Reset route value to empty string
        setSelectedDate(new Date());  // Reset selected date to current date or a default value
        setSelectedFrequency('daily');// Reset frequency to default value
        setSelectedDays([]);          // Reset selected days to an empty array
        
        console.log("Form fields reset");
        console.log({
          vehicleValueModal,
          routeValue,
          selectedDate,
          selectedFrequency,
          selectedDays
        }); // Debugging log
      };
      
      // Ensure fetchSchedules function is defined
      const fetchSchedules = async () => {
        try {
          const response = await axios.get(`${config.apiUrl}/trip/get-trip-schedules`);
          setSchedules(response.data);
          setFilteredSchedules(response.data);
        } catch (error) {
          console.error('Error fetching trip schedules:', error);
        }
      };
  

    // Handle vehicle change for the main page
    const handleVehicleChangeMainPage = (selectedOption) => {
      setVehicleValueMainPage(selectedOption);
  };

  // Handle vehicle change for the modal
  const handleVehicleChangeModal = (selectedOption) => {
      setVehicleValueModal(selectedOption);
  };

  // Clear vehicle in the modal
  const handleClearVehicleModal = () => {
    setVehicleValueMainPage(null);
  };
      
    // Function to fetch suggestions from backend
    const fetchSuggestions = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/vehicle/getSuggestions`);
      const formattedSuggestions = response.data.map(suggestion => ({
        label: suggestion,
        value: suggestion
      }));
      setSuggestions(formattedSuggestions);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
  };

  const handleEdit = (schedule = null) => {
    setEditSchedule(schedule);
    if (schedule) {
      setVehicleValueModal({ label: schedule.vehicle });
        
        setSelectedDate(moment(schedule.start_time, 'HH:mm:ss').toDate());
        setSelectedFrequency(schedule.frequency);
        setSelectedDays(schedule.days ? schedule.days.split(',') : []);
        setIsEditing(true);
    } else {
      setVehicleValueModal(null);
     
        setSelectedDate(new Date());
        setSelectedFrequency('');
        setSelectedDays([]);
        setIsEditing(false);
    }
    setIsRecurringModalOpen(true);
};


    return (
        <div>
<div className="flex justify-between items-center h-[10vh] border-b-2 border-gray-300">
  <div className="flex flex-col items-center">
    <h3>TRIP</h3>
    <h2 className="text-lg font-semibold text-gray-500 mb-2 text-center">Trip Schedules</h2>
  </div>
  <div className="pl-2">
    <ProfileModule />
  </div>
</div>
<div className="flex items-center justify-between p-4 border-b-2 border-gray-300">
<div className="relative flex items-center">
<Select
        options={suggestions}
        value={vehicleValueMainPage}
        onChange={handleVehicleChangeMainPage} 
        placeholder="Select a vehicle"
        className="pl-10 pr-8 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        classNamePrefix="select"
      />
      {vehicleValueMainPage  && ( // Only show the clear button if a vehicle is selected
        <button
          className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-red-500 text-white py-1 px-2 rounded-lg hover:bg-red-600 focus:outline-none z-10"
          onClick={handleClearVehicleModal}
          aria-label="Clear selection"
        >
          &times;
        </button>
      )}
    </div>




  <div className="ml-auto flex space-x-4">
          {/* <button
            className="px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-500"
            onClick={toggleFilterModal}
          >
            Filter
          </button> */}
          <button
            className="px-4 py-2 bg-blue-500 text-white hover:bg-blue-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            onClick={toggleScheduleModal}
          >
            Create Schedule
          </button>
        </div>
     
</div>
<div>
<table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-md">
  <thead>
    <tr className="w-full bg-gray-100 text-left">
      <th className="px-4 py-2 border-b">Start Time</th>
      <th className="px-4 py-2 border-b">Vehicle</th>
     
      <th className="px-4 py-2 border-b">Days</th>
      <th className="px-4 py-2 border-b text-center">Actions</th> {/* Centered "Actions" header */}
    </tr>
  </thead>
  <tbody>
          {filteredSchedules.length > 0 ? (
            filteredSchedules.map((schedule) => (
              <tr key={schedule.id}>
                <td className="px-4 py-2 border-b">
                  BasedOnTime<br />
                  {moment(schedule.start_time, 'HH:mm:ss').format('h:mm A')}
                </td>
                <td className="px-4 py-2 border-b">{schedule.vehicle}</td>
                <td className="px-4 py-2 border-b">
                  {Array.isArray(schedule.days)
                    ? schedule.days.length > 0
                      ? schedule.days.map(day => day.charAt(0).toUpperCase() + day.slice(1)).join(', ')
                      : 'No days specified'
                    : typeof schedule.days === 'string'
                      ? schedule.days.split(',').map(day => day.trim().charAt(0).toUpperCase() + day.trim().slice(1)).join(', ')
                      : 'No days specified'}
                </td>
                <td className="px-4 py-2 border-b text-center"> {/* Center the content of the Actions column */}
                  <div className="flex justify-center space-x-4">
                    <button 
                      className="text-blue-500 hover:text-blue-700 focus:outline-none"
                      aria-label="Edit"
                      onClick={() => handleEdit(schedule)} // Add your edit logic here
                    >
                      <FaPen className="h-5 w-5" />
                    </button>
                    <button 
                      className="text-red-500 hover:text-red-700 focus:outline-none"
                      aria-label="Delete"
                      onClick={() => handleDelete(schedule.id)} // Add your delete logic here
                    >
                      <FaTrash className="h-5 w-5" />
                    </button>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="px-4 py-2 border-b text-center">No schedules found</td>
            </tr>
          )}
        </tbody>
</table>

</div>

  {/* {isFilterModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-end">
          <div className="bg-white w-96 h-full shadow-xl p-6">
            <div className="flex justify-between items-center">
              <h2 className="text-2xl font-semibold">Filters</h2>
              <button
                className="text-red-500 text-2xl font-bold"
                onClick={toggleFilterModal}
              >
               &times;
              </button>
            </div>
            <div className="mt-4 space-y-4">
              <div>
                <label className="block text-sm font-medium">Trip Schedule Type</label>
                <input type="text" className="mt-1 p-2 w-full border rounded" />
              </div>
              {/* <div>
                <label className="block text-sm font-medium mb-2">Route</label>
                <input type="text" className="mt-1 p-2 w-full border rounded" />
              </div>
              <div>
                <label className="block text-sm font-medium mb-2">Source</label>
                <select className="mt-1 p-2 w-full border rounded mb-2">
                  <option>Source 1</option>
                  <option>Source 2</option>
                </select>
              </div> */}
              {/* <div>
                <label className="block text-sm font-medium mb-2">Vehicle Groups</label>
                <input type="text" className="mt-1 p-2 w-full border rounded mb-2" />
              </div>
              <div>
                <label className="block text-sm font-medium mb-2">Vehicles</label>
                <input type="text" className="mt-1 p-2 w-full border rounded mb-2" />
              </div>
              <div className="flex justify-between mb-2">
                <button className="px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded-lg mb-4">                  Clear All
                </button>
                <button className="px-4 py-2 bg-blue-500 text-white hover:bg-blue-600 rounded-lg mb-4">
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      )} */} 

{isScheduleModalOpen && (
         <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
         <div className="bg-white w-full max-w-lg p-4 rounded-lg shadow-lg overflow-y-auto h-80">
           <div className="flex justify-between items-center ">
             <h2 className="text-xl font-bold mb-2">Create Trip Schedule</h2>
             <button onClick={closeModal} className="text-red-500 font-bold">
               X
             </button>
           </div>
           <div className="mt-4">
             <label className="block font-semibold">Choose Trip Schedule Type</label>
             <div className="mt-2">
               <div className="flex items-center">
                 <input type="radio" id="recurring" name="tripType" className="mr-2" onClick={openRecurringModal} />
                 <label htmlFor="recurring" className="font-medium mb-2">Recurring Trip at Selected Time</label>
               </div>
               <p className="ml-6 text-sm mb-2">Schedule a recurring trip on a route at selected time frequency.</p>
             </div>
             {/* <div className="mt-2">
               <div className="flex items-center">
                 <input type="radio" id="geofence" name="tripType" className="mr-2" onClick={openGeofenceModal} />
                 <label htmlFor="geofence" className="font-medium mb-2">Trip Schedule Based on Geofence Exit</label>
               </div>
               <p className="ml-6 text-sm mb-2">Schedule a trip whenever your selected vehicle exits the source geofence of the selected route</p>
             </div> */}
           </div>
           <div className="mt-4 flex justify-between mb-2">
             <button
               onClick={closeModal}
               className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-500"
             >
               Cancel
             </button>
             <button className="px-4 py-2 bg-blue-500 text-white hover:bg-blue-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500">
               Continue
             </button>
           </div>
         </div>
       </div>
      )}

{isRecurringModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white w-full max-w-2xl rounded-lg shadow-lg h-5/6 flex flex-col">
            <div className="sticky top-0 bg-white z-10 p-6 shadow-md flex justify-between items-center">
              <h2 className="text-xl font-bold">
                {isEditing ? 'Update Trip Schedule' : 'Create Trip Schedule'}
              </h2>
              <button onClick={closeModal} className="text-red-500 font-bold">
                X
              </button>
            </div>
            <div className="overflow-y-auto p-6 flex-1">
              <p className="text-sm mb-2">
                Schedule a recurring trip on a route at selected time frequency.
              </p>
              <label className="block font-semibold">Vehicle</label>
              <Select
                options={suggestions}
                value={vehicleValueModal}
                onChange={handleVehicleChangeModal} 
                placeholder="Select a vehicle"
                className="w-full mt-2"
                ariaHideApp={false}
                 overlayClassName="overlay"
              />
              {/* <label className="block font-semibold mt-4">Vehicle Route</label>
              <input
                type="text"
                className="w-full mt-2 p-2 border rounded-lg"
                value={routeValue}
                onChange={(e) => setRouteValue(e.target.value)}
              /> */}
              <label className="block font-semibold mt-4">Trip Start Time</label>
              <DatePicker
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="h:mm aa"
                className="w-full mt-2 p-2 border rounded-lg"
              />
              <label className="block font-semibold mt-4">Trip Schedule Frequency</label>
              <select
                className="w-full mt-2 p-2 border rounded-lg"
                value={selectedFrequency}
                onChange={(e) => setSelectedFrequency(e.target.value)}
              >
                <option value="weekly">Weekly</option>
                <option value="daily">Daily</option>
              </select>
              <label className="block font-semibold mt-4">Repeat on Following Days</label>
              <div className="flex flex-wrap mt-2">
              {['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'].map((day) => (
                  <label key={day} className="flex items-center mr-4 mb-2">
                    <input
                      type="checkbox"
                      className="mr-2"
                      checked={selectedDays.includes(day)}
                      onChange={() => handleDayChange(day)}
                     
                    />
                    {day}
                  </label>
                ))}
              </div>
            </div>
            <div className="p-6 flex justify-between">
              <button
                onClick={closeModal}
                className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-500"
              >
                Back
              </button>
              <button
                className="px-4 py-2 bg-blue-500 text-white hover:bg-blue-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                onClick={handleCreateOrUpdateTripSchedule}
              >
                {isEditing ? 'Update Trip Schedule' : 'Create Trip Schedule'}
              </button>
            </div>
          </div>
        </div>
      )}



{/* {isGeofenceModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white w-full max-w-xl p-6 rounded-lg shadow-lg overflow-y-auto h-96">
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-bold mb-2">Trip Schedule Based on Geofence Exit</h2>
             
              <button onClick={closeModal} className="text-red-500 font-bold">
                X
              </button>
            </div>
            <div className="mt-4">
            <p className="text-sm mb-4">Schedule a trip whenever your selected vehicle exits the source geofence of the selected route</p>
              <label className="block font-semibold">Vehicle Route</label>
              <input type="text" className="w-full mt-2 p-2 border rounded-lg" />
              <label className="block font-semibold mt-4">Vehicle</label>
              <input type="text" className="w-full mt-2 p-2 border rounded-lg" />
            </div>
            <div className="mt-4 flex justify-between mb-2">
              <button
                onClick={closeModal}
                className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-500"
              >
                Back
              </button>
              <button className="px-4 py-2 bg-blue-500 text-white hover:bg-blue-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500">
                Create Trip Schedule
              </button>
            </div>
          </div>
        </div>
      )} */}


        </div>
    )
}

export default TripSchedule

