import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Circle, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import Modal from 'react-modal';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import config from '../../config';
import GeofenceApp from './GeofenceApp';

// Create a default icon
const DefaultIcon = L.icon({
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
  iconAnchor: [12, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

const ChangeView = ({ center, zoom }) => {
  const map = useMap();
  useEffect(() => {
    map.flyTo(center, zoom);
  }, [center, zoom, map]);
  return null;
};

const GeofenceMap = () => {
  const [geofences, setGeofences] = useState([]);
  const [selectedGeofence, setSelectedGeofence] = useState(null);
  const [mapCenter, setMapCenter] = useState([9.897392, 78.120794]);
  const [mapZoom, setMapZoom] = useState(13);
  const [viewMode, setViewMode] = useState('map');
  const [filterStatus, setFilterStatus] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [editGeofence, setEditGeofence] = useState(null);

  const markerRefs = useRef([]);
  const mapRef = useRef(null);

  useEffect(() => {
    fetchGeofences()
  }, []);

  const fetchGeofences = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/geo/geofences`);
      if (!response.ok) {
        throw new Error(`Error fetching geofences: ${response.statusText}`);
      }
      const data = await response.json();
      setGeofences(data)
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.whenReady(() => {
        // Perform any additional actions once the map is fully loaded and ready
        if (selectedGeofence) {
          // Adjust or redraw radius circles if needed
          const circle = document.querySelector('.leaflet-circle');
          if (circle) {
            circle.style.zIndex = '1000'; // Ensure it is on top
          }
        }
      });
    }
  }, [mapCenter, mapZoom, selectedGeofence]);

  const handleGeofenceClick = (geofence, index) => {
    if (selectedGeofence && selectedGeofence.id === geofence.id) {
      setSelectedGeofence(null);
      setMapCenter([9.897392, 78.120794]);
      setMapZoom(13);
      if (markerRefs.current[index]) {
        markerRefs.current[index].closePopup();
      }
    } else {
      setSelectedGeofence(geofence);
      setMapCenter([geofence.latitude, geofence.longitude]);
      setMapZoom(15);
      if (markerRefs.current[index]) {
        markerRefs.current[index].openPopup();
      }
    }
  };

  const handleMarkerClick = (geofence, index) => {
    if (selectedGeofence && selectedGeofence.id === geofence.id) {
      setSelectedGeofence(null);
      setMapCenter([9.897392, 78.120794]);
      setMapZoom(13);
      if (markerRefs.current[index]) {
        markerRefs.current[index].closePopup();
      }
    } else {
      setSelectedGeofence(geofence);
      setMapCenter([geofence.latitude, geofence.longitude]);
      setMapZoom(15);
      if (markerRefs.current[index]) {
        markerRefs.current[index].openPopup();
      }
    }
  };

  const filteredGeofences = geofences.filter(geofence => {
    const matchesStatus = filterStatus === 'all' || 
      (filterStatus === 'active' && geofence.enabled) || 
      (filterStatus === 'inactive' && !geofence.enabled);
    const matchesSearch = geofence.name.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesStatus && matchesSearch;
  });

  return (
    <>
      <style>{`
      
        .modal-content {
          height: 90vh;
          overflow-y: auto;
        }
        .sidebar {
          height: calc(100vh - 64px); /* Adjust based on your header height */
          overflow-y: auto;
        }
        .map-container {
          height: 100%;
          width: 100%;
        }
        .map-hidden {
          display: none;
        }
        .list-hidden {
          display: none;
        }
        .leaflet-popup-content-wrapper {
          margin-top: 0; /* Ensure no margin hides the pointer */
        }
        .leaflet-popup-tip {
          background-color: white; /* Match the popup background */
          border: 1px solid #ccc; /* Match the border with the popup */
        }
        .leaflet-popup {
          margin-bottom: 50px; /* Position the popup above the marker */
        }
       
      `}</style>

      <div className="flex flex-col h-screen">
        <div className="flex items-center justify-between p-4 bg-gray-100 border-b border-gray-300 h-[10vh]">
          <div className="flex space-x-4">
            <input
              type="text"
              placeholder="Search geofences..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="border p-2 rounded"
            />
            <select
              value={filterStatus}
              onChange={(e) => {setFilterStatus(e.target.value)
                setSelectedGeofence(null);
                setMapCenter([9.897392, 78.120794]);
                setMapZoom(13)}
              }
              className="border p-2 rounded"
            >
              <option value="all">All</option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
          </div>
          <div>
            <button 
              onClick={() => setViewMode('map')}
              className={`px-4 py-2 rounded ${viewMode === 'map' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            >
              Map View
            </button>
            <button 
              onClick={() => {setViewMode('list')
                setSelectedGeofence(null);
                setMapCenter([9.897392, 78.120794]);
                setMapZoom(13);}
              }
              className={`px-4 py-2 rounded ${viewMode === 'list' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            >
              List View
            </button>
          </div>
          <button 
            onClick={() =>{ setModalIsOpen(true)
              setSelectedGeofence(null);
              setMapCenter([9.897392, 78.120794]);
              setMapZoom(13);
            }}
            className="bg-green-500 text-white px-4 py-2 rounded"
          >
            Add Geofence
          </button>
        </div>

        <div className="flex flex-grow h-[90vh]">
          {viewMode === 'map' && (
            <div className="flex w-full">
   <div className="w-1/3 p-4 bg-gray-50 border-r border-gray-300 sidebar">
  {filteredGeofences.map((geofence, index) => (
    <div
      key={geofence.id}
      className={`p-4 mb-4 border rounded-lg cursor-pointer transition-all duration-300 transform hover:scale-105 hover:shadow-2xl ${
        selectedGeofence && selectedGeofence.id === geofence.id ? 'bg-gradient-to-r from-blue-100 to-blue-200 border-blue-500' : 'bg-white border-gray-300'
      }`}
      onClick={() => handleGeofenceClick(geofence, index)}
    >
      <div className="flex justify-between items-center mb-2 ">
        <h3 className="text-xs font-semibold uppercase">{geofence.name}</h3>
        <p className="text-xs font-normal text-gray-400">Last edited {new Date(geofence.updated_at).toLocaleDateString()}</p>
      </div>
      <p className="text-xs font-semibold mb-1"> <span className=" text-gray-500">{geofence.location}</span></p>
      <div className="flex justify-between items-center mt-6">
        <p className="text-xs text-gray-500">Radius: <span className=" ">{geofence.radius} meters</span></p>
        {selectedGeofence && selectedGeofence.id === geofence.id && (
          <button 
            onClick={(e) => {
              e.stopPropagation(); // Prevents click event from triggering parent handler
              setEditGeofence(geofence);
              setEditModalIsOpen(true);
              if (selectedGeofence) {
                const index = filteredGeofences.findIndex(f => f.id === selectedGeofence.id);
                if (markerRefs.current[index]) {
                  markerRefs.current[index].closePopup();
                }
              }
            }}
            className="text-sm font-medium text-blue-500 hover:text-blue-700 hover:underline ml-4"
          >
            Edit
          </button>
        )}
      </div>
    </div>
  ))}
</div>




              <div className="w-2/3 map-container">
                <MapContainer
                  center={mapCenter}
                  zoom={mapZoom}
                  style={{ height: '100%', width: '100%' }}
                  whenCreated={mapInstance => mapRef.current = mapInstance}
                >
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  />
                  {filteredGeofences.map((geofence, index) => (
                    <Marker
                      key={geofence.id}
                      position={[geofence.latitude, geofence.longitude]}
                      eventHandlers={{
                        click: () => handleMarkerClick(geofence, index),
                      }}
                      ref={el => markerRefs.current[index] = el}
                    >
                      <Popup>{geofence.name}</Popup>
                    </Marker>
                  ))}
                  {selectedGeofence && (
                    <Circle
                      center={[selectedGeofence.latitude, selectedGeofence.longitude]}
                      radius={selectedGeofence.radius}
                      // color="blue"
                      // fillColor="blue"
                      // fillOpacity={0.3}
                    >
                      <Popup>{selectedGeofence.name}</Popup>
                    </Circle>
                  )}
                  <ChangeView center={mapCenter} zoom={mapZoom} />
                </MapContainer>
              </div>
            </div>
          )}
         {viewMode === 'list' && (
  <div className="w-full p-4 overflow-x-auto">
    <table className="min-w-full divide-y divide-gray-200 bg-white shadow-lg rounded-lg">
      <thead className="bg-gray-100">
        <tr>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Location</th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Last Updated</th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Radius</th>
          <th className="px-6 py-3"></th> {/* Empty header for actions */}
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {filteredGeofences.map(geofence => (
          <tr key={geofence.id}>
            <td className="px-6 py-4 whitespace-nowrap text-sm uppercase font-medium text-gray-900">{geofence.name}</td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{geofence.location}</td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{new Date(geofence.updated_at).toLocaleDateString()}</td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{geofence.radius} meters</td>
            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
              <button 
                onClick={() => {
                  setEditGeofence(geofence);
                  setEditModalIsOpen(true);
                  
                }}
                className="text-blue-500 hover:underline"
              >
                Edit
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)}

        </div>
      </div>

     {/* Add Geofence Modal */}
<Modal
  isOpen={modalIsOpen}
  onRequestClose={() => setModalIsOpen(false)}
  className="flex flex-col h-[95vh] bg-white p-0 relative z-[1001] m-[20px_100px] rounded-lg shadow-lg"
  overlayClassName="fixed top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,0.5)] z-[1000]"
>
  <div className="flex items-center justify-between h-[5vh] p-4 bg-gray-200 border-b border-gray-300 rounded-t-lg">
    <div className="text-lg font-semibold">Add Geofence</div>
    <button 
      className="text-xl font-bold text-gray-700 hover:text-gray-900 bg-transparent border-none cursor-pointer"
      onClick={() => setModalIsOpen(false)}
    >
      &times;
    </button>
  </div>
  
  <div className="flex-1 overflow-y-auto p-4">
    <GeofenceApp
      geofence={null}
      onClose={() => setModalIsOpen(false)}
      onSave={() => {
        // Handle save action
        setModalIsOpen(false);
        fetchGeofences();

      }}
    />
  </div>
</Modal>

{/* Edit Geofence Modal */}
<Modal
  isOpen={editModalIsOpen}
  onRequestClose={() => setEditModalIsOpen(false)}
  className="flex flex-col h-[95vh] bg-white p-0 relative z-[1001] m-[20px_100px] rounded-lg shadow-lg"
  overlayClassName="fixed top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,0.5)] z-[1000]"
>
  <div className="flex items-center justify-between h-[5vh] p-4 bg-gray-200 border-b border-gray-300 rounded-t-lg">
    <div className="text-lg font-semibold">Edit Geofence</div>
    <button 
      className="text-xl font-bold text-gray-700 hover:text-gray-900 bg-transparent border-none cursor-pointer"
      onClick={() =>{ setEditModalIsOpen(false)
        setSelectedGeofence(null);
        setMapCenter([9.897392, 78.120794]);
        setMapZoom(13);
      }}
    >
      &times;
    </button>
  </div>

  <div className="flex-1 overflow-y-auto p-4">
    <GeofenceApp
      geofence={editGeofence}
      onClose={() => {
        setEditModalIsOpen(false);
        setEditGeofence(null);
       
      }}
      onSave={() => {
        // Handle save action
        setEditModalIsOpen(false);
        setEditGeofence(null);
        fetchGeofences();
        setSelectedGeofence(null);
        setMapCenter([9.897392, 78.120794]);
        setMapZoom(13);
      }}
    />
  </div>
</Modal>

    </>
  );
};

export default GeofenceMap;