import React, { useState, useEffect } from 'react';

const GeocodeSearch = ({ onLocationFound, onStreetNameChange, location, error, clearInput1 }) => {
  const [streetName, setStreetName] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({ lat: '', lon: '' });
  const [loading, setLoading] = useState(false);
  const [noSuggestions, setNoSuggestions] = useState(false);
  const [isUserInput, setIsUserInput] = useState(true);
  const [callApi, setCallApi] = useState(false);
  const [clearInput, setClearInput] = useState(clearInput1);
  const [hasInitialized, setHasInitialized] = useState(false);

  // Initialize state based on location prop
  useEffect(() => {
    if (!!location) {
      setSelectedLocation({ lat: location.latitude, lon: location.longitude });
      setStreetName(location.location);
      setHasInitialized(true); // Set flag to true after initialization
    } 
  }, [location]);

  // Clear input fields if clearInput prop changes
  useEffect(() => {
    if (clearInput || clearInput1) {
      setStreetName('');
      setSelectedLocation({ lat: '', lon: '' });
      setCallApi(false);
      setHasInitialized(true); // Ensure flag is set to true to allow further calls
    }
  }, [clearInput, clearInput1]);

  useEffect(() => {
    if (!callApi && !hasInitialized) {
      if ((isUserInput || streetName.length > 10) && !!streetName) {
        const handler = setTimeout(() => {
          fetchSuggestions();
        }, 1000);

        return () => {
          clearTimeout(handler);
        };
      }
    }
  }, [streetName, isUserInput, callApi]);

  const fetchSuggestions = async () => {
    setLoading(true);
    setNoSuggestions(false);
    try {
      const response = await fetch(`https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(streetName)}, Madurai, Tamil Nadu, India&format=json&addressdetails=1&limit=5`);
      const data = await response.json();
      if (data.length > 0) {
        setSuggestions(data);
        setNoSuggestions(false);
      } else {
        setNoSuggestions(true);
      }
    } catch (error) {
      console.error('Error fetching suggestions:', error);
      setNoSuggestions(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSelect = (lat, lon, street) => {
    setIsUserInput(false);
    setSelectedLocation({ lat, lon });
    setStreetName(street);
    onLocationFound([lat, lon]);
    onStreetNameChange(street);
    setSuggestions([]);
    setNoSuggestions(false);
    setCallApi(true);
    setLoading(false);

    setTimeout(() => {
      setIsUserInput(true);
    }, 100);
  };

  const handleClear = () => {
    setIsUserInput(false);
    setStreetName('');
    setSelectedLocation({ lat: '', lon: '' });
    onLocationFound([null, null]);
    onStreetNameChange('');
    setSuggestions([]);
    setNoSuggestions(false);
    setCallApi(false);
    setHasInitialized(false)

    setTimeout(() => {
      setIsUserInput(true);
    }, 100);
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center space-x-2">
        <input
          type="text"
          value={streetName}
          onChange={(e) => {
            setIsUserInput(true);
            setLoading(true);
            setStreetName(e.target.value);
            onStreetNameChange(e.target.value); // Call the prop function to handle validation
          }}
          placeholder="Enter street name"
          className={`input flex-grow p-2 border rounded ${error ? 'border-red-500' : 'border-gray-300'}`}
        />
        <button
          type="button"
          onClick={handleClear}
          className="button p-2 bg-red-500 text-white rounded"
        >
          X
        </button>
      </div>
      <div className="suggestions border border-gray-300 max-h-40 overflow-y-auto">
        {loading && <div className="p-2 text-gray-500">Loading...</div>}
        {!loading && noSuggestions && <div className="p-2 text-gray-500">No suggestions found</div>}
        {suggestions.map((suggestion) => (
          <div
            key={suggestion.place_id}
            onClick={() => handleSelect(suggestion.lat, suggestion.lon, suggestion.display_name)}
            className="suggestion p-2 cursor-pointer hover:bg-gray-100"
          >
            {suggestion.display_name}
          </div>
        ))}
      </div>
      <div className="location-details space-y-2">
        <div className="space-y-1">
          <label className="block font-semibold">Latitude:</label>
          <input
            type="text"
            value={selectedLocation.lat}
            readOnly
            className="input w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div className="space-y-1">
          <label className="block font-semibold">Longitude:</label>
          <input
            type="text"
            value={selectedLocation.lon}
            readOnly
            className="input w-full p-2 border border-gray-300 rounded"
          />
        </div>
      </div>
    </div>
  );
};

export default GeocodeSearch;
