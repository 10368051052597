import React, { useState,useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import config from '../../config';
import ProfileModule from '../UserProfile';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCalendarAlt } from 'react-icons/fa';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function GenerateIdleReport({  isOpen,onClose,fetchReport }) {
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [rangeOption, setRangeOption] = useState('Today');
    const [subUsername, setSubUsername] = useState("");
    const [vehicleGroups, setVehicleGroups] = useState([]);
    const [selectedVehicle, setSelectedVehicle] = useState('');
    const [minIdleDuration, setminIdleDuration] = useState('');
    const [suggestions, setSuggestions] = useState([]);
const [idleData,setIdleData]=useState([]);
const [loading, setLoading] = useState(false);
const [validationErrors, setValidationErrors] = useState({});
const [selectedGroup, setSelectedGroup] = useState(null);
    const options = [
        { value: 'Today', label: 'Today' },
        { value: 'Yesterday', label: 'Yesterday' },
        { value: 'Last 7 Days', label: 'Last 7 Days' },
        { value: 'Last 30 Days', label: 'Last 30 Days' },
        { value: 'Custom', label: 'Custom' }
      ];

      const handleDateRangeChange = (selectedOption) => {
        setRangeOption(selectedOption);
      
        let fromDate, toDate;
      
        if (selectedOption.value === 'Today') {
          fromDate = moment().subtract(5, 'hours').subtract(30, 'minutes').startOf('day').toDate();
          toDate = moment().subtract(5, 'hours').subtract(30, 'minutes').endOf('day').toDate();
        } else if (selectedOption.value === 'Yesterday') {
          fromDate = moment().subtract(1, 'days').subtract(5, 'hours').subtract(30, 'minutes').startOf('day').toDate();
          toDate = moment().subtract(1, 'days').subtract(5, 'hours').subtract(30, 'minutes').endOf('day').toDate();
        } else if (selectedOption.value === 'Last 7 Days') {
          fromDate = moment().subtract(7, 'days').subtract(5, 'hours').subtract(30, 'minutes').startOf('day').toDate();
          toDate = moment().subtract(5, 'hours').subtract(30, 'minutes').endOf('day').toDate();
        } else if (selectedOption.value === 'Last 30 Days') {
          fromDate = moment().subtract(30, 'days').subtract(5, 'hours').subtract(30, 'minutes').startOf('day').toDate();
          toDate = moment().subtract(5, 'hours').subtract(30, 'minutes').endOf('day').toDate();
        } else if (selectedOption.value === 'Custom') {
          fromDate = null; // Clear existing dates for custom range
          toDate = null; // Clear existing dates for custom range
        }
      
        setFromDate(fromDate);
        setToDate(toDate);
      };
      
      
  

    useEffect(() => {
      axios.get(`${config.apiUrl}/vehicle/assignments`)
          .then(response => {
              const formattedData = response.data.map(group => ({
                  value: group.id,
                  label: group.groupName,
                  vehicles: group.vehicles // Store the vehicles array
              }));
              setVehicleGroups(formattedData);
          })
          .catch(error => {
              console.error('Error fetching vehicle groups:', error);
          });
  }, []);


  const handleGroupChange = (selectedOption) => {
    setSelectedGroup(selectedOption);
    setSelectedVehicle(null); // Reset vehicle selection when the group changes
    setSuggestions(selectedOption ? selectedOption.vehicles.map(vehicle => ({ value: vehicle, label: vehicle })) : []);
};

      const handleVehicleChange = (selectedOption) => {
        setSelectedVehicle(selectedOption);
    };

    const handleSubmit = async () => {
      const errors = {};
  
      // Validate required fields
      if (!rangeOption) errors.rangeOption = true;
      if (!fromDate) errors.fromDate = true;
      if (!toDate) errors.toDate = true;
      if (!subUsername) errors.subUsername = true;
      if (!selectedGroup) errors.selectedGroup = true;
      if (!selectedVehicle) errors.selectedVehicle = true;
      if (!minIdleDuration) errors.minIdleDuration = true;
    
      // Set validation errors if there are any
      if (Object.keys(errors).length) {
        setValidationErrors(errors);
        return; // Stop execution if there are validation errors
      }
    
      // Clear previous validation errors
      setValidationErrors({});
      setLoading(true); 
 
      try {
        const response = await axios.post(`${config.apiUrl}/report/idleplayback`, {
          fromDate: fromDate,
          toDate: toDate,
          vehicleGroup: selectedGroup ? selectedGroup.label : '',
          vehicleNumber: selectedVehicle ? selectedVehicle.label : '', 
          minimumDuration: minIdleDuration,
          subUsername,
        });
        
        setIdleData(response.data)
        toast.success(" Idle Report successfully Generated")
        fetchReport()
        // const idleDataWithAddress = await Promise.all(
        //   response.data.map(async (item) => {
        //     const address = await fetchAddress(item.lat, item.lon);
        //     return { ...item, address };
        //   })
        // );
  
        // setIdleData(idleDataWithAddress);
        // onDataExport(idleDataWithAddress);
        //    // Ensure stoppageData is set correctly before exporting
        //    console.log('idle Data State:', idleDataWithAddress);
        // handleExportToExcel(idleDataWithAddress);
      } catch (error) {
        console.error('Error fetching idle data:', error);
      }
  
      console.log('Form submitted');
      setLoading(false); 
      handleCancel();
    };
    

    
 
  
  

  const handleCancel = () => {
    setRangeOption(null);
    setFromDate(null);
    setToDate(null);
    setSubUsername("");
    setSelectedGroup(null);
    setSelectedVehicle(null);
    setminIdleDuration("");
    onClose();
};


    return (
      isOpen ? (
      <div className="fixed inset-0 bg-gray-900 bg-opacity-5 backdrop-blur-sm flex items-center justify-center z-15">
          <div className="modal-content bg-white rounded-lg shadow-lg p-6 max-w-md w-full animate-fadeIn">
    <div className="date-options flex flex-wrap gap-4 mb-6">
      <div className="relative w-full">
        <Select
          value={rangeOption}
          onChange={handleDateRangeChange}
          options={options}
          className={`block w-full border ${validationErrors.rangeOption ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm`}
          placeholder="Select a date range"
        />
      </div>
    </div>
    
    {rangeOption && rangeOption.value === 'Custom' && (
      <div className="custom-date-pickers flex gap-6 mb-6">
        <div className="flex flex-col w-full">
          <label className="text-gray-800 mb-2 font-medium">From:</label>
          <div className="flex items-center border border-gray-300 rounded-lg shadow-sm p-2">
            <FaCalendarAlt className="text-gray-600 mr-2" />
            <DatePicker
              selected={fromDate}
              onChange={date => setFromDate(date)}
              maxDate={new Date()}
              minDate={moment().subtract(6, 'months').toDate()}
              className={`w-full border-none ${validationErrors.fromDate ? 'border-red-500' : ''}`}
            />
          </div>
        </div>
        <div className="flex flex-col w-full">
          <label className="text-gray-800 mb-2 font-medium">To:</label>
          <div className="flex items-center border border-gray-300 rounded-lg shadow-sm p-2">
            <FaCalendarAlt className="text-gray-600 mr-2" />
            <DatePicker
              selected={toDate}
              onChange={date => setToDate(date)}
              maxDate={new Date()}
              minDate={moment().subtract(6, 'months').toDate()}
              className={`w-full border-none ${validationErrors.toDate ? 'border-red-500' : ''}`}
            />
          </div>
        </div>
      </div>
    )}
  
    <div className="date-display mb-6">
      <p className="text-gray-800 font-medium">
        Selected Range: {moment(fromDate).format('DD MMM YYYY')} - {moment(toDate).format('DD MMM YYYY')}
      </p>
    </div>
    
    <div className="mb-6">
          <label className="block text-gray-800 mb-2 font-medium">Sub Username:</label>
          <select
            value={subUsername}
            onChange={e => setSubUsername(e.target.value)}
            className={`w-full border ${validationErrors.subUsername ? 'border-red-500' : 'border-gray-300'} rounded-lg p-3 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500`}
          >
            <option value="">Select Sub Username</option>
            <option value="subUsername">All Sub Username</option>
            {/* Add more options here if needed */}
          </select>
        </div>

        <div className="mb-6">
                <label className="block text-gray-800 mb-2 font-medium">Vehicle Group Name:</label>
                <Select
                    value={selectedGroup}
                    onChange={handleGroupChange}
                    options={vehicleGroups}
                    className={`block w-full border ${validationErrors.vehicleGroups ? 'border-red-500' : 'border-gray-300'} rounded-lg shadow-sm`}
                    placeholder="Select a vehicle group"
                />
            </div>

            <div className="mb-6">
                <label htmlFor="vehicle" className="block text-gray-800 mb-2 font-medium">Select Vehicle</label>
                <Select
                    id="vehicle"
                    name="vehicle"
                    value={selectedVehicle}
                    onChange={handleVehicleChange}
                    options={suggestions}
                    className={`block w-full py-3 px-4 border ${validationErrors.selectedVehicle ? 'border-red-500' : 'border-gray-300'} rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500`}
                    placeholder="Start typing to select a vehicle"
                    isDisabled={!selectedGroup} // Disable if no group is selected
                />
            </div>
{/*   
    <div className="mb-6">
      <label className="block text-gray-800 mb-2 font-medium">Vehicle Group Name:</label>
      <Select
        value={vehicleGroups}
        onChange={setVehicleGroups}
        options={vehicleGroups || []}
        className={`block w-full border ${validationErrors.vehicleGroups ? 'border-red-500' : 'border-gray-300'} rounded-lg shadow-sm`}
        placeholder="Select a vehicle group"
      />
    </div>
  
    <div className="mb-6">
      <label htmlFor="vehicle" className="block text-gray-800 mb-2 font-medium">Select Vehicle</label>
      <Select
        id="vehicle"
        name="vehicle"
        value={selectedVehicle}
        onChange={handleVehicleChange}
        options={suggestions}
        className={`block w-full py-3 px-4 border ${validationErrors.selectedVehicle ? 'border-red-500' : 'border-gray-300'} rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500`}
        placeholder="Start typing to select a vehicle"
      />
    </div> */}
  
    <div className="mb-6">
      <label className="block text-gray-800 mb-2 font-medium">Minimum Stoppage Duration:</label>
      <select
        value={minIdleDuration}
        onChange={e => setminIdleDuration(e.target.value)}
        className={`w-full border ${validationErrors.minIdleDuration ? 'border-red-500' : 'border-gray-300'} rounded-lg p-3 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500`}
      >
        <option value="">Select duration</option>
        <option value="2">2 minutes</option>
        <option value="4">4 minutes</option>
        <option value="6">6 minutes</option>
      </select>
    </div>
  
    <div className="flex justify-between">
      <button
        type="button"
        onClick={handleCancel}
        className="bg-gray-600 text-white px-6 py-3 rounded-lg shadow hover:bg-gray-700 transition duration-300"
      >
        Cancel
      </button>
      <button
        type="button"
        onClick={handleSubmit}
        disabled={loading}
        className="bg-blue-600 text-white px-6 py-3 rounded-lg shadow hover:bg-blue-700 transition duration-300"
      >
        {loading ? 'Submitting...' : 'Submit'}
      </button>
    </div>
  </div>
  
      </div>
 
) : null
    )
}

export default GenerateIdleReport