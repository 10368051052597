import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBus,
  faTruck,
  faVanShuttle,
  faMotorcycle,
  faTractor,
  faTruckMonster,
} from "@fortawesome/free-solid-svg-icons";
import { FaTrash, FaEdit } from "react-icons/fa";
// import { config } from '@fortawesome/fontawesome-svg-core';
import config from "../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProfileModule from "./UserProfile";

function VehicleInventory() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [submittedData, setSubmittedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editingVehicle, setEditingVehicle] = useState(null);

  const [formData, setFormData] = useState({
    registrationNumber: "",
    displayNumber: "",
    odometerReading: "",
    vehicleMake: "",
    modelYear: "",
    vehicleType: "",
    vehicleIdentificationNumber: "",
    fuelType: "",
    expectedMileage: "",
    vehicleColor: "",
    selectedIcon: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value === "N/A" ? "" : value,
    }));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/vehicle/getvehicles`);
      if (response.ok) {
        const data = await response.json();
        setSubmittedData(data);
      } else {
        console.error("Failed to fetch vehicle data");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };
  // const handleSubmit = async (e) => {
  //   e.preventDefault(); // Prevent default form submission behavior
  //   setIsSubmitting(true); // Set loading state to true

  //   const {
  //     registrationNumber,
  //     displayNumber,
  //     odometerReading = null,
  //     vehicleMake = null,
  //     modelYear = null,
  //     vehicleType = null,
  //     vehicleIdentificationNumber = null,
  //     fuelType = null,
  //     expectedMileage = null,
  //     vehicleColor = null,
  //     selectedIcon = null,
  //   } = formData;

  //   try {
  //     const response = await fetch(`${config.apiUrl}/vehicle/addvehicle`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         registrationNumber,
  //         displayNumber,
  //         odometerReading,
  //         vehicleMake,
  //         modelYear,
  //         vehicleType,
  //         vehicleIdentificationNumber,
  //         fuelType,
  //         expectedMileage,
  //         vehicleColor,
  //         selectedIcon: selectedIcon
  //         ? {
  //             prefix: selectedIcon.prefix || '',
  //             iconName: selectedIcon.iconName || '',
  //             icon: selectedIcon.iconName ? selectedIcon.iconName.split(",") : [],
  //           }
  //         : null,
  //       }),
  //     });

  //     if (response.ok) {
  //       console.log("Vehicle data saved successfully");
  //       setFormData({
  //         registrationNumber: "",
  //         displayNumber: "",
  //         odometerReading: "",
  //         vehicleMake: "",
  //         modelYear: "",
  //         vehicleType: "",
  //         vehicleIdentificationNumber: "",
  //         fuelType: "",
  //         expectedMileage: "",
  //         vehicleColor: "",
  //         selectedIcon: "",
  //       });
  //       toast.success("Vehicle data saved successfully");
  //       handleCloseModal();
  //       fetchData();
  //     } else {
  //       const errorData = await response.json(); // Parse JSON error response
  //       console.error("Failed to save vehicle data:", errorData);
  //       toast.error(
  //         `Failed to save vehicle data: ${errorData.error || "Unknown error"}`
  //       );
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //     toast.error(`Error occurred: ${error.message}`);
  //   } finally {
  //     setIsSubmitting(false); // Reset loading state
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = editingVehicle
      ? `${config.apiUrl}/vehicle/${editingVehicle.id}`
      : `${config.apiUrl}/vehicle/addvehicle`;
    const method = editingVehicle ? "PUT" : "POST";

    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          registrationNumber: formData.registrationNumber,
          displayNumber: formData.displayNumber,
          odometerReading: formData.odometerReading,
          vehicleMake: formData.vehicleMake,
          modelYear: formData.modelYear,
          vehicleType: formData.vehicleType,
          vehicleIdentificationNumber: formData.vehicleIdentificationNumber,
          fuelType: formData.fuelType,
          expectedMileage: formData.expectedMileage,
          vehicleColor: formData.vehicleColor,
          selectedIcon: formData.selectedIcon
            ? {
                prefix: formData.selectedIcon.prefix,
                iconName: formData.selectedIcon.iconName,
                icon: formData.selectedIcon.icon.join(","),
              }
            : null,
        }),
      });

      if (response.ok) {
        console.log("Form Data:", {
          registrationNumber: formData.registrationNumber,
          displayNumber: formData.displayNumber,
          odometerReading: formData.odometerReading,
          vehicleMake: formData.vehicleMake,
          modelYear: formData.modelYear,
          vehicleType: formData.vehicleType,
          vehicleIdentificationNumber: formData.vehicleIdentificationNumber,
          fuelType: formData.fuelType,
          expectedMileage: formData.expectedMileage,
          vehicleColor: formData.vehicleColor,
          selectedIcon: formData.selectedIcon,
        });

        toast.success(
          `Vehicle ${editingVehicle ? "updated" : "saved"} successfully`
        );
        setFormData({
          registrationNumber: "",
          displayNumber: "",
          odometerReading: null,
          vehicleMake: null,
          modelYear: null,
          vehicleType: null,
          vehicleIdentificationNumber: null,
          fuelType: null,
          expectedMileage: null,
          vehicleColor: null,
          selectedIcon: null,
        });
        setIsModalOpen(false);
        setEditingVehicle(null);
        fetchData(); // Refresh the list after saving
      } else {
        const errorData = await response.json();
        toast.error(
          `Failed to ${editingVehicle ? "update" : "save"} vehicle data: ${
            errorData.error || "Unknown error"
          }`
        );
      }
    } catch (error) {
      toast.error(`Error occurred: ${error.message}`);
    }
  };

  const handleEdit = (data) => {
    setEditingVehicle(data);
    setIsModalOpen(true);
    setFormData({
      registrationNumber: data.registration_number || "",
      displayNumber: data.display_number || "",
      odometerReading: data.odometer_reading || null,
      vehicleMake: data.vehicle_make || null,
      modelYear: data.model_year || null,
      vehicleType: data.vehicle_type || null,
      vehicleIdentificationNumber: data.vehicle_identification_number || null,
      fuelType: data.fuel_type || null,
      expectedMileage: data.expected_mileage || null,
      vehicleColor: data.vehicle_color || null,
      selectedIcon:
        data.selected_icon_prefix && data.selected_icon_iconName
          ? {
              prefix: data.selected_icon_prefix,
              iconName: data.selected_icon_iconName,
              icon: data.selected_icon_icon
                ? data.selected_icon_icon.split(",")
                : [],
            }
          : null,
    });
  };

  const handleDelete = async (id) => {
    // Show a confirmation dialog
    const isConfirmed = window.confirm("Are you sure you want to delete this vehicle?");
    
    if (!isConfirmed) {
      return; // Exit the function if the user cancels the confirmation
    }
  
    try {
      const response = await fetch(`${config.apiUrl}/vehicle/${id}`, {
        method: "DELETE",
      });
  
      if (response.ok) {
        toast.success("Vehicle deleted successfully");
        fetchData(); // Refresh the vehicle list after deletion
      } else {
        const errorData = await response.json();
        toast.error(`Failed to delete vehicle: ${errorData.error}`);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred while deleting the vehicle");
    }
  };
  

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // const handleIconSelect = (icon) => {
  //   setSelectedIcon(icon); // Set the selected icon
  // };

  // const iconClasses = (icon) => {
  //   return selectedIcon && selectedIcon.iconName === icon.iconName
  //     ? "text-blue-500"
  //     : "text-gray-500";
  // };

  return (
    <div className={` ${isModalOpen ? "" : ""}`}>
      <div className="flex justify-between h-[10vh] border-b-2  border-white">
        <div className="ml-2 flex justify-start items-center">
          <div>
            <h3>Vehicles</h3>
            <h2 className="text-lg font-semibold text-gray-500 mb-2 text-center">
              Vehicle Inventory
            </h2>
          </div>
        </div>

        <div className="relative pl-4">
          <div className="absolute left-0 top-2 bottom-4 w-0.5 bg-gray-500"></div>
          <ProfileModule className="pl-2" />
        </div>
      </div>

      <div className="flex justify-end mt-2 mr-8">
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded-full "
          onClick={handleOpenModal}
        >
          + Add Vehicle
        </button>
      </div>

      <div
        className="mt-4 mx-auto bg-white"
        style={{
          maxWidth: "94vw",
          maxHeight: "70vh",
          overflowX: "auto",
          overflowY: "auto",
        }}
      >
        <table className="w-full text-sm">
          <thead className="  bg-sky-800 text-white z-20">
            <tr>
              <th className="px-8 py-4 text-left whitespace-nowrap">
                Registration Number
              </th>
              <th className="px-8 py-4 text-left whitespace-nowrap">
                Display Number
              </th>
              {/* <th className="px-8 py-4 text-left whitespace-nowrap">
                Vehicle Icon
              </th> */}
              <th className="px-8 py-4 text-left whitespace-nowrap">
                Vehicle Odometer Reading
              </th>
              <th className="px-8 py-4 text-left whitespace-nowrap">
                Vehicle Make
              </th>
              <th className="px-8 py-4 text-left whitespace-nowrap">
                Model Year
              </th>
              <th className="px-8 py-4 text-left whitespace-nowrap">
                IMEI Number
              </th>
              <th className="px-8 py-4 text-left whitespace-nowrap">
                Fuel Type
              </th>
              <th className="px-8 py-4 text-left whitespace-nowrap">
                Expected Mileage
              </th>
              <th className="px-8 py-4 text-left whitespace-nowrap">
                Vehicle Color
              </th>
              <th className="py-2 px-3 font-semibold">Actions</th>
            </tr>
          </thead>
          <tbody>
            {submittedData.map((data, index) => (
              <tr
                key={index}
                className="w-full border-b-2 text-gray-500 border-gray-300 rounded-md bg-white mb-4 transition-transform duration-200 hover:scale-100 hover:shadow-lg"
                style={{ margin: "8px 0" }}
              >
                <td className="px-8 py-4 text-left whitespace-nowrap">
                  {data.registration_number ? data.registration_number : "N/A"}
                </td>
                <td className="px-8 py-4 text-left whitespace-nowrap">
                  {data.display_number ? data.display_number : "N/A"}
                </td>
                {/* <td className="px-8 py-4 text-left whitespace-nowrap">
                  {data.selected_icon_prefix && data.selected_icon_iconName && (
                    <FontAwesomeIcon
                      icon={[
                        data.selected_icon_prefix,
                        data.selected_icon_iconName,
                      ]}
                    />
                  )}
                </td> */}
                <td className="px-8 py-4 text-left whitespace-nowrap">
                  {data.odometer_reading ? data.odometer_reading : "N/A"}
                </td>
                <td className="px-8 py-4 text-left whitespace-nowrap">
                  {data.vehicle_make ? data.vehicle_make : "N/A"}
                </td>
                <td className="px-8 py-4 text-left whitespace-nowrap">
                  {data.model_year ? data.model_year : "N/A"}
                </td>
                <td className="px-8 py-4 text-left whitespace-nowrap">
                  {data.vehicle_identification_number
                    ? data.vehicle_identification_number
                    : "N/A"}
                </td>
                <td className="px-8 py-4 text-left whitespace-nowrap">
                  {data.fuel_type ? data.fuel_type : "N/A"}
                </td>
                <td className="px-8 py-4 text-left whitespace-nowrap">
                  {data.expected_mileage ? data.expected_mileage : "N/A"}
                </td>
                <td className="px-8 py-4 text-left whitespace-nowrap">
                  {data.vehicle_color ? data.vehicle_color : "N/A"}
                </td>
                <td className="px-8 py-4 text-left whitespace-nowrap">
                  <div className="inline-flex space-x-4  gap-2">
                    <FaEdit
                      className="text-blue-500 cursor-pointer "
                      onClick={() => handleEdit(data)}
                    />
                    <FaTrash
                      className="text-red-500 cursor-pointer"
                      onClick={() => handleDelete(data.id)}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 ">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg overflow-y-auto relative h-[500px]">
            <button
              className="absolute top-2 right-2 text-red-500 text-3xl hover:text-red-700 focus:outline-none"
              onClick={handleCloseModal}
            >
              &times;
            </button>

            <h2 className="text-2xl mb-2">Add Vehicle</h2>
            <form onSubmit={handleSubmit}>
              <div className="flex mb-4 space-x-4">
                <div className="w-1/2">
                  <label className="block text-gray-700">
                    Vehicle Registration Number
                  </label>
                  <input
                    type="text"
                    name="registrationNumber"
                    value={formData.registrationNumber}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border rounded"
                    required
                  />
                </div>
                <div className="w-1/2">
                  <label className="block text-gray-700">Display Number</label>
                  <input
                    type="text"
                    name="displayNumber"
                    value={formData.displayNumber}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border rounded"
                    required
                  />
                </div>
              </div>
              {/* <div className="mb-4">
                <div>
                  <div className="flex space-x-4">
                    <FontAwesomeIcon
                      icon={faBus}
                      className={iconClasses(faBus)}
                      onClick={() =>
                        handleIconSelect({
                          prefix: "fa",
                          iconName: "bus",
                          icon: ["bus"],
                        })
                      }
                    />
                    <FontAwesomeIcon
                      icon={faTruck}
                      className={iconClasses(faTruck)}
                      onClick={() =>
                        handleIconSelect({
                          prefix: "fa",
                          iconName: "truck",
                          icon: ["truck"],
                        })
                      }
                    />
                    <FontAwesomeIcon
                      icon={faVanShuttle}
                      className={iconClasses(faVanShuttle)}
                      onClick={() =>
                        handleIconSelect({
                          prefix: "fa",
                          iconName: "VanShuttle",
                          icon: ["VanShuttle"],
                        })
                      }
                    />
                    <FontAwesomeIcon
                      icon={faMotorcycle}
                      className={iconClasses(faMotorcycle)}
                      onClick={() =>
                        handleIconSelect({
                          prefix: "fa",
                          iconName: "Motorcycle",
                          icon: ["Motorcycle"],
                        })
                      }
                    />
                    <FontAwesomeIcon
                      icon={faTractor}
                      className={iconClasses(faTractor)}
                      onClick={() =>
                        handleIconSelect({
                          prefix: "fa",
                          iconName: "Tractor",
                          icon: ["Tractor"],
                        })
                      }
                    />
                    <FontAwesomeIcon
                      icon={faTruckMonster}
                      className={iconClasses(faTruckMonster)}
                      onClick={() =>
                        handleIconSelect({
                          prefix: "fa",
                          iconName: "TruckMonster",
                          icon: ["TruckMonster"],
                        })
                      }
                    />
                  </div>

                  <input
                    type="text"
                    value={selectedIcon ? selectedIcon.iconName : ""}
                    readOnly
                  />
                </div>
              </div> */}

              <div className="flex mb-4 space-x-4">
                <div className="w-1/2">
                  <label className="block text-gray-700">
                    Vehicle Odometer Reading
                  </label>
                  <input
                    type="number"
                    name="odometerReading"
                    value={formData.odometerReading || "N/A"}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border rounded"
                  />
                </div>
                <div className="w-1/2">
                  <label className="block text-gray-700">Vehicle Make</label>
                  <input
                    type="text"
                    name="vehicleMake"
                    value={formData.vehicleMake || ""}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border rounded"
                  />
                </div>
              </div>
              <div className="flex mb-4 space-x-4">
                <div className="w-1/2">
                  <label className="block text-gray-700">Model Year</label>
                  <input
                    type="number"
                    name="modelYear"
                    value={formData.modelYear || ""}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border rounded"
                  />
                </div>
                <div className="W-1-2">
                  <label className="block text-gray-700">IMEI Number</label>
                  <input
                    type="text"
                    name="vehicleIdentificationNumber"
                    value={formData.vehicleIdentificationNumber}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border rounded"
                    required
                  />
                </div>
              </div>
              <div className="flex mb-4 space-x-4">
                <div className="w-full">
                  <label className="block text-gray-700">Vehicle Type</label>
                  <select className="w-full px-3 py-2 border rounded">
                    <option value="">Select One</option>
                    <option value="suv">SUV</option>
                    <option value="hatchback">HatchBack</option>
                    <option value="mpv">MPV</option>
                    <option value="jeep">JEEP</option>
                    <option value="minibus">MINIBUS</option>
                    <option value="bus">BUS</option>
                  </select>
                </div>
              </div>

              <div className="flex mb-4 space-x-4">
                <div className="w-1/2">
                  <label className="block text-gray-700">Fuel Type</label>
                  <select className="w-full px-3 py-2 border rounded">
                    <option value="">Select One</option>
                    <option value="petrol">Petrol</option>
                    <option value="petrol-hybrid">Petrol-Hybrid</option>
                    <option value="diesel">Diesel</option>
                    <option value="diesel-hybrid">Diesel-Hybrid</option>
                    <option value="lpg">LPG</option>
                    <option value="lpg-hybrid">LPG-Hybrid</option>
                  </select>
                </div>
                <div className="w-1/2">
                  <label className="block text-gray-700">
                    Expected Mileage (km/l)
                  </label>
                  <input
                    type="number"
                    name="expectedMileage"
                    value={formData.expectedMileage || "N/A"}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border rounded"
                  />
                </div>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">
                  Choose Vehicle Color
                </label>
                <input
                  type="color"
                  className="w-full px-3 py-2 border rounded"
                />
              </div>
              <div className="flex justify-between mt-4">
                <button
                  type="button"
                  className="bg-gray-500 text-white px-4 py-2 rounded-full"
                  onClick={handleCloseModal}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className={`bg-blue-500 text-white px-4 py-2 rounded-full ${
                    isSubmitting ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default VehicleInventory;
