import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Select from "react-select";
import {
  GoogleMap,
  LoadScript,
  Marker,
  Polyline,
  DirectionsRenderer,
} from "@react-google-maps/api";
import config from "../../config";
import ProfileModule from "../UserProfile";
import moment from "moment-timezone";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt, faMapPin } from "@fortawesome/free-solid-svg-icons";
import { RiMapPinLine } from "react-icons/ri";
import gT from "../../assests/ioi.png";

function HistoryReport() {
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  // const [rangeOption, setRangeOption] = useState('Today');
  const [selectedRange, setSelectedRange] = useState("Today");
  const [locationData, setLocationData] = useState([]);
  const [reportData, setReportData] = useState({});
  const [activeTab, setActiveTab] = useState("All"); // State for active tab
  const [routePath, setRoutePath] = useState([]);
  const [isAnimating, setIsAnimating] = useState(false);
  const [detailedData, setDetailedData] = useState([]);
  const mapRef = useRef(null);
  const [vehicleMarker, setVehicleMarker] = useState(null); // To store vehicle marker
  const [currentIndex, setCurrentIndex] = useState(0);
  const [vehiclePosition, setVehiclePosition] = useState(routePath[0]);
  const [playbackSpeed, setPlaybackSpeed] = useState(1); // Default speed
  const playbackSpeeds = [1, 2, 5, 10]; // Playback speed options
  const GOOGLE_MAPS_API_KEY = `${config.googleMapsApiKey}`;
  const [intervalId, setIntervalId] = useState(null);
  const polylineRef = useRef(null);
  const [addressData, setAddressData] = useState({}); // Store addresses

  useEffect(() => {
    console.log("Detailed Data Changed:", detailedData);
  
    if (!detailedData || detailedData.length === 0) {
      setRoutePath([]); // Clear routePath if no data
      console.log("Route Path Cleared");
      return;
    }
  
    const paths = [];
    const sortedData = detailedData.sort((a, b) => a.timestamp - b.timestamp);
  
    sortedData.forEach((item) => {
      if (item.status === "moving") {
        paths.push({ lat: item.startLat, lng: item.startLon });
        paths.push({ lat: item.endLat, lng: item.endLon });
      }
    });
  
    console.log("Sorted Data:", sortedData);
    console.log("Route Path:", paths);
  
    setRoutePath(paths);
  }, [detailedData]);
  
  useEffect(() => {
    console.log("Route Path Updated:", routePath);
  }, [routePath]);



  useEffect(() => {
    if (mapRef.current) {
      if (polylineRef.current) {
        polylineRef.current.setMap(null); // Remove previous polyline
      }

      if (routePath.length > 0) {
        polylineRef.current = new window.google.maps.Polyline({
          path: routePath,
          strokeColor: "#FF0000",
          strokeOpacity: 1,
          strokeWeight: 2,
          map: mapRef.current,
        });
      }
    }
  }, [routePath]);

 
  const handleMapLoad = (map) => {
    mapRef.current = map;
  };

  

  useEffect(() => {
    if (isAnimating && currentIndex < routePath.length - 1) {
      const startPoint = routePath[currentIndex];
      const endPoint = routePath[currentIndex + 1];

      const deltaLat = (endPoint.lat - startPoint.lat) / 100;
      const deltaLng = (endPoint.lng - startPoint.lng) / 100;

      let progress = 0;
      const intervalDuration = 30 / playbackSpeed; // Adjust based on playback speed
      const interval = setInterval(() => {
        progress += 1;

        if (progress <= 100) {
          const nextLat = startPoint.lat + deltaLat * progress;
          const nextLng = startPoint.lng + deltaLng * progress;
          setVehiclePosition({ lat: nextLat, lng: nextLng });
        } else {
          setCurrentIndex((prevIndex) => prevIndex + 1);
          clearInterval(interval);
        }
      }, intervalDuration); // Interval adjusted by playback speed

      setIntervalId(interval); // Store interval ID for pausing
      return () => clearInterval(interval);
    }
  }, [isAnimating, currentIndex, routePath, playbackSpeed]);

  // Handle start/pause animation
  const handleStartPause = () => {
    if (isAnimating) {
      // Pause the animation
      setIsAnimating(false);
      clearInterval(intervalId); // Clear the interval to pause the animation
    } else {
      // Start the animation from the start location
      setCurrentIndex(0); // Reset to start location
      setIsAnimating(true);
    }
  };

  useEffect(() => {
    fetchSuggestions();
  }, []);

  useEffect(() => {
    if (activeTab === "All") {
      fetchData();
    }
  }, [activeTab]);

  useEffect(() => {
    handleDateChange();
    fetchData();
  }, [selectedRange, selectedVehicle]);

  // const setDateRange = (value) => {
  //     handleDateChange();
  //     setRangeOption(value);
  // };
  // const handleDateChange = () => {
  //     let fromDate, toDate;

  //     switch (selectedOption) {
  //         case 'Today':
  //             fromDate = moment().subtract(5, 'hours').subtract(30, 'minutes').startOf('day').toDate();
  //             toDate = moment().subtract(5, 'hours').subtract(30, 'minutes').endOf('day').subtract(1, 'minute').toDate();

  //         case 'Yesterday':
  //             fromDate = moment().subtract(1, 'days').subtract(5, 'hours').subtract(30, 'minutes').startOf('day').toDate();
  //             toDate = moment().subtract(1, 'days').subtract(5, 'hours').subtract(30, 'minutes').endOf('day').subtract(1, 'minute').toDate();

  //         case 'Last 7 Days':
  //             fromDate = moment().subtract(6, 'days').subtract(5, 'hours').subtract(30, 'minutes').startOf('day').toDate();
  //             toDate = moment().subtract(5, 'hours').subtract(30, 'minutes').endOf('day').subtract(1, 'minute').toDate();

  //         case 'Last 30 Days':
  //             fromDate = moment().subtract(30, 'days').subtract(5, 'hours').subtract(30, 'minutes').startOf('day').toDate();
  //             toDate = moment().subtract(5, 'hours').subtract(30, 'minutes').endOf('day').subtract(1, 'minute').toDate();

  //         case 'Custom':
  //             fromDate = fromDate; // Keep existing fromDate if it's set
  //             toDate = toDate;     // Keep existing toDate if it's set
  //             break;
  //         default:
  //             fromDate = null;
  //             toDate = null;
  //             break;
  //     }

  //     // Update state
  //     setRangeOption(selectedOption);
  //     setFromDate(fromDate);
  //     setToDate(toDate);
  // };
  const handleDateChange = () => {
    let fromDate, toDate;
    console.log(selectedRange, "selectedRange");

    if (selectedRange === "Today") {
      fromDate = moment()
        .subtract(5, "hours")
        .subtract(30, "minutes")
        .startOf("day")
        .toDate();
      toDate = moment()
        .subtract(5, "hours")
        .subtract(30, "minutes")
        .endOf("day")
        .subtract(1, "minute")
        .toDate();
    } else if (selectedRange === "Yesterday") {
      fromDate = moment()
        .subtract(1, "days")
        .subtract(5, "hours")
        .subtract(30, "minutes")
        .startOf("day")
        .toDate();
      toDate = moment()
        .subtract(1, "days")
        .subtract(5, "hours")
        .subtract(30, "minutes")
        .endOf("day")
        .subtract(1, "minute")
        .toDate();
    } else if (selectedRange === "Last 7 Days") {
      fromDate = moment()
        .subtract(6, "days")
        .subtract(5, "hours")
        .subtract(30, "minutes")
        .startOf("day")
        .toDate();
      toDate = moment()
        .subtract(5, "hours")
        .subtract(30, "minutes")
        .endOf("day")
        .subtract(1, "minute")
        .toDate();
    } else if (selectedRange === "Last 15 Days") {
      fromDate = moment()
        .subtract(14, "days")
        .subtract(5, "hours")
        .subtract(30, "minutes")
        .startOf("day")
        .toDate();
      toDate = moment()
        .subtract(5, "hours")
        .subtract(30, "minutes")
        .endOf("day")
        .subtract(1, "minute")
        .toDate();
    } else if (selectedRange === "Last 30 Days") {
      fromDate = moment()
        .subtract(29, "days")
        .subtract(5, "hours")
        .subtract(30, "minutes")
        .startOf("day")
        .toDate();
      toDate = moment()
        .subtract(5, "hours")
        .subtract(30, "minutes")
        .endOf("day")
        .subtract(1, "minute")
        .toDate();
    } else if (selectedRange === "Custom") {
      // Handle custom date range case
      if (fromDate == null || toDate == null) {
        // Ensure fromDate and toDate are set by the user if 'Custom' is selected
        return { fromDate: null, toDate: null };
      }
    } else {
      fromDate = moment()
        .subtract(5, "hours")
        .subtract(30, "minutes")
        .startOf("day")
        .toDate();
      toDate = moment()
        .subtract(5, "hours")
        .subtract(30, "minutes")
        .endOf("day")
        .subtract(1, "minute")
        .toDate();
    }

    setFromDate(fromDate);
    setToDate(toDate);

    return { fromDate, toDate };
  };

  const fetchData = async () => {
    try {
      const { fromDate, toDate } = handleDateChange(); // Ensure dates are updated

      console.log("From Date:", fromDate);
      console.log("To Date:", toDate); // Get date range
      console.log(selectedVehicle, "vehicle");

      if (!selectedVehicle) {
        console.log("No vehicle selected.");
        return; // Exit the function if no vehicle is selected
      }
      // Make API calls
      const [locationResponse, reportResponse, alldatasResponse] =
        await Promise.all([
          axios.get(`${config.apiUrl}/vehicle/historylocation`, {
            params: { fromDate, toDate, vehicleNumber: selectedVehicle?.value },
          }),
          axios.post(`${config.apiUrl}/report/history_report`, {
            fromDate,
            toDate,
            vehicleNumber: selectedVehicle?.value,
          }),
          axios.post(`${config.apiUrl}/report/history_alldatas`, {
            fromDate,
            toDate,
            vehicleNumber: selectedVehicle?.value,
          }),
        ]);

      // Process and set the data
      const locationData = locationResponse.data;
      const reportData = reportResponse.data.summary;
      const detailedData = alldatasResponse.data.detailedData;

      console.log("Detailed Data Response:", alldatasResponse.data);
      console.log("Detailed Data:", detailedData);
      console.log(locationData, "locationData");
      console.log(reportData, "reportData");

      // Check if the data is empty and handle accordingly
      if (detailedData.length === 0) {
        console.log("No detailed data available.");
        setDetailedData([]);
      } else {
        setDetailedData(detailedData);
      }

      setLocationData(locationData);
      setReportData(reportData);
        //  setDetailedData(detailedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //     const fetchData = async () => {
  //       try {
  //           let formattedFromDate, formattedToDate;

  //           switch (rangeOption) {
  //               case 'Today':
  //                   formattedFromDate = moment().startOf('day').format('YYYY-MM-DD');
  //                   formattedToDate = moment().endOf('day').format('YYYY-MM-DD');
  //                   break;
  //               case 'Yesterday':
  //                   formattedFromDate = moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD');
  //                   formattedToDate = moment().subtract(1, 'day').endOf('day').format('YYYY-MM-DD');
  //                   break;
  //               case 'Last 7 Days':
  //                   formattedFromDate = moment().subtract(6, 'days').startOf('day').format('YYYY-MM-DD');
  //                   formattedToDate = moment().endOf('day').format('YYYY-MM-DD');
  //                   break;
  //               case 'Last 30 Days':
  //                   formattedFromDate = moment().subtract(29, 'days').startOf('day').format('YYYY-MM-DD');
  //                   formattedToDate = moment().endOf('day').format('YYYY-MM-DD');
  //                   break;
  //               case 'Custom':
  //                   formattedFromDate = moment(fromDate).format('YYYY-MM-DD');
  //                   formattedToDate = moment(toDate).format('YYYY-MM-DD');
  //                   break;
  //               default:
  //                   formattedFromDate = moment().startOf('day').format('YYYY-MM-DD');
  //                   formattedToDate = moment().endOf('day').format('YYYY-MM-DD');
  //                   break;
  //           }

  //           const [locationResponse, reportResponse,alldatasResponse] = await Promise.all([
  //             axios.get(`${config.apiUrl}/vehicle/historylocation`, {
  //                 params: { fromDate: formattedFromDate, toDate: formattedToDate, vehicleNumber: selectedVehicle?.value }
  //             }),
  //             axios.post(`${config.apiUrl}/report/history_report`, {
  //                 fromDate: formattedFromDate,
  //                 toDate: formattedToDate,
  //                 vehicleNumber: selectedVehicle?.value
  //             }),
  //             axios.post(`${config.apiUrl}/report/history_alldatas`, {
  //               fromDate: formattedFromDate,
  //               toDate: formattedToDate,
  //               vehicleNumber: selectedVehicle?.value
  //           })

  //         ]);

  //           const locationData = locationResponse.data;
  //           const reportData = reportResponse.data.data;

  //           console.log('Detailed Data Response:', alldatasResponse.data); // Add this line to check the response
  //           const detailedData = alldatasResponse.data.detailedData;

  //           setDetailedData(detailedData);
  //           console.log('Detailed Data:', detailedData); // Log the detailedData state

  //           console.log(locationData,"locationData");
  //           console.log(reportData,"reportData");

  //           setDetailedData(detailedData)
  //           setLocationData(locationData);
  //           setReportData(reportData);
  //       } catch (error) {
  //           console.error('Error fetching data:', error);
  //       }
  //   };

  const fetchSuggestions = async () => {
    try {
      const response = await axios.get(
        `${config.apiUrl}/vehicle/getSuggestions`
      );
      const formattedSuggestions = response.data.map((suggestion) => ({
        label: suggestion,
        value: suggestion,
      }));
      setSuggestions(formattedSuggestions);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  const handleVehicleChange = (selectedOption) => {
    setSelectedVehicle(selectedOption);
    console.log(selectedOption, "selectedOption");
  };

  //   const fetchLocationData = async () => {
  //       const formattedFromDate = moment(fromDate).tz('Asia/Kolkata').startOf('day').format('YYYY-MM-DDTHH:mm:ss');
  //       const formattedToDate = moment(toDate).tz('Asia/Kolkata').endOf('day').format('YYYY-MM-DDTHH:mm:ss');

  //       try {
  //           const response = await axios.get(`${config.apiUrl}/vehicle/history-location`, {
  //               params: {
  //                   fromDate: formattedFromDate,
  //                   toDate: formattedToDate,
  //                   vehicleNumber: selectedVehicle?.value // Pass the selected vehicle number here
  //               }
  //           });
  //           console.log('API Response:', response.data);
  //           setData(response.data);

  //           // if (response.data.length > 0) {
  //           //   const startLocation = response.data[0];
  //           //   const endLocation = response.data[response.data.length - 1];

  //           //   const startAddr = await fetchAddress(startLocation.lat, startLocation.lon);
  //           //   const endAddr = await fetchAddress(endLocation.lat, endLocation.lon);

  //           //   setStartAddress(startAddr);
  //           //   setEndAddress(endAddr);
  //           // }
  //       } catch (error) {
  //           console.error('Error fetching location data:', error);
  //       }
  //   };

  //   const fetchReportData = async () => {
  //     const formattedFromDate = moment(fromDate).tz('Asia/Kolkata').startOf('day').format('YYYY-MM-DDTHH:mm:ss');
  //     const formattedToDate = moment(toDate).tz('Asia/Kolkata').endOf('day').format('YYYY-MM-DDTHH:mm:ss');

  //     try {
  //         const response = await axios.post(`${config.apiUrl}/vehicle/history_Report`, {
  //             fromDate: formattedFromDate,
  //             toDate: formattedToDate,
  //             vehicleNumber: selectedVehicle?.value
  //         });
  //         console.log('API Response:', response.data);
  //         setReportData(response.data.data); // Set the report data
  //     } catch (error) {
  //         console.error('Error fetching report data:', error);
  //     }
  // };

  //   const fetchAddress = async (lat, lon) => {
  //     const apiKey = "AIzaSyDciM17HrWOucxREypzzWE7KJ_wMqTVoZ0"; // Replace with your actual API key
  //     try {
  //         const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
  //             params: {
  //                 latlng: `${lat},${lon}`,
  //                 key: apiKey
  //             }
  //         });
  //         if (response.data.results.length > 0) {
  //             return response.data.results[0].formatted_address; // Return the formatted address
  //         }
  //         return 'Address not found';
  //     } catch (error) {
  //         console.error('Error fetching address:', error);
  //         return 'Error retrieving address';
  //     }
  // };

  const apiKey = "AIzaSyDciM17HrWOucxREypzzWE7KJ_wMqTVoZ0";

//   const getAddressFromLatLon = async (lat, lon) => {
//     const apiKey = "AIzaSyDciM17HrWOucxREypzzWE7KJ_wMqTVoZ0"; // Replace with your API key
//     const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${apiKey}`;

//     try {
//       const response = await axios.get(url);
//       if (response.data.results.length > 0) {
//         return response.data.results[0].formatted_address; // Return formatted address
//       }
//       return "Unknown location";
//     } catch (error) {
//       console.error("Error fetching address:", error);
//       return "Unknown location";
//     }
//   };

//   useEffect(() => {
//     // Function to fetch addresses
//     const fetchAddresses = async (item) => {
//       if (!addressData[item.startLat]) {
//         const startAddress = await getAddressFromLatLon(item.startLat || item.lat, item.startLon || item.lon);
//         setAddressData((prevState) => ({
//           ...prevState,
//           [item.startLat]: startAddress,
//         }));
//       }
//       if (!addressData[item.endLat]) {
//         const endAddress = await getAddressFromLatLon(item.endLat || item.lat, item.endLon || item.lon);
//         setAddressData((prevState) => ({
//           ...prevState,
//           [item.endLat]: endAddress,
//         }));
//       }
//     };
  
//     // Iterate over detailedData and fetch addresses for each item
//     if (detailedData && detailedData.length > 0) {
//       detailedData.forEach((item) => {
//         fetchAddresses(item);
//       });
//     }
//   }, [detailedData]); 


//  const renderData = () => {
//   if (!detailedData || detailedData.length === 0) {
//     return <div>No detailed data available.</div>;
//   }

//   const preparedData = detailedData.map((item) => ({
//     ...item,
//     fixTime: moment(
//       item.startStopTime || item.startMovingTime || item.startIdleTime
//     ).valueOf(), // Convert to timestamp for sorting
//   }));

//   const filteredData =
//     activeTab === "All"
//       ? preparedData.sort((a, b) => b.fixTime - a.fixTime)
//       : activeTab === "Movements"
//       ? preparedData
//           .filter((item) => item.status === "moving")
//           .sort((a, b) => b.fixTime - a.fixTime)
//       : preparedData
//           .filter((item) => item.status === "stopped")
//           .sort((a, b) => b.fixTime - a.fixTime);

//   return filteredData.map((item, index) => {
//     let statusClass = "";
//     let statusText = "";
//     if (item.status === "stopped") {
//       statusClass = "text-red-500";
//       statusText = "Stopped for";
//     } else if (item.status === "idle") {
//       statusClass = "text-amber-500";
//       statusText = "Idle for";
//     } else if (item.status === "moving") {
//       statusClass = "text-green-500";
//       statusText = "";
//     }

//     return (
//       <div key={index} className="flex flex-col mb-4">
//         {statusText && (
//           <div className={`font-semibold ${statusClass}`}>
//             {statusText} {item.duration}
//           </div>
//         )}
//         {item.status === "stopped" && (
//           <>
//             <div className="flex justify-between">
//               <RiMapPinLine className="h-5 text-red-500" />
//               <span>{addressData[item.startLat] || "Loading address..."}</span>
//               <span>{moment(item.startStopTime).format("HH:mm")}</span>
//             </div>
//             <div className="flex justify-between">
//               <RiMapPinLine className="h-5 text-red-500" />
//               <span>{addressData[item.endLat] || "Loading address..."}</span>
//               <span>{moment(item.endStopTime).format("HH:mm")}</span>
//             </div>
//           </>
//         )}
//         {item.status === "moving" && (
//           <>
//             <div className="font-semibold text-green-500">
//               {item.distance} km in {item.duration}
//             </div>
//             <div className="flex justify-between">
//               <RiMapPinLine className="h-5 text-green-500" />
//               <span>{addressData[item.startLat] || "Loading address..."}</span>
//               <span>{moment(item.startMovingTime).format("HH:mm")}</span>
//             </div>
//             <div className="flex justify-between">
//               <RiMapPinLine className="h-5 text-green-500" />
//               <span>{addressData[item.endLat] || "Loading address..."}</span>
//               <span>{moment(item.endMovingTime).format("HH:mm")}</span>
//             </div>
//           </>
//         )}
//         {item.status === "idle" && (
//           <>
//             <div className="flex justify-between">
//               <RiMapPinLine className="h-5 text-amber-500" />
//               <span>{addressData[item.startLat] || "Loading address..."}</span>
//               <span>{moment(item.startIdleTime).format("HH:mm")}</span>
//             </div>
//             <div className="flex justify-between">
//               <RiMapPinLine className="h-5 text-amber-500" />
//               <span>{addressData[item.endLat] || "Loading address..."}</span>
//               <span>{moment(item.endIdleTime).format("HH:mm")}</span>
//             </div>
//           </>
//         )}
//       </div>
//     );
//   });
// };
const renderData = () => {
  if (!detailedData || detailedData.length === 0) {
    return <div>No detailed data available.</div>;
  }

  const preparedData = detailedData.map((item) => {
    return {
      ...item,
      fixTime: moment(
        item.startStopTime || item.startMovingTime || item.startIdleTime
      ).valueOf(), // Convert to timestamp for sorting
    };
  });

  // Filter data based on active tab
  const filteredData =
    activeTab === "All"
      ? preparedData.sort((a, b) => b.fixTime - a.fixTime)
      : activeTab === "Movements"
      ? preparedData
          .filter((item) => item.status === "moving")
          .sort((a, b) => b.fixTime - a.fixTime) // Only moving data sorted
      : preparedData
          .filter((item) => item.status === "stopped")
          .sort((a, b) => b.fixTime - a.fixTime); // Only stopped data sorted

  return filteredData.map((item, index) => {
    // Prepare time and location formatting
    const startLatLon = `${item.startLat || item.lat}, ${
      item.startLon || item.lon
    }`;
    const endLatLon = `${item.endLat || item.lat}, ${
      item.endLon || item.lon
    }`;
    const fixTimeStart = moment(
      item.startStopTime || item.startMovingTime || item.startIdleTime
    ).format("HH:mm"); // Format start time
    const fixTimeEnd = moment(
      item.endStopTime || item.endMovingTime || item.endIdleTime
    ).format("HH:mm"); // Format end time

    // Determine the status color
    let statusClass = "";
    let statusText = "";

    if (item.status === "stopped") {
      statusClass = "text-red-500"; // Red color for stopped
      statusText = "Stopped for";
    } else if (item.status === "idle") {
      statusClass = "text-amber-500"; // Amber color for idle
      statusText = "Idle for";
    } else if (item.status === "moving") {
      statusClass = "text-green-500"; // Green color for moving
      statusText = ""; // No text for moving status
    }

    return (
      <div key={index} className="flex flex-col mb-4 ">
        {statusText && (
          <div className={`font-semibold ${statusClass}`}>
            {statusText} {item.duration}
          </div>
        )}
        {item.status === "stopped" && (
          <>
            <div className="flex justify-between">
              <RiMapPinLine className="h-5 text-red-500" />
              <span>{startLatLon}</span>
              <span>{fixTimeStart}</span>
            </div>
            <div className="flex justify-between">
              <RiMapPinLine className="h-5 text-red-500" />
              <span>{endLatLon}</span>
              <span>{fixTimeEnd}</span>
            </div>
          </>
        )}
        {item.status === "moving" && (
          <>
            <div className="font-semibold text-green-500">
              {item.distance} km in {item.duration}{" "}
              {/* Show duration and distance for moving */}
            </div>
            <div className="flex justify-between">
              <RiMapPinLine className="h-5 text-green-500" />
              <span>{startLatLon}</span>
              <span>{fixTimeStart}</span>
            </div>
            <div className="flex justify-between">
              <RiMapPinLine className="h-5 text-green-500" />
              <span>{endLatLon}</span>
              <span>{fixTimeEnd}</span>
            </div>
          </>
        )}
        {item.status === "idle" && (
          <>
            <div className="flex justify-between">
              <RiMapPinLine className="h-5 text-amber-500" />
              <span>{startLatLon}</span>
              <span>{fixTimeStart}</span>
            </div>
            <div className="flex justify-between">
              <RiMapPinLine className="h-5 text-amber-500" />
              <span>{endLatLon}</span>
              <span>{fixTimeEnd}</span>
            </div>
          </>
        )}
      </div>
    );
  });
};


  const mapStyles = {
    height: "500px",
    border: "1px solid #000",
  };

  const defaultCenter = {
    lat: 9.9497,
    lng: 78.1479,
  };

  return (
    <div>
      <div className="flex justify-between items-center h-[10vh] border-b-2 border-gray-300">
        <div className="flex flex-col items-center">
          <h3>Vehicle</h3>
          <h2 className="text-lg font-semibold text-gray-500 mb-2 text-center">
            Vehicle Details
          </h2>
        </div>
        <div className="pl-2">
          <ProfileModule />
        </div>
      </div>
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-1 p-4 ">
          <label
            htmlFor="vehicle"
            className="block text-sm font-medium text-gray-700 "
          >
            Select Vehicle
          </label>
          <Select
            id="vehicle"
            name="vehicle"
            value={selectedVehicle}
            onChange={handleVehicleChange}
            options={suggestions}
            className="mt-1 block w-full py-2 px-3 mb-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            placeholder="Start typing to select a vehicle"
          />
          {/* Date Range Selection */}
          <div className="date-options flex flex-wrap gap-2 mb-4">
            <div className="relative w-full">
              <Select
                options={[
                  { label: "Today", value: "Today" },
                  { label: "Yesterday", value: "Yesterday" },
                  { label: "Last 7 Days", value: "Last 7 Days" },
                  { label: "Last 15 Days", value: "Last 15 Days" },
                  { label: "Last 30 Days", value: "Last 30 Days" },
                  { label: "Custom", value: "Custom" },
                ]}
                onChange={(selectedOption) =>
                  setSelectedRange(selectedOption.value)
                }
                placeholder="Select Date Range"
                className="w-full"
              />
              {selectedRange === "Custom" && (
                <div className="flex flex-col sm:flex-row sm:space-x-4 mt-4">
                  <input
                    type="date"
                    value={
                      fromDate ? moment(fromDate).format("YYYY-MM-DD") : ""
                    }
                    onChange={(e) => setFromDate(new Date(e.target.value))}
                    className="border border-gray-300 rounded-lg p-2 w-full sm:w-1/2"
                  />
                  <input
                    type="date"
                    value={toDate ? moment(toDate).format("YYYY-MM-DD") : ""}
                    onChange={(e) => setToDate(new Date(e.target.value))}
                    className="border border-gray-300 rounded-lg p-2 w-full sm:w-1/2"
                  />
                </div>
              )}
            </div>
          </div>

          {/* Location Data Display */}
          <div className="location-data-container mb-4">
            {locationData.map((point, index) => {
              const formattedTime = moment(point.fixTime)
                .tz("Asia/Kolkata")
                .format("YYYY-MM-DD HH:mm:ss");

              return (
                <div
                  className="flex items-center justify-between mb-3"
                  key={index}
                >
                  <div className="flex items-start">
                    <RiMapPinLine className="mr-3 h-5 w-4 mt-0 text-blue-700" />
                    <span className="text-sm break-words mr-4">
                      ( {point.lat}, {point.lon} )
                    </span>
                  </div>
                  <span className="text-sm text-gray-500 mr-8">
                    {formattedTime}
                  </span>
                </div>
              );
            })}
          </div>

          {/* Movement Data Table */}
          <div className="movement-data-table mb-4">
            <table className="w-18 divide-y divide-gray-200 border border-gray-300">
              <thead>
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-pre-wrap">
                    TravelTime
                    <br />
                    (HH:mm)
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-pre-wrap">
                    Distance
                    <br />
                    (kmph)
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-pre-wrap">
                    StoppedTime
                    <br />
                    (HH:mm)
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {/* Render summary data */}
                <tr>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {reportData.totalMoving || "N/A"}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {reportData.totalDistance || "N/A"}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {reportData.totalStopped || "N/A"}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* Tab Bar */}
          <div className="tab-bar mt-4 mb-4">
            <div className="flex justify-around border-t border-gray-300 pt-2">
              {["All", "Movements", "Stopped"].map((tab) => (
                <button
                  key={tab}
                  className={`px-4 py-2 relative ${
                    activeTab === tab ? "text-blue-600" : "text-gray-600"
                  }`}
                  onClick={() => setActiveTab(tab)}
                >
                  {tab}
                  {activeTab === tab && (
                    <span className="absolute left-0 bottom-0 w-full h-1 bg-blue-600"></span>
                  )}
                </button>
              ))}
            </div>
            <div className="report-section p-4 border-t border-gray-300 max-h-40 overflow-y-auto">
              {renderData()}
            </div>
          </div>
        </div>

        <div className="col-span-2 p-4">
        <LoadScript googleMapsApiKey={apiKey}>
          <GoogleMap
          mapContainerStyle={mapStyles}
          center={defaultCenter}
          zoom={14}
          onLoad={handleMapLoad}
        >
              {locationData.map((point, index) => (
                <Marker
                  key={index}
                  position={{ lat: point.lat, lng: point.lon }}
                  title={point.location}
                />
              ))}
{/* 
              {routePath.length > 0 && (
                <Polyline
                  path={routePath}
                  options={{
                    strokeColor: "#FF0000",
                    strokeOpacity: 1,
                    strokeWeight: 2,
                  }}
                />
              )} */}

              {vehiclePosition && (
                <Marker
                  position={vehiclePosition}
                  icon={{
                    url: gT,
                    scaledSize: new window.google.maps.Size(32, 32),
                  }}
                  title="Vehicle"
                />
              )}
            </GoogleMap>
            <button
              onClick={handleStartPause}
              className="mt-4 p-2 bg-blue-500 text-white rounded z-50"
            >
              {isAnimating ? "Pause" : "Start"}
            </button>
            <select
              value={playbackSpeed}
              onChange={(e) => setPlaybackSpeed(Number(e.target.value))}
              className="mt-4 p-2 border rounded"
            >
              {playbackSpeeds.map((speed) => (
                <option key={speed} value={speed}>
                  {speed}x
                </option>
              ))}
            </select>
          </LoadScript>
        </div>
      </div>
    </div>
  );
}

export default HistoryReport;
