import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import {
  FaSearch,
  FaTrash,
  FaEdit,
  FaCalendarAlt,
  FaArrowAltCircleLeft,
} from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import ProfileModule from "../UserProfile";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polyline,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { useNavigate } from "react-router-dom";
import L from "leaflet";
import config from "../../config"; // Assuming config.js is correctly configured
 import TripAlert from "./Tripalert";

function Trip() {
  const [assignVehicle, setAssignVehicle] = useState("");
  const [drivername, setDriverName] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [tripId, setTripId] = useState(null);

  const [loading, setLoading] = useState(true);
  const [searchTripId, setSearchTripId] = useState("");
  const [filteredTrip, setFilteredTrip] = useState(null);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [fromDate, setFromDate] = useState(moment().startOf("day").toDate()); // Start of today
  const [toDate, setToDate] = useState(moment().endOf("day").toDate()); // End of today

  // const [rangeOption, setRangeOption] = useState("Today");
  const [editTrip, setEditTrip] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [showAssignVehicleCheckbox, setShowAssignVehicleCheckbox] =
    useState(false);
  const [showAssignDriverCheckbox, setShowAssignDriverCheckbox] =
    useState(false);
  const [vehicleCheckboxChecked, setVehicleCheckboxChecked] = useState(false);
  const [driverCheckboxChecked, setDriverCheckboxChecked] = useState(false);
  const [isRouteSelected, setIsRouteSelected] = useState(false);

  const [stops, setStops] = useState([
    { address: "", arrivalTime: "", departureTime: "" , isEditing: false,},
  ]);
  const [tripDelayAlerts, setTripDelayAlerts] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [tripDetails, setTripDetails] = useState([]);
  const [filteredTripDetails, setFilteredTripDetails] = useState([]);
  const [detailLoading, setDetailLoading] = useState(true);
  const [error, setError] = useState(null);
  const [trips, setTrips] = useState([]);
  const [sourceAddress, setSourceAddress] = useState("");
  const [sourcearrivalTime, setSourceArrivalTime] = useState(null);
  const [sourcedepartureTime, setSourceDepartureTime] = useState(null);
  const [destinationAddress, setDestinationAddress] = useState("");
  const [destinationarrivalTime, setDestinationArrivalTime] = useState(null);
  const [destinationdepartureTime, setDestinationDepartureTime] =
    useState(null);
  const [sourceSuggestions, setSourceSuggestions] = useState([]);
  const [destinationSuggestions, setDestinationSuggestions] = useState([]);
  const [noSourceSuggestions, setNoSourceSuggestions] = useState(false);
  const [noDestinationSuggestions, setNoDestinationSuggestions] =
    useState(false);
  const [checkpointsuggestions, setCheckPointSuggestions] = useState([]);
  const [noSuggestions, setNoSuggestions] = useState(false);
  const [source, setSource] = useState({ lat: "", lon: "" });
  const [destination, setDestination] = useState({ lat: "", lon: "" });
  const [isUserInput, setIsUserInput] = useState(true);
  const [callApi, setCallApi] = useState(false);
  const [streetName, setStreetName] = useState("");
  const [hasInitialized, setHasInitialized] = useState(false);
  const [loadingSource, setLoadingSource] = useState(false);
  const [loadingDestination, setLoadingDestination] = useState(false);
  const [routePath, setRoutePath] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editSchedule, setEditSchedule] = useState(null);

  const handleVehicleFocus = () => setShowAssignVehicleCheckbox(true);
  const handleDriverFocus = () => setShowAssignDriverCheckbox(true);
  const handleVehicleCheckboxChange = (e) => {
    setVehicleCheckboxChecked(e.target.checked);
  };
  const handleDriverCheckboxChange = () =>
    setDriverCheckboxChecked((prev) => !prev);

  // const { vehicle } = location.state;
  const navigate = useNavigate();

  const OPENCAGE_API_KEY = "062d4cf071d04326b6d1312fbfda0cbd";
  const OPENCAGE_URL = "https://api.opencagedata.com/geocode/v1/json";

  useEffect(() => {
    let newRoutePath = [];

    if (source && !isNaN(source.lat) && !isNaN(source.lon)) {
      newRoutePath.push([source.lat, source.lon]);
    }

    if (Array.isArray(stops)) {
      stops.forEach((stop) => {
        const lat = parseFloat(stop.lat);
        const lon = parseFloat(stop.lon);
        if (!isNaN(lat) && !isNaN(lon)) {
          newRoutePath.push([lat, lon]);
        }
      });
    }

    if (destination && !isNaN(destination.lat) && !isNaN(destination.lon)) {
      newRoutePath.push([destination.lat, destination.lon]);
    }

    setRoutePath(newRoutePath);
  }, [source, destination, stops]);

  useEffect(() => {
    const fetchLatLon = async () => {
      // Create an array of promises
      const promises = [];

      if (sourceAddress.trim()) {
        promises.push(
          getLatLonFromAddress(sourceAddress)
            .then(({ lat, lon }) => setSource({ lat, lon }))
            .catch((error) =>
              console.error("Error fetching source address coordinates:", error)
            )
        );
      }

      if (destinationAddress.trim()) {
        promises.push(
          getLatLonFromAddress(destinationAddress)
            .then(({ lat, lon }) => setDestination({ lat, lon }))
            .catch((error) =>
              console.error(
                "Error fetching destination address coordinates:",
                error
              )
            )
        );
      }

      // Await all promises to complete
      try {
        await Promise.all(promises);
      } catch (error) {
        console.error("Error fetching addresses:", error);
      }
    };

    fetchLatLon();
  }, [sourceAddress, destinationAddress]);

  // if (source && !isNaN(source.lat) && !isNaN(source.lon)) {
  //     routePath.push([source.lat, source.lon]);
  // }
  // stops.forEach(stop => {
  //     const lat = parseFloat(stop.lat);
  //     const lon = parseFloat(stop.lon);
  //     if (!isNaN(lat) && !isNaN(lon)) {
  //         routePath.push([lat, lon]);
  //     }
  // });
  // if (destination && !isNaN(destination.lat) && !isNaN(destination.lon)) {
  //     routePath.push([destination.lat, destination.lon]);
  // }

  // const route = source && destination ? [source, destination] : [];
  // const routePath = route.map(point => [point.lat, point.lon]);

  useEffect(() => {
    if (!callApi && !hasInitialized) {
      if ((isUserInput || streetName.length > 10) && !!streetName) {
        const handler = setTimeout(() => {
          fetchSuggestions();
        }, 1000);

        return () => {
          clearTimeout(handler);
        };
      }
    }
  }, [streetName, isUserInput, callApi]);

  useEffect(() => {
    fetchvehicleSuggestions();
  }, []);

  // Function to fetch suggestions from backend
  const fetchvehicleSuggestions = async () => {
    try {
      const response = await axios.get(
        `${config.apiUrl}/vehicle/getSuggestions`
      );
      setSuggestions(response.data); // Assuming response.data is an array of suggestion strings
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  useEffect(() => {
    fetchDrivers();
  }, []);

  // Function to fetch drivers from backend
  const fetchDrivers = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/mydrivers/drivers`);
      const driverNames = response.data.map((driver) => driver.name);
      setDrivers(driverNames);
      console.log(driverNames, "driverNames"); // Assuming response.data is an array of driver objects
    } catch (error) {
      console.error("Error fetching drivers:", error);
    }
  };

  useEffect(() => {
    fetchTripDetails();
  }, []);

  const fetchTripDetails = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/trip/GetAllTrips`);
      setTripDetails(response.data);
      console.log("Fetched Trip Details:", response);
      setFilteredTripDetails(response.data); // Initially show all trips
      setLoading(false);
    
    } catch (err) {
      console.error("Error fetching trip details:", err);
      setError("Error fetching trip details");
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   const filterTripsByDate = (trips) => {
  //     const today = moment().startOf("day");
  //     const yesterday = moment().subtract(1, "days").startOf("day");
  //     const last7Days = moment().subtract(6, "days").startOf("day");
  //     const last30Days = moment().subtract(29, "days").startOf("day");

  //     let filteredTrips = [];

  //     switch (rangeOption) {
  //       case "Today":
  //         filteredTrips = trips.filter((trip) =>
  //           moment(trip.sourcearrivalTime).isSame(today, "day")
  //         );
  //         break;
  //       case "Yesterday":
  //         filteredTrips = trips.filter((trip) =>
  //           moment(trip.sourcearrivalTime).isSame(yesterday, "day")
  //         );
  //         break;
  //       case "Last 7 Days":
  //         filteredTrips = trips.filter((trip) =>
  //           moment(trip.sourcearrivalTime).isBetween(last7Days, today, "day", "[]")
  //         );
  //         break;
  //       case "Last 30 Days":
  //         filteredTrips = trips.filter((trip) =>
  //           moment(trip.sourcearrivalTime).isBetween(last30Days, today, "day", "[]")
  //         );
  //         break;
  //       case "Custom":
  //         filteredTrips = trips.filter((trip) =>
  //           moment(trip.sourcearrivalTime).isBetween(moment(fromDate).startOf("day"), moment(toDate).endOf("day"), "day", "[]")
  //         );
  //         break;
  //       default:
  //         filteredTrips = trips; // Show all trips if no filter is applied
  //         break;
  //     }

  //     return filteredTrips;
  //   };

  //   if (tripDetails.length) {
  //     setFilteredTripDetails(filterTripsByDate(tripDetails));
  //   }
  // }, [rangeOption, fromDate, toDate, tripDetails]);
  // if (loading) {
  //   return <div>Loading...</div>;
  // }

  // if (error) {
  //   return <div className="text-red-500">{error}</div>;
  // }
  const fetchSuggestions = async (
    query,
    setSuggestions,
    setLoading,
    setNoSuggestions
  ) => {
    if (query.length < 3) {
      setSuggestions([]);
      setNoSuggestions(false);
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
          query
        )}&addressdetails=1&limit=5`
      );
      const data = await response.json();
      setLoading(false);

      if (data.length > 0) {
        setSuggestions(data);
        setNoSuggestions(false);
      } else {
        setSuggestions([]);
        setNoSuggestions(true);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching suggestions:", error);
    }
  };

  const handleSourceAddressChange = (e) => {
    const value = e.target.value;
    setSourceAddress(value);
   
      fetchSuggestions(
        value,
        setSourceSuggestions,
        setLoadingSource,
        setNoSourceSuggestions
      );
   
  };

  // Function to handle destination address change
  const handleDestinationAddressChange = (e) => {
    const value = e.target.value;
    setDestinationAddress(value);
    fetchSuggestions(
      value,
      setDestinationSuggestions,
      setLoadingDestination,
      setNoDestinationSuggestions
    );
  };

  const handleSourceSelect = (address) => {
    setSourceAddress(address);
    setSourceSuggestions([]);
   
  };

  const handleDestinationSelect = (address) => {
    setDestinationAddress(address);
    setDestinationSuggestions([]);
   
  };

  const handleClearDestination = () => {
    setDestinationAddress("");
 setDestinationArrivalTime("");
  setDestinationDepartureTime('')
 
    setDestination({ lat: "", lon: "" });
    if (isEditing) {
      setIsEditing(true); // Ensure editing state remains true
    }
  };

  const handleSourceArrivalTimeChange = (date) => {
    setSourceArrivalTime(date);

    // Calculate and set the departure time to 5 minutes after arrival time
    if (date) {
      const arrivalDate = new Date(date);
      console.log("Arrival Date:", arrivalDate); // Log arrival date
      arrivalDate.setMinutes(arrivalDate.getMinutes() + 5);

      // Format departureTime in 'YYYY-MM-DDTHH:mm' format
      const year = arrivalDate.getFullYear();
      const month = String(arrivalDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based
      const day = String(arrivalDate.getDate()).padStart(2, "0");
      const hours = String(arrivalDate.getHours()).padStart(2, "0");
      const minutes = String(arrivalDate.getMinutes()).padStart(2, "0");

      setSourceDepartureTime(`${year}-${month}-${day}T${hours}:${minutes}`);

      // Log the updated times
      console.log("Updated Arrival Time:", date);
      console.log(
        "Departure Time:",
        `${year}-${month}-${day}T${hours}:${minutes}`
      );
    } else {
      setSourceDepartureTime(null);
    }
  };

  // const handleSourceArrivalTimeChange = (date) => {
  //   setSourceArrivalTime(date);
  
  //   // Only proceed if not in editing mode
  //   if (!isEditing) {
  //     if (date) {
  //       const arrivalDate = new Date(date);
  //       console.log("Arrival Date:", arrivalDate); // Log arrival date
  
  //       // Calculate and set departure time to 5 minutes after arrival time
  //       arrivalDate.setMinutes(arrivalDate.getMinutes() + 5);
  
  //       // Format departureTime in 'YYYY-MM-DDTHH:mm' format
  //       const year = arrivalDate.getFullYear();
  //       const month = String(arrivalDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  //       const day = String(arrivalDate.getDate()).padStart(2, "0");
  //       const hours = String(arrivalDate.getHours()).padStart(2, "0");
  //       const minutes = String(arrivalDate.getMinutes()).padStart(2, "0");
  
  //       setSourceDepartureTime(`${year}-${month}-${day}T${hours}:${minutes}`);
  
  //       // Log the updated times
  //       console.log("Updated Arrival Time:", date);
  //       console.log("Departure Time:", `${year}-${month}-${day}T${hours}:${minutes}`);
  //     } else {
  //       setSourceDepartureTime(null);
  //     }
  //   }
  // };
  
  const handleDestinationArrivalTimeChange = (date) => {
    setDestinationArrivalTime(date);

    // Calculate and set the departure time to 5 minutes after arrival time
    if (date) {
      const arrivalDate = new Date(date);
      console.log("Arrival Date:", arrivalDate); // Log arrival date
      arrivalDate.setMinutes(arrivalDate.getMinutes() + 5);

      // Format departureTime in 'YYYY-MM-DDTHH:mm' format
      const year = arrivalDate.getFullYear();
      const month = String(arrivalDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based
      const day = String(arrivalDate.getDate()).padStart(2, "0");
      const hours = String(arrivalDate.getHours()).padStart(2, "0");
      const minutes = String(arrivalDate.getMinutes()).padStart(2, "0");

      setDestinationDepartureTime(
        `${year}-${month}-${day}T${hours}:${minutes}`
      );

      // Log the updated times
      console.log("Updated Arrival Time:", date);
      console.log(
        "Departure Time:",
        `${year}-${month}-${day}T${hours}:${minutes}`
      );
    } else {
      setDestinationDepartureTime(null);
    }
  };

  const handleDateChange = (index, date) => {
    if (!Array.isArray(stops)) return;
    const newStops = [...stops];
    newStops[index].arrivalTime = date;

    if (date) {
      const arrivalDate = new Date(date);
      arrivalDate.setMinutes(arrivalDate.getMinutes() + 5);
      const year = arrivalDate.getFullYear();
      const month = String(arrivalDate.getMonth() + 1).padStart(2, "0");
      const day = String(arrivalDate.getDate()).padStart(2, "0");
      const hours = String(arrivalDate.getHours()).padStart(2, "0");
      const minutes = String(arrivalDate.getMinutes()).padStart(2, "0");
      newStops[
        index
      ].departureTime = `${year}-${month}-${day}T${hours}:${minutes}`;
    } else {
      newStops[index].departureTime = "";
    }

    setStops(newStops);
  };

  const handleAddStop = () => {
    
    // Ensure stops is always an array
    if (!Array.isArray(stops)) {
      setStops([{ address: "", arrivalTime: "", departureTime: "" }]);
      return;
    }

    // Get the last stop if stops array is not empty
    const lastStop = stops[stops.length - 1];

    // Validate the last stop fields before adding a new one
    if (
      stops.length === 0 ||
      (lastStop &&
        lastStop.address &&
        lastStop.arrivalTime &&
        lastStop.departureTime)
    ) {
      // Add a new empty stop
      setStops([...stops, { address: "", arrivalTime: "", departureTime: "" }]);
    } else {
      toast.error(
        "Please fill all fields (address, arrival time, and departure time) before adding a new stop."
      );
    }
  };

  const handleRemoveStop = (index) => {
    if (Array.isArray(stops) && stops.length > 1) {
      setStops(stops.filter((_, i) => i !== index));
    }
  };

  const getLatLonFromAddress = async (address) => {
    try {
      const response = await axios.get(OPENCAGE_URL, {
        params: {
          q: address,
          key: OPENCAGE_API_KEY,
        },
      });

      const results = response.data.results;
      if (results.length > 0) {
        const { lat, lng } = results[0].geometry;
        return { lat, lon: lng };
      } else {
        throw new Error("No results found");
      }
    } catch (error) {
      console.error("Error fetching latitude and longitude:", error.message);
      throw error;
    }
  };

  const isValidDate = (date) => {
    return date instanceof Date && !isNaN(date.getTime());
  };

  // const handleSubmit = async (event) => {
  //   event.preventDefault();

  //   // Handle default dates
  //   const defaultDate = new Date().toISOString();

  //   // Format date strings conditionally
  //   const formattedSourceArrivalTime = sourcearrivalTime
  //     ? new Date(sourcearrivalTime).toISOString()
  //     : defaultDate;
  //   const formattedSourceDepartureTime = sourcedepartureTime
  //     ? new Date(sourcedepartureTime).toISOString()
  //     : defaultDate;
  //   const formattedDestinationArrivalTime = destinationarrivalTime
  //     ? new Date(destinationarrivalTime).toISOString()
  //     : defaultDate;
  //   const formattedDestinationDepartureTime = destinationdepartureTime
  //     ? new Date(destinationdepartureTime).toISOString()
  //     : defaultDate;

  //   // Handle stops formatting conditionally
  //   const formattedStops =
  //     stops && stops.length > 0
  //       ? stops.map((stop) => {
  //           const arrivalTime = new Date(stop.arrivalTime);
  //           const departureTime = new Date(stop.departureTime);

  //           if (!isValidDate(arrivalTime) || !isValidDate(departureTime)) {
  //             console.warn(
  //               `Invalid date values in stops: arrivalTime=${stop.arrivalTime}, departureTime=${stop.departureTime}`
  //             );
  //             return {
  //               ...stop,
  //               arrivalTime: defaultDate,
  //               departureTime: defaultDate,
  //             }; // Default value
  //           }

  //           return {
  //             ...stop,
  //             arrivalTime: arrivalTime.toISOString(),
  //             departureTime: departureTime.toISOString(),
  //           };
  //         })
  //       : [];

  //   const tripData = {
  //     assignVehicle,
  //     drivername,
  //     sourceAddress,
  //     sourceArrivalTime: formattedSourceArrivalTime,
  //     sourceDepartureTime: formattedSourceDepartureTime,
  //     destinationAddress,
  //     destinationArrivalTime: formattedDestinationArrivalTime,
  //     destinationDepartureTime: formattedDestinationDepartureTime,
  //     tripDelayAlerts,
  //     stops: formattedStops,
  //   };

  //   try {
  //     const response = await fetch(`${config.apiUrl}/trip/PostTrips`, {
  //       method: "POST",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify(tripData),
  //     });

  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }

  //     const result = await response.json();
  //     console.log("Trip created successfully:", result);

  //     let newRoutePathData = [];

  //     if (source && !isNaN(source.lat) && !isNaN(source.lon)) {
  //       newRoutePathData.push([source.lat, source.lon]);
  //     }

  //     stops.forEach((stop, index) => {
  //       const lat = parseFloat(stop.lat);
  //       const lon = parseFloat(stop.lon);
  //       if (!isNaN(lat) && !isNaN(lon)) {
  //         newRoutePathData.push([lat, lon]);
  //       } else {
  //         console.warn(
  //           `Invalid coordinates for stop ${index + 1}: lat=${stop.lat}, lon=${
  //             stop.lon
  //           }`
  //         );
  //       }
  //     });

  //     if (destination && !isNaN(destination.lat) && !isNaN(destination.lon)) {
  //       newRoutePathData.push([destination.lat, destination.lon]);
  //     }

  //     console.log("Final Route Path:", newRoutePathData);

  //     // Set the route path
  //     setRoutePath(newRoutePathData);

  //     // Reset the form
  //     resetForm();

  //     toast.success("Trip created successfully");
  //   } catch (error) {
  //     console.error("Error creating trip:", error);
  //     toast.error("Error creating trip");
  //   }
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    console.log("Form Submission Started");
    console.log("Is Editing:", isEditing);
    console.log("Edit Schedule:", editSchedule);

    // Handle default dates
    const defaultDate = new Date().toISOString();
  
    // Format date strings conditionally
    const formattedSourceArrivalTime = sourcearrivalTime
      ? new Date(sourcearrivalTime).toISOString()
      : defaultDate;
    const formattedSourceDepartureTime = sourcedepartureTime
      ? new Date(sourcedepartureTime).toISOString()
      : defaultDate;
    const formattedDestinationArrivalTime = destinationarrivalTime
      ? new Date(destinationarrivalTime).toISOString()
      : defaultDate;
    const formattedDestinationDepartureTime = destinationdepartureTime
      ? new Date(destinationdepartureTime).toISOString()
      : defaultDate;

      console.log(formattedSourceArrivalTime,"sourcearrival");
      console.log(formattedSourceDepartureTime,"sourcedeparture");
      console.log(formattedDestinationArrivalTime,"destinationarrival");
      console.log(formattedDestinationDepartureTime,"destinationdeparture")
  
    // Ensure stops is an array before processing
    const filteredStops = Array.isArray(stops)
      ? stops
          .filter((stop) => stop.address.trim() !== "") // Filter out stops without an address
          .map((stop) => {
            const arrivalTime = new Date(stop.arrivalTime);
            const departureTime = new Date(stop.departureTime);
  
            if (!isValidDate(arrivalTime) || !isValidDate(departureTime)) {
              console.warn(
                `Invalid date values in stops: arrivalTime=${stop.arrivalTime}, departureTime=${stop.departureTime}`
              );
              return {
                ...stop,
                arrivalTime: defaultDate,
                departureTime: defaultDate,
              }; // Default value
            }
  
            return {
              ...stop,
              arrivalTime: arrivalTime.toISOString(),
              departureTime: departureTime.toISOString(),
            };
          })
      : []; 
  
      const tripData = {
        assignVehicle,
        drivername,
        sourceAddress,
        source_latitude: source.lat, // Extract lat from source object
        source_longitude: source.lon, // Extract lon from source object
        sourceArrivalTime: formattedSourceArrivalTime,
        sourceDepartureTime: formattedSourceDepartureTime,
        destinationAddress,
        destination_latitude: destination.lat, // Extract lat from destination object
        destination_longitude: destination.lon, // Extract lon from destination object
        destinationArrivalTime: formattedDestinationArrivalTime,
        destinationDepartureTime: formattedDestinationDepartureTime,
        tripDelayAlerts,
        stops: filteredStops,
      };
      console.log(tripData,"daaaaaaaaa")
  
    try {
      let response;
  
      // if (isEditing && editSchedule) {
      //   // Update existing trip
      //   response = await fetch(`${config.apiUrl}/trip/${editSchedule}`, {
      //     method: "PUT",
      //     headers: { "Content-Type": "application/json" },
      //     body: JSON.stringify(tripData),
      //   });
  
      //   if (response.ok) {
      //     toast.success("Trip updated successfully");
      //   }
  
      //   console.log(tripData, "trip updated");
  
      // } else {
      //   // Create new trip
      //   response = await fetch(`${config.apiUrl}/trip/PostTrips`, {
      //     method: "POST",
      //     headers: { "Content-Type": "application/json" },
      //     body: JSON.stringify(tripData),
      //   });
  
      //   if (response.ok) {
      //     toast.success("Trip created successfully");
      //   }
  
      //   console.log(tripData, "trip created");
      // }

      if (isEditing && editSchedule) {
        // Update existing trip

      console.log(`Updating trip with ID ${editSchedule}`);
      console.log("Updating trip with ID:", editSchedule);
        response = await fetch(`${config.apiUrl}/trip/${editSchedule}`, {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(tripData),
        });
    
  
        if (response.ok) {
          console.log(tripData,"edit ")
          toast.success("Trip updated successfully");
          console.log(tripData, "Trip updated");
        } else {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
  
      } else {
        // Create new trip
        response = await fetch(`${config.apiUrl}/trip/PostTrips`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(tripData),
        });
      
  
        if (response.ok) {
          console.log(tripData,"create ")
          toast.success("Trip created successfully");
          console.log(tripData, "Trip created");
        } else {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
      }
  
  
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
  
      const result = await response.json();
      console.log("Trip processed successfully:", result);
      setShowModal(false);
      await fetchTripDetails();
  
      let newRoutePathData = [];
  
      if (source && !isNaN(source.lat) && !isNaN(source.lon)) {
        newRoutePathData.push([source.lat, source.lon]);
      }
  
      if (Array.isArray(stops)) {
        stops.forEach((stop, index) => {
          const lat = parseFloat(stop.lat);
          const lon = parseFloat(stop.lon);
          if (!isNaN(lat) && !isNaN(lon)) {
            newRoutePathData.push([lat, lon]);
          } else {
            console.warn(`Invalid coordinates for stop ${index + 1}: lat=${stop.lat}, lon=${stop.lon}`);
          }
        });
      }
  
      if (destination && !isNaN(destination.lat) && !isNaN(destination.lon)) {
        newRoutePathData.push([destination.lat, destination.lon]);
      }
  
      console.log("Final Route Path:", newRoutePathData);
  
      // Set the route path
      setRoutePath(newRoutePathData);
  
      // Reset the form
      resetForm();
  
    } catch (error) {
      console.error("Error processing trip:", error);
      toast.error("Error processing trip");
    }
  };
  
  
 

  const handleDelete = async (tripId) => {
    // Show confirmation dialog
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this trip?"
    );
    if (!confirmDelete) return;

    try {
      // Send DELETE request to the server
      await axios.delete(`${config.apiUrl}/trip/DeleteTrip/${tripId}`);

      console.log("Before update:", tripDetails);
      setTripDetails((prevTripDetails) => {
        const updatedTripDetails = prevTripDetails.filter(
          (trip) => trip.id !== tripId
        );
        console.log("After update:", updatedTripDetails);
        return updatedTripDetails;
      });
      await fetchTripDetails();
      // Notify the user
      toast.success("Trip deleted successfully");
    } catch (error) {
      console.error("Error deleting trip:", error);
      alert("Failed to delete trip");
    }
  };

  const handleSearch = () => {
    const searchId = Number(searchTripId); // Convert searchTripId to number
    console.log("searchId:", searchId);

    if (isNaN(searchId) && searchTripId.trim() !== "") {
      toast.error("Please enter a valid Trip ID");
      return;
    }

    if (!Array.isArray(tripDetails)) {
      console.error("tripDetails is not an array:", tripDetails);
      toast.error("Trip data is not available");
      return;
    }

    console.log("tripDetails:", tripDetails);

    let filteredTrips;
    if (isNaN(searchId) || searchTripId.trim() === "") {
      // If searchId is not valid or empty, show all trips
      filteredTrips = tripDetails;
    } else {
      // Find trip with matching ID
      const foundTrip = tripDetails.find((trip) => {
        console.log("Trip object:", trip); // Debug: Log the trip object
        console.log(
          "Trip ID from data:",
          trip.trip?.id,
          "Type of Trip ID:",
          typeof trip.trip?.id
        );
        const tripId = Number(trip.trip?.id); // Access the id through the nested trip object
        return tripId === searchId;
      });

      console.log("Found trip:", foundTrip);
      filteredTrips = foundTrip ? [foundTrip] : [];
      if (!foundTrip) {
        toast.error("Trip not found");
      }
    }

    setFilteredTripDetails(filteredTrips); // Set filtered trips
  };

  // const setDateRange = (value) => {
  //   setRangeOption(value);
  // };

  // const formatDateForInput = (isoString) => {
  //   const date = new Date(isoString);
  //   const pad = (n) => (n < 10 ? "0" + n : n);
  //   console.log(date,"date")

  //   return (
  //     date.getFullYear() +
  //     "-" +
  //     pad(date.getMonth() + 1) +
  //     "-" +
  //     pad(date.getDate()) +
  //     "T" +
  //     pad(date.getHours()) +
  //     ":" +
  //     pad(date.getMinutes())
  //   );
  // };

  // Usage in handleEditClick
  const handleEditClick = (tripId) => {

    console.log(tripId,"tripId")
    
    setEditSchedule(tripId);

    const tripData = tripDetails.find((data) => data.trip.id === tripId);

    if (!tripData) {
      console.error('No trip data provided');
      return;
    }

    const { trip, checkpoints } = tripData;

    if (trip) {
   
      // Convert to compatible format
      // const formattedSourceArrivalTime = formatDateForInput(
      //   trip.source_arrival_time
      // );
      // const formattedSourceDepartureTime = formatDateForInput(
      //   trip.source_departure_time
      // );
      // const formattedDestinationArrivalTime = formatDateForInput(
      //   trip.destination_arrival_time
      // );
      // const formattedDestinationDepartureTime = formatDateForInput(
      //   trip.destination_departure_time
      // );

      // console.log(formattedSourceArrivalTime,"sourcearrival");
      // console.log(formattedSourceDepartureTime,"sourcedeparture");
      // console.log(formattedDestinationArrivalTime,"destinationarrival");
      // console.log(formattedDestinationDepartureTime,"destinationdeparture")

      // Populate form fields with trip data
      setAssignVehicle(trip.assign_vehicle);
      setDriverName(trip.driver_name);
      setSourceAddress(trip.source_address);
      setSourceArrivalTime(trip.source_arrival_time);  // Use original time
      setSourceDepartureTime(trip.source_departure_time);  // Use original time
      setDestinationAddress(trip.destination_address);
      setDestinationArrivalTime(trip.destination_arrival_time);

      // Handle checkpoints similarly if needed
       const stopsData = checkpoints && checkpoints.length > 0
    ? checkpoints.map((checkpoint) => ({
        id: checkpoint.id,
        address: checkpoint.address,
        arrivalTime: checkpoint.arrival_time,  // Use original time
        departureTime: checkpoint.departure_time,  // Use original time
        lat: checkpoint.lat,
        lon: checkpoint.lon
      }))
    : [];


  setStops(stopsData);

      setTripDelayAlerts(trip.trip_delay_alerts || "");
      setIsEditing(true);
      console.log('Trips Data:', tripData);
    } else {
      // Reset form fields if no trip data is provided
      setAssignVehicle(null);
      setDriverName("");
      setSourceAddress("");
      setSourceArrivalTime("");
      setSourceDepartureTime("");
      setDestinationAddress("");
      setDestinationArrivalTime("");
      setDestinationDepartureTime("");
      setTripDelayAlerts(false);
      setStops([]);
      setIsEditing(false);
    }

    setShowModal(true);

  };

  const resetForm = () => {
    setTripId("");
    setAssignVehicle("");
    setDriverName("");
    setSourceAddress("");
    setDestinationAddress("");
    setSourceArrivalTime(null);
    setSourceDepartureTime(null);
    setDestinationArrivalTime(null);
    setDestinationDepartureTime(null);

    setStops("");
    setSource(null);
    setDestination(null);
    setVehicleCheckboxChecked(false);
    setDriverCheckboxChecked(false);
    setTripDelayAlerts(false);
  };

  const handleClearSource = () => {
    setSourceAddress("");
    setSourceArrivalTime('');
    setSourceDepartureTime('');
    setSource({ lat: "", lon: "" });
    if (isEditing) {
      setIsEditing(true); // Ensure editing state remains true
    }
  };

  const closeModal = () => {
    setShowModal(false);

    setTripId("");
    setAssignVehicle("");
    setDriverName("");
    setSourceAddress("");
    setDestinationAddress("");
    setSourceArrivalTime(null);
    setSourceDepartureTime(null);
    setDestinationArrivalTime(null);
    setDestinationDepartureTime(null);
    setStops("");
    setSource(null);
    setDestination(null);
    setVehicleCheckboxChecked(false);
    setDriverCheckboxChecked(false);
    setTripDelayAlerts(false);
  };

  const handleSuggestionClick = (suggestion, index) => {
    if (!Array.isArray(stops)) return;
    const newStops = [...stops];
    newStops[index].address = suggestion.display_name;
    newStops[index].lat = suggestion.lat;
    newStops[index].lon = suggestion.lon;
    setStops(newStops);
    setCheckPointSuggestions([]);
  };

  const handleAddressChange = async (e, index) => {
    const value = e.target.value;

    if (!Array.isArray(stops)) return;

    const newStops = [...stops];
    newStops[index].address = value;
    setStops(newStops);

    if (value.trim().length > 2) {
      try {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
            value
          )}&addressdetails=1&limit=5`
        );
        const suggestions = await response.json();
        if (suggestions.length > 0) {
          const checkpointSuggestions = suggestions.map((suggestion) => ({
            display_name: suggestion.display_name,
            lat: suggestion.lat,
            lon: suggestion.lon,
          }));
          setCheckPointSuggestions(checkpointSuggestions);
          setNoSuggestions(false);
        } else {
          setNoSuggestions(true);
          setCheckPointSuggestions([]);
        }
      } catch (error) {
        console.error("Error fetching suggestions:", error);
        setNoSuggestions(true);
        setCheckPointSuggestions([]);
      }
    } else {
      setNoSuggestions(false);
      setCheckPointSuggestions([]);
    }
  };
  
  const handleClearStop = (index) => {
    const newStops = [...stops];
    newStops[index] = {
      address: "",
      arrivalTime: null,
      departureTime: "",
      isEditing: false,
    }; // Resetting fields of the selected checkpoint
    setStops(newStops);
  };
  const handleFromDateChange = (date) => {
    if (date) {
      setFromDate(date);
    }
  };

  const handleToDateChange = (date) => {
    if (date) {
      setToDate(date);
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center h-[10vh] border-b-2 border-gray-300">
        <div className="flex flex-col items-center">
          <h3>TRIP</h3>
          <h2 className="text-lg font-semibold text-gray-500 mb-2 text-center">
            Create Trip
          </h2>
        </div>
        <div className="pl-2">
          <ProfileModule />
        </div>
      </div>

      <div className="mt-4 flex justify-center space-x-4">
        {/* <div className="flex flex-wrap gap-2 mb-2">
        <div className="relative w-full">
        <select
          onChange={(e) => setRangeOption(e.target.value)}
          value={rangeOption}
          className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
        >
          {[
            "Today",
            "Yesterday",
            "Last 7 Days",
            "Last 30 Days",
            "Custom",
          ].map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          {/* <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M7 10l5 5 5-5H7z" />
          </svg> */}
        {/* </div>
      </div>
      </div>

      {rangeOption === "Custom" && (
        <div className="custom-date-pickers flex gap-4 mb-4">
          <div>
            <label className="block text-gray-700 mb-1">From:</label>
            <DatePicker
              selected={fromDate}
              onChange={handleFromDateChange}
              maxDate={new Date()}
              minDate={moment().subtract(6, "months").toDate()}
              className="w-full"
            />
          </div>
          <div>
            <label className="block text-gray-700 mb-1">To:</label>
            <DatePicker
              selected={toDate}
              onChange={handleToDateChange}
              maxDate={new Date()}
              minDate={moment().subtract(6, "months").toDate()}
              className="w-full"
            />
          </div>
        </div>
      )}  */}

        <button
         onClick={() => {
          setShowModal(true);
          setIsEditing(false);
      
       
        }}
        
          className="bg-slate-500 text-white rounded-md hover:bg-slate-600 h-10 w-28"
        >
          Create Trip
        </button>

        <div className="flex items-center">
          <FaSearch
            className="text-blue-500 cursor-pointer ml-7"
            onClick={() => setShowSearchInput(!showSearchInput)}
          />
          {showSearchInput && (
            <div className="flex">
              <input
                type="text"
                placeholder="Search by Trip ID"
                className="border-b border-gray-400 w-48 px-2 ml-1"
                value={searchTripId}
                onChange={(e) => setSearchTripId(e.target.value)}
              />
              <button
                onClick={handleSearch}
                className="bg-blue-500 text-white ml-1 px-2 rounded hover:bg-blue-600"
              >
                Search
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-lg relative mt-4 mb-6">
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border-collapse">
            <thead className="sticky top-0 bg-gray-100 text-left text-sm text-gray-600 border-b">
              <tr className="bg-gray-100 text-left text-sm text-gray-600 border-b">
                <th className="py-2 px-3 font-semibold">Trip ID</th>
                <th className="py-2 px-3 font-semibold">Assigned Vehicle</th>
                <th className="py-2 px-3 font-semibold">Driver Name</th>
                <th className="py-2 px-3 font-semibold">Current Location</th>
                <th className="py-2 px-3 font-semibold">Source Location</th>
                <th className="py-2 px-3 font-semibold">Target Location</th>
                <th className="py-2 px-3 font-semibold">Arrival Time</th>
                <th className="py-2 px-3 font-semibold">
                  Checkpoint Addresses
                </th>
                <th className="py-2 px-3 font-semibold">Actions</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="9" className="py-4 text-center text-gray-600">
                    Loading trips...
                  </td>
                </tr>
              ) : (
                <>
                  {Array.isArray(filteredTripDetails) &&
                  filteredTripDetails.length > 0 ? (
                    filteredTripDetails.map((detail, index) => (
                      <tr key={index} className="border-t border-gray-200">
                        <td className="py-3 px-6 text-left whitespace-nowrap">
                          {detail.trip.id}
                        </td>
                        <td className="py-3 px-6 text-left whitespace-nowrap">
                          {detail.trip.assign_vehicle}
                        </td>
                        <td className="py-3 px-6 text-left whitespace-nowrap">
                          {detail.trip.driver_name}
                        </td>
                        <td className="py-3 px-6 text-left whitespace-nowrap">
                          {detail.vehicleDetails
                            ? `${detail.vehicleDetails.lat}, ${detail.vehicleDetails.lon}`
                            : "No data"}
                        </td>
                        <td className="py-3 px-6 text-left text-sm whitespace-normal break-words">
                          {detail.trip.source_address}
                        </td>
                        <td className="py-3 px-6 text-left text-sm whitespace-normal break-words">
                          {detail.trip.destination_address}
                        </td>
                        <td className="py-3 px-6 text-left whitespace-nowrap">
                          {new Date(
                            detail.trip.source_arrival_time
                          ).toLocaleString()}
                        </td>
                        <td className="py-3 px-6 text-left whitespace-normal break-words">
                          {detail.checkpoints &&
                          detail.checkpoints.length > 0 ? (
                            <ul>
                              {detail.checkpoints.map((checkpoint, idx) => (
                                <li key={idx}>{checkpoint.address}</li>
                              ))}
                            </ul>
                          ) : (
                            "No checkpoints"
                          )}
                        </td>
                        <td className="py-3 px-6 text-left flex justify-center items-center space-x-2">
                          <FaEdit
                            className="text-blue-500 cursor-pointer"
                            onClick={() => handleEditClick(detail.trip.id)}
                          />
                          <FaTrash
                            className="text-red-500 cursor-pointer"
                            onClick={() => handleDelete(detail.trip.id)}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="9"
                        className="py-4 text-center text-gray-600"
                      >
                        No trips found
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Modal for creating/editing trip */}
      {showModal && (
        <div className="fixed inset-0 flex outline-none focus:outline-none">
          <div className="absolute inset-0 bg-gray-900 bg-opacity-50 backdrop-blur-sm"></div>
          <div className="w-2/5 mt-10 ml-16 overflow-y-auto">
            <div className="flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
              <div className="relative w-full my-6 mx-auto max-w-3xl">
                <div className="bg-white shadow-lg rounded-lg relative flex flex-col w-full outline-none focus:outline-none">
                  <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                    <h3 className="text-2xl font-semibold">
                      {isEditing ? "Edit Trip" : "Trip Details"}
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-red-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={closeModal}
                    >
                      <span className="text-red-500">×</span>
                    </button>
                  </div>
                  <div className="relative p-6 flex-auto">
                    <form onSubmit={handleSubmit}>
                      <div className="grid grid-cols-1 gap-4">
                        <div className="mb-2">
                          <label className="block text-gray-700 mb-1">
                            Trip ID
                          </label>
                          <input
                            type="text"
                            className="border border-gray-300 rounded px-3 py-2 w-full"
                            value={tripId}
                          />
                        </div>

                        <div>
                          <div>
                            <label>
                              <input
                                type="checkbox"
                                checked={vehicleCheckboxChecked}
                                onChange={handleVehicleCheckboxChange}
                              />
                              Show Assigned Vehicle
                            </label>
                          </div>
                          {/* <label className="block text-gray-700 mb-1">Assigned Vehicle</label> */}
                          <input
                            type="text"
                            className={`border border-gray-300 rounded px-3 py-2 w-full ${
                              !vehicleCheckboxChecked && "hidden"
                            }`}
                            value={assignVehicle}
                            onChange={(e) => setAssignVehicle(e.target.value)}
                            list="vehicleSuggestions"
                            required
                          />
                          <datalist id="vehicleSuggestions">
                            {suggestions.map((vehicle, index) => (
                              <option key={index} value={vehicle} />
                            ))}
                          </datalist>
                        </div>

                        <div className="mb-1">
                          <div>
                            <label>
                              <input
                                type="checkbox"
                                checked={driverCheckboxChecked}
                                onChange={handleDriverCheckboxChange}
                              />
                              Show Driver Name
                            </label>
                          </div>
                          {/* <label className="block text-gray-700 mb-1">Driver Name</label> */}
                          <input
                            type="text"
                            className={`border border-gray-300 rounded px-3 py-2 w-full ${
                              !driverCheckboxChecked && "hidden"
                            }`}
                            value={drivername}
                            onChange={(e) => setDriverName(e.target.value)}
                            list="vehicleDrivers"
                            required
                          />
                          <datalist id="vehicleDrivers">
                            {drivers.map((name, index) => (
                              <option key={index} value={name} /> // Use <option> instead of <li>
                            ))}
                          </datalist>
                        </div>
                        <div className="flex-grow ">
                          <label className="block text-gray-700 mb-1">
                            Source Address
                          </label>
                          <div className="relative">
                            <input
                              type="text"
                              className="mt-1 block w-full p-2 border border-gray-300 rounded-md relative"
                              placeholder="Enter source address"
                              value={sourceAddress}
                              onChange={handleSourceAddressChange}
                            />
                            {sourceAddress && (
                              <button
                                type="button"
                                onClick={handleClearSource}
                                className="absolute right-2 top-2 text-red-500 "
                              >
                                &#10005; {/* Cross tick symbol */}
                              </button>
                            )}
                            {loadingSource && (
                              <div className="p-2 text-gray-500">
                                Loading...
                              </div>
                            )}
                            {!loadingSource && noSourceSuggestions && (
                              <div className="p-2 text-gray-500">
                                No suggestions found
                              </div>
                            )}
                            {sourceSuggestions.map((suggestion) => (
                              <div
                                key={suggestion.place_id}
                                onClick={() =>
                                  handleSourceSelect(suggestion.display_name)
                                }
                                className="suggestion p-2 cursor-pointer hover:bg-gray-100"
                              >
                                {suggestion.display_name}
                              </div>
                            ))}
                          </div>
                          <div className="mb-4">
                            <label className="block text-gray-700 mb-1">
                              Latitude & Longitude
                            </label>
                            <input
                              type="text"
                              value={`${source?.lat || "N/A"}, ${
                                source?.lon || "N/A"
                              }`} // Conditional rendering
                              readOnly
                              className="border border-gray-300 rounded px-3 py-2 w-full"
                            />
                          </div>
                        </div>

                        <div className="mb-4 flex">
                          <div className="w-2/4">
                            <label className="block text-gray-700 mb-1">
                              Arrival Time
                            </label>
                            <DatePicker
                              selected={sourcearrivalTime}
                              onChange={handleSourceArrivalTimeChange}
                              showTimeSelect
                              dateFormat="Pp" // Displays date and time
                              timeIntervals={15} // Show 15-minute intervals
                              minDate={new Date()} // Disables past dates
                              className="border border-gray-300 rounded px-3 py-2 w-full"
                              required
                            />
                          </div>
                          <div className="w-2/4 ml-4">
                            <label className="block text-gray-700 mb-1">
                              Departure Time
                            </label>
                            <input
                              type="datetime-local"
                              name="departureTime"
                              value={sourcedepartureTime || ""}
                              readOnly
                              className="border border-gray-300 rounded px-3 py-2 w-full"
                              required
                            />
                          </div>
                        </div>

                        <div className="flex-grow">
                          <label className="block text-gray-700 mb-1">
                            Destination Address
                          </label>
                          <div className="relative">
                            <input
                              type="text"
                              value={destinationAddress}
                              onChange={handleDestinationAddressChange}
                              className="mt-2 block w-full p-2 border border-gray-300 rounded-md mb-4"
                              placeholder="Enter destination address"
                            />
                            {destinationAddress && isEditing && (
                              <button
                                type="button"
                                onClick={handleClearDestination}
                                className="absolute right-2 top-2 text-red-500"
                              >
                                &#10005;
                              </button>
                            )}
                            {loadingDestination && (
                              <div className="p-2 text-gray-500">
                                Loading...
                              </div>
                            )}
                            {!loadingDestination &&
                              noDestinationSuggestions && (
                                <div className="p-2 text-gray-500">
                                  No suggestions found
                                </div>
                              )}
                            {destinationSuggestions.map((suggestion) => (
                              <div
                                key={suggestion.place_id}
                                onClick={() =>
                                  handleDestinationSelect(
                                    suggestion.display_name
                                  )
                                }
                                className="suggestion p-2 cursor-pointer hover:bg-gray-100"
                              >
                                {suggestion.display_name}
                              </div>
                            ))}
                          </div>
                          <div className="mb-4">
                            <label className="block text-gray-700 mb-1">
                              Latitude & Longitude
                            </label>
                            <input
                              type="text"
                              value={`${destination?.lat || "N/A"}, ${
                                destination?.lon || "N/A"
                              }`} // Conditional rendering
                              readOnly
                              className="border border-gray-300 rounded px-3 py-2 w-full"
                            />
                          </div>
                        </div>
                        <div className="mb-4 flex">
                          <div className="w-2/4">
                            <label className="block text-gray-700 mb-1">
                              Arrival Time
                            </label>
                            <DatePicker
                              selected={destinationarrivalTime}
                              onChange={handleDestinationArrivalTimeChange}
                              showTimeSelect
                              dateFormat="Pp" 
                              // dateFormat="yyyy-MM-dd HH:mm" // Explicitly set to 24-hour format
                              timeIntervals={15} // Show 15-minute intervals
                              minDate={new Date()} // Disables past dates
                              className="border border-gray-300 rounded px-3 py-2 w-full"
                              required
                            />
                          </div>
                          <div className="w-2/4 ml-4">
                            <label className="block text-gray-700 mb-1">
                              Departure Time
                            </label>
                            <input
                              type="datetime-local"
                              name="departureTime"
                              value={destinationdepartureTime || ""}
                              readOnly
                              className="border border-gray-300 rounded px-3 py-2 w-full"
                              required
                            />
                          </div>
                        </div>
                        <div className="flex items-center space-x-4 mb-4">
                          <label className="text-lg font-semibold">
                            Checkpoints
                          </label>
                          <button
                            type="button"
                            onClick={handleAddStop}
                            className="ml-auto bg-blue-500 text-white px-4 py-2 rounded"
                          >
                            Add checkpoint
                          </button>
                        </div>
                        {Array.isArray(stops) &&
                          stops.map((stop, index) => (
                            <div
                              key={index}
                              className="border-t border-gray-300 pt-4 mt-4"
                            >
                              <div className="mb-4 flex items-center">
                                <div className="flex-grow">
                                  <label className="block text-gray-700 mb-1">
                                    Address
                                  </label>
                                  <input
                                    type="text"
                                    name="address"
                                    value={stop.address}
                                    onChange={(e) =>
                                      handleAddressChange(e, index)
                                    }
                                    className="border border-gray-300 rounded px-3 py-2 w-full"
                                  />
                                  {/* Render suggestions */}
                                  {checkpointsuggestions.length > 0 && (
                                    <div className="border border-gray-300 rounded bg-white mt-1">
                                      {checkpointsuggestions.map(
                                        (suggestion, i) => (
                                          <div
                                            key={i}
                                            onClick={() =>
                                              handleSuggestionClick(
                                                suggestion,
                                                index
                                              )
                                            }
                                            className="p-2 cursor-pointer hover:bg-gray-100"
                                          >
                                            {suggestion.display_name}
                                          </div>
                                        )
                                      )}
                                    </div>
                                  )}
                                  {noSuggestions && (
                                    <div className="text-gray-500 mt-1">
                                      No suggestions found
                                    </div>
                                  )}
                                </div>
                                {index > 0 && (
                                  <button
                                    type="button"
                                    onClick={() => handleRemoveStop(index)}
                                    className="bg-red-500 text-white px-3 py-1 rounded-full ml-2 mt-8"
                                  >
                                    <span className="text-lg">&times;</span>
                                  </button>
                                )}
                                 {stop && isEditing && ( // Show Clear button only if in edit mode
                <button
                  type="button"
                  onClick={() => handleClearStop(index)}
                  className="bg-gray-500 text-white px-3 py-1 rounded-full ml-2 mt-8"
                >
                  &times;
                </button>
              )}
                              </div>

                              <div className="mb-4 flex">
                                <div className="w-2/4">
                                  <label className="block text-gray-700 mb-1">
                                    Arrival Time
                                  </label>
                                  <DatePicker
                                        selected={stop.arrivalTime}
                                    onChange={(date) =>
                                      handleDateChange(index, date)
                                    }
                                    showTimeSelect
                                    dateFormat="Pp"
                                    timeIntervals={15}
                                    minDate={new Date()} // Disables past dates
                                    className="border border-gray-300 rounded px-3 py-2 w-full"
                                  />
                                </div>
                                <div className="w-2/4 ml-4">
                                  <label className="block text-gray-700 mb-1">
                                    Departure Time
                                  </label>
                                  <input
                                    type="datetime-local"
                                    name="departureTime"
                                    value={
                                      stop.departureTime
                                        ? stop.departureTime
                                        : ""
                                    }
                                 
                                    readOnly
                                    className="border border-gray-300 rounded px-3 py-2 w-full"
                                  />
                                </div>
                              </div>

                              <div className="mb-4">
                                <label className="block text-gray-700 mb-1">
                                  Latitude & Longitude
                                </label>
                                <input
                                  type="text"
                                  value={`${stop.lat || ""}, ${stop.lon || ""}`}
                                  readOnly
                                  className="border border-gray-300 rounded px-3 py-2 w-full"
                                />
                              </div>
                            </div>
                          ))}

                        <div className="flex items-center mt-6">
                          <label className="text-lg font-semibold">
                            Trip Alerts
                          </label>
                        </div>
                        <div className="flex items-center mt-2">
                          <input
                            type="checkbox"
                            id="tripDelayAlerts"
                            checked={tripDelayAlerts}
                            onChange={() =>
                              setTripDelayAlerts(!tripDelayAlerts)
                            }
                            className="mr-2"
                          />
                          <label
                            htmlFor="tripDelayAlerts"
                            className="text-gray-700"
                          >
                            Enable Trip Delay Alerts
                          </label>
                        </div>

                        <div></div>
                      </div>
                      <div className="flex justify-end mt-6">
                        <button
                          type="submit"
                          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                        >
                          {isEditing ? "Update Trip" : "Create Trip"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-3/5 mt-16">
            <div style={{ height: "500px", width: "100%" }}>
              <MapContainer
                center={[9.939093, 78.121719]}
                zoom={13}
                style={{ height: "100%", width: "100%" }}
              >
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                {/* {source && <Marker position={[source.lat, source.lon]}><Popup>Source</Popup></Marker>}
          {destination && <Marker position={[destination.lat, destination.lon]}><Popup>Destination</Popup></Marker>}
          {route.length > 0 && <Polyline positions={routePath} color="blue" />}
         */}
                {source && (
                  <Marker position={[source.lat, source.lon]}>
                    <Popup>Source</Popup>
                  </Marker>
                )}
                {destination && (
                  <Marker position={[destination.lat, destination.lon]}>
                    <Popup>Destination</Popup>
                  </Marker>
                )}
                {Array.isArray(stops) &&
                  stops.map((stop, index) => {
                    const lat = parseFloat(stop.lat);
                    const lon = parseFloat(stop.lon);
                    return !isNaN(lat) && !isNaN(lon) ? (
                      <Marker key={index} position={[lat, lon]}>
                        <Popup>Checkpoint {index + 1}</Popup>
                      </Marker>
                    ) : null;
                  })}
                {routePath.length > 1 && (
                  <Polyline
                    positions={routePath}
                    color="blue"
                    weight={3}
                    opacity={0.7}
                  />
                )}
              </MapContainer>
            </div>
          </div>
        </div>
      )}
  <TripAlert /> 
    </div>
  );
}

export default Trip;
