import React, { useState, useEffect } from 'react';
import GeocodeSearch from './GeocodeSearch';
import Geofence from './Geofence';
import config from '../../config'; // Adjust the path as needed

const GeofenceApp = ({ geofence, onClose, onSave }) => {
  const [center, setCenter] = useState(null);
  const [radius, setRadius] = useState('');
  const [zoom, setZoom] = useState(5);
  const [geofenceName, setGeofenceName] = useState('');
  const [streetName, setStreetName] = useState('');
  const [selectedLocation, setSelectedLocation] = useState({ lat: '', lon: '' });
  const [errors, setErrors] = useState({ name: false, radius: false, street: false });
  const [clearInput, setClearInput] = useState(false);

  useEffect(() => {
    if (geofence) {
      // Populate form fields with geofence data
      setGeofenceName(geofence.name || '');
      setCenter([geofence.latitude || '', geofence.longitude || '']);
      setRadius(geofence.radius || '');
      setStreetName(geofence.location || '');
      setSelectedLocation({ lat: geofence.latitude || '', lon: geofence.longitude || '' });
      setZoom(15); // Adjust zoom based on requirements
    }
  }, [geofence]);

  const handleGeofenceEnter = () => {
    console.log('Entered geofence');
  };

  const handleGeofenceExit = () => {
    console.log('Exited geofence');
  };

  const updateCenter = (location) => {
    if (location[0] !== null && location[1] !== null) {
      setCenter(location);
      if (radius === '') {
        setRadius(500);
        setErrors(prevState => ({
          ...prevState,
          radius: false
        }));
        setZoom(15);
      }
    } else {
      setCenter(null);
      setRadius('');
      setZoom(5);
    }
  };

  const validateInputs = () => {
    const newErrors = {
      name: !geofenceName.trim(),
      radius: !radius || isNaN(radius) || radius < 500,
      street: !streetName.trim(),
    };
    setErrors(newErrors);
    return !newErrors.name && !newErrors.radius && !newErrors.street;
  };

  const handleGeofenceNameChange = (event) => {
    setGeofenceName(event.target.value);
    setErrors(prevState => ({
      ...prevState,
      name: false
    }));
  };

  const handleRadiusChange = (event) => {
    setRadius(event.target.value);
    setErrors(prevState => ({
      ...prevState,
      radius: !event.target.value || isNaN(event.target.value) || event.target.value < 500,
    }));
  };

  const handleStreetNameChange = (value) => {
    setStreetName(value);
    setErrors(prevState => ({
      ...prevState,
      street: false
    }));
  };

  const updateGeofence = async () => {
    if (!validateInputs() || !center) {
      return;
    }
  
    const geofenceData = {
      name: geofenceName,
      latitude: center[0],
      longitude: center[1],
      radius: parseInt(radius, 10),
      enabled: true,
      location: streetName
    };
  
    try {
      let response;
  
      if (geofence && geofence.id) {
        // Update existing geofence
        response = await fetch(`${config.apiUrl}/geo/geofences/${geofence.id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(geofenceData),
        });
      } else {
        // Create new geofence
        response = await fetch(`${config.apiUrl}/geo/geofences`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(geofenceData),
        });
      }
  
      const result = await response.json();
      
      if (response.ok) {
        alert(`Geofence ${geofence ? 'updated' : 'created'} successfully!`);
        onSave(); // Call the onSave callback to handle additional actions
      } else {
        // Handle API errors
        console.error(`Error ${geofence ? 'updating' : 'creating'} geofence:`, result);
        alert(`Error ${geofence ? 'updating' : 'creating'} geofence.`);
      }
    } catch (error) {
      console.error(`Error ${geofence ? 'updating' : 'creating'} geofence:`, error);
      alert(`Error ${geofence ? 'updating' : 'creating'} geofence.`);
    }
  };
  

  return (
    <div className="flex flex-row h-full font-sans">
      <div className="w-1/3 p-5 bg-gray-100 border-r border-gray-300 shadow-md">
        <GeocodeSearch
          onLocationFound={updateCenter}
          onStreetNameChange={handleStreetNameChange}
          selectedLocation={selectedLocation} // Pass selectedLocation to GeocodeSearch
          setSelectedLocation={setSelectedLocation} 
          clearInput1={clearInput} // Pass clearInput1 to GeocodeSearch
          error={errors.street} 
          location={geofence}
        />
        <div className="mb-5">
          <label>
            <div className='block font-semibold'>Geofence Name:</div>
            <input
              type="text"
              value={geofenceName}
              onChange={handleGeofenceNameChange}
              className={`input w-full p-2 mt-1 mb-2 border rounded ${errors.name ? 'border-red-500' : 'border-gray-300'}`}
              placeholder="Enter geofence name"
            />
          </label>
          <label>
            <div className='block font-semibold'>Radius (meters):</div>
            <input
              type="number"
              value={radius}
              onChange={handleRadiusChange}
              className={`input w-full p-2 mt-1 mb-2 border rounded ${errors.radius ? 'border-red-500' : 'border-gray-300'}`}
              placeholder="Enter radius in meters"
            />
          </label>
          <button
            type="button"
            onClick={updateGeofence}
            className="button p-2 bg-blue-500 text-white rounded"
          >
           {geofence?'Update Geofence':'Create Geofence'}
          </button>
        </div>
      </div>
      <div className="w-2/3 p-5">
        {/* Render map and other components */}
        <Geofence
          center={center}
          radius={radius}
          zoom={zoom}
          onEnter={handleGeofenceEnter}
          onExit={handleGeofenceExit}
        />
      </div>
    </div>
  );
};

export default GeofenceApp;
