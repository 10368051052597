import React, { useState,useRef,useEffect } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import moment from 'moment-timezone';
import config from '../../config';
import ProfileModule from "../UserProfile";
import { FaDownload} from 'react-icons/fa';
import GenerateIdleReport from './GenerateIdleReport';
import StoppageReport from './StoppageReport';
import MovingReport from './MovingReport';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCalendarAlt } from 'react-icons/fa';
import Select from 'react-select';
import SummaryReport from './SummaryReport';
import DailyDistancereport from './DailyDistancereport';
import GeofenceReport from './GeofenceReport';
import ExcelDownload from './ExcelDownload';
import CSVDownload from './CSVDownload';
import PDFDownload from './DownloadPDF';




function Report() {

  const options = [
    { value: 'Today', label: 'Today' },
    { value: 'Yesterday', label: 'Yesterday' },
    { value: 'Last 7 Days', label: 'Last 7 Days' },
    { value: 'Last 30 Days', label: 'Last 30 Days' },
    { value: 'Custom', label: 'Custom' }
  ];
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [rangeOption, setRangeOption] = useState(options[0]); 
    const [isidleModalOpen, setIsIdleModalOpen] = useState(false);
    const [isstoppageModalOpen, setIsStoppageModalOpen] = useState(false);
    const [ismovingModalOpen, setIsMovingModalOpen] = useState(false);
     const [issummaryModalOpen, setIsSummaryModalOpen] = useState(false);
     const [isdistanceModalOpen, setIsDistanceModalOpen] = useState(false);
     const [isGeoModalOpen, setIsGeoModalOpen] = useState(false);
     const [lastGeneratedDate, setLastGeneratedDate] = useState('');
     const [reports, setReports] = useState([]);
     const [openDropdownId, setOpenDropdownId] = useState(null); 
     const [loading, setLoading] = useState(false);
      const[data,setData]=useState({});
     const [reportData, setReportData] = useState([]);
    const [reportName, setReportName] = useState('');
    const [totalSummary, setTotalSummary] = useState({});
    const [showPDFDownload, setShowPDFDownload] = useState(false);
    const [showExcelDownload, setShowExcelDownload] = useState(false);
    const [showCsvDownload, setShowCsvDownload] = useState(false);
    const [filteredReports, setFilteredReports] = useState(reports);
    const [lastGeneratedDates, setLastGeneratedDates] = useState({});

    const toggleStoppageDropdown = (id) => {
      // If the current openDropdownId is the same as the clicked id, close the dropdown by setting it to null.
      // Otherwise, open the dropdown by setting it to the clicked id.
      setOpenDropdownId(openDropdownId === id ? null : id);
      console.log(id, "iddddd");
  };
  
  const handleDownload = async (format, report) => {
    const { id, report_name } = report; // Ensure report contains id and report_name
    console.log('Fetching report for:', { id, report_name });

    try {
        const response = await axios.get(`${config.apiUrl}/report/getdownloaded`, {
            params: { id, report_name },
        });

        console.log('Backend response:', response.data);

        // Determine the data structure based on the report name
        let data = [];
        let totalSummary = {}; // Define totalSummary

        switch (report_name) {
          case 'Stoppage Report':
              data = response.data.stoppageData; // Access stoppage data
              break;
          case 'Idle Report':
              data = response.data.idleData;
           ; // Access idle data
              break;
          case 'Moving Report':
              data = response.data.movingData;
              totalSummary = response.data.totalSummary; // Access moving data
              break;
          case 'Movement Summary Report':
              data = response.data.formattedTotals;
              break;
          case 'Distance Report':
          data=response.data.totalDistancesByDate;
          totalSummary=response.data.totalDistanceAllDates
         
              break;
          case 'Geofence Report':
              data = response.data.processedResults;
              break; 
          default:
              console.error('Unknown report name');
      }
      
      // Additional logic based on the `data` and `totalSummary` can go here
      console.log(data,"data");
      console.log(totalSummary,"totalSummary");

        // Check if data is valid
        if (data&& data.length > 0) {
            setReportData(data); 
            console.log(data,"DATTTTTTTTA")
            setReportName(report_name);
            setTotalSummary(totalSummary);
            console.log(totalSummary,"totalSummary") // Set total summary

            // Trigger download based on the selected format
            switch (format) {
              case 'PDF':
                  setShowPDFDownload(true);
                  setShowExcelDownload(false);
                  setShowCsvDownload(false);
                  break;
              case 'XLSX':
                  setShowExcelDownload(true);
                  setShowPDFDownload(false);
                  setShowCsvDownload(false);
                  break;
              case 'CSV':
                  setShowCsvDownload(true);
                  setShowPDFDownload(false);
                  setShowExcelDownload(false);
                  break;
              default:
                  console.error('Unknown format');
          }
        } else {
            console.error('No data found for the report');
            toast.error('No data found for the report')
            
        }
    } catch (error) {
        console.error('Error fetching report:', error);
    }
};




    const fetchReports = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${config.apiUrl}/report/getreporttable`);
        console.log('response', response);
    
        const parsedReports = response.data.map(report => ({
          ...report,
          report_info: JSON.parse(report.report_info),
          payload: JSON.parse(report.payload),
        }));
    
        // Sort reports by created_at in descending order (latest first)
        parsedReports.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
       
        setFilteredReports(parsedReports);
        setReports(parsedReports);
        console.log(parsedReports,"parsedReports")

        // Create a mapping for last generated dates based on the report_name
        const newLastGeneratedDates = {};
        parsedReports.forEach(report => {
          const reportName = report.report_name; // Use report_name as the key
          if (reportName) {
              if (!newLastGeneratedDates[reportName] || new Date(report.created_at) > new Date(newLastGeneratedDates[reportName])) {
                  // Update if current report's created_at is later than the stored date
                  newLastGeneratedDates[reportName] = report.created_at;
              }
          } else {
              console.warn('Report name is undefined for report:', report); // Log a warning if report_name is not found
          }
      });


        // Update the state with new last generated dates
        setLastGeneratedDates(newLastGeneratedDates);
        console.log('New Last Generated Dates:', newLastGeneratedDates); 

      } catch (error) {
        console.error('Error fetching reports:', error);
      } finally {
        setLoading(false);
      }
    };


  
  
  useEffect(() => {
      fetchReports();
  }, []);
    

  const handleDateRangeChange = (option) => {
    setRangeOption(option);
    
    const today = moment();
    let startDate, endDate;

    if (option.value === 'Today') {
        startDate = today.clone().startOf('day').toDate();
        endDate = today.clone().endOf('day').toDate();
    } else if (option.value === 'Yesterday') {
        startDate = today.clone().subtract(1, 'day').startOf('day').toDate();
        endDate = today.clone().subtract(1, 'day').endOf('day').toDate();
    } else if (option.value === 'Last 7 Days') {
        startDate = today.clone().subtract(6, 'days').startOf('day').toDate();
        endDate = today.clone().endOf('day').toDate();
    } else if (option.value === 'Last 30 Days') {
        startDate = today.clone().subtract(29, 'days').startOf('day').toDate();
        endDate = today.clone().endOf('day').toDate();
    } else {
        // For custom, we don't set startDate and endDate here
        startDate = null;
        endDate = null;
    }

    // Set filtered reports based on selected option
    if (startDate && endDate) {
        const filtered = reports.filter(report => {
            const createdAt = new Date(report.created_at);
            return createdAt >= startDate && createdAt <= endDate;
        });
        setFilteredReports(filtered);
    } else if (option.value === 'Custom') {
        // Reset filtered reports if custom is selected
        setFilteredReports(reports);
    } else {
        setFilteredReports(reports); // Reset to all reports if no range is selected
    }
};

useEffect(() => {
  // Automatically filter reports for today when the component mounts
  handleDateRangeChange(options[0]); // Default to today
}, [reports]);

  // Effect to handle filtering based on fromDate and toDate
  useEffect(() => {
    if (rangeOption.value === 'Custom' && fromDate && toDate) {
        const filtered = reports.filter(report => {
            const createdAt = new Date(report.created_at);
            return createdAt >= fromDate && createdAt <= toDate;
        });
        setFilteredReports(filtered);
    }
}, [fromDate, toDate, rangeOption, reports]);

    const IdletoggleModal = () => {
        setIsIdleModalOpen(!isidleModalOpen);
        console.log(isidleModalOpen ? 'Modal closed.' : 'Modal opened.');
      };
    const StoppagetoggleModal = () => {
        setIsStoppageModalOpen(!isstoppageModalOpen);
        console.log(isstoppageModalOpen ? 'Modal closed.' : 'Modal opened.');
      };
      const MovingtoggleModal = () => {
        setIsMovingModalOpen(!ismovingModalOpen);
        console.log(ismovingModalOpen ? 'Modal closed.' : 'Modal opened.');
      };
      const SummarytoggleModal = () => {
        setIsSummaryModalOpen(!issummaryModalOpen);
        console.log(issummaryModalOpen ? ' summary Modal closed.' : ' summary Modal opened.');
      };
      const DistancetoggleModal = () => {
        setIsDistanceModalOpen(!isdistanceModalOpen);
        console.log(isdistanceModalOpen ? ' distance Modal closed.' : ' distance Modal opened.');
      };
      const GeotoggleModal = () => {
        setIsGeoModalOpen(!isGeoModalOpen);
      
      };
   
      const cardData = [
        {
            title: "Idling Report",
            description: "Idling Report tells you the idling durations and the idling locations for the vehicles selected by you in a date range set by you.",
            lastGeneratedDate: lastGeneratedDates['Idle Report'] ? moment(lastGeneratedDates['Idle Report']).tz('Asia/Kolkata').format('DD MMM YYYY') : 'N/A',
            onClick: IdletoggleModal // Replace with actual function
        },
        {
            title: "Stoppage Instances Report",
            description: "Stoppage Instances Report tells you the list of all individual stoppage instances and respected stoppage locations for the minimum threshold set by you.",
            lastGeneratedDate: lastGeneratedDates['Stoppage Report'] ? moment(lastGeneratedDates['Stoppage Report']).tz('Asia/Kolkata').format('DD MMM YYYY') : 'N/A',
            onClick: StoppagetoggleModal // Replace with actual function
        },
        {
            title: "Vehicle Movement Report",
            description: "Vehicle Movement Report tells you the list of all individual movements done by the vehicles selected.",
            lastGeneratedDate: lastGeneratedDates['Moving Report'] ? moment(lastGeneratedDates['Moving Report']).tz('Asia/Kolkata').format('DD MMM YYYY') : 'N/A',
           onClick: MovingtoggleModal // Replace with actual function
        },
        {
            title: "Vehicle Movement Summary Report",
            description: "Vehicle Movement Summary Report tells you the summary of all individual movements done by the vehicles selected.",
            lastGeneratedDate: lastGeneratedDates['Movement Summary Report'] ? moment(lastGeneratedDates['Movement Summary Report']).tz('Asia/Kolkata').format('DD MMM YYYY') : 'N/A',
            onClick: SummarytoggleModal // Replace with actual function
        },
        {
            title: "Daily Distance Travelled by Vehicle Report",
            description: "Distance Travelled by Vehicle Report tells you the total daily distance covered by the vehicles for the selected date range.",
            lastGeneratedDate: lastGeneratedDates['Distance Report'] ? moment(lastGeneratedDates['Distance Report']).tz('Asia/Kolkata').format('DD MMM YYYY') : 'N/A',
            onClick: DistancetoggleModal // Replace with actual function
        },
        {
          title: "Geofence Report",
          description: "The movement between geofences report provides you with the distance that the vehicle has covered in order to reach the destination geofence and other related parameters.",
          lastGeneratedDate: lastGeneratedDates['Geofence Report'] ? moment(lastGeneratedDates['Geofence Report']).tz('Asia/Kolkata').format('DD MMM YYYY') : 'N/A',
          onClick: GeotoggleModal // Replace with actual function
      }
    ];
    
   
    return (
        <div>
            <div className="flex justify-between items-center h-[10vh] border-b-2 border-gray-300">
  <div className="flex flex-col items-center">
    <h3 className='ml-3 font-bold'>Report</h3>
    <h2 className="text-lg font-semibold text-gray-500 mb-2 text-center ml-4">My Reports</h2>
  </div>
  <div className="pl-2">
    <ProfileModule />
  </div>
</div>
<div className='flex'>
<div className="bg-white w-3/4 border border-spacing-0 border-gray-200 h-[90vh] overflow-y-auto">
<div className="flex justify-between items-center ml-10 mr-10">
                <div className=" flex items-center mt-5">
                    <label className="text-2xl font-bold text-gray-700 ">Generate new report</label>
                </div>
                {/* <div className=" flex justify-end mt-5">
                    <div className="relative w-full">
                        <input
                            type="text"
                            className="border border-gray-300 rounded-md p-2 w-full pl-10"
                            placeholder="Search..."
                        />
                        <div className="absolute left-0 top-0 flex items-center h-full pl-3 pointer-events-none">
                            <i className="fas fa-search text-gray-500"></i>
                        </div>
                    </div>
                </div> */}
            </div>
            {/* <div className="mt-4 flex justify-start ml-10">
                <button className="bg-white-500 border-black text-black text-sm px-3 py-1 rounded-2xl shadow mr-3">
                    All: 44
                </button>
                <button className="bg-white-500 text-black border-black text-sm px-3 py-1 rounded-2xl shadow mr-3">
                    Vehicle:5
                </button>
                
            </div> */}
            {/* <div className="mt-4  grid grid-cols-3 gap-4 ml-10 mr-10">
                <div className="border border-gray-300 p-4 rounded-md shadow-md hover:bg-gray-100 relative">
                    <h3 className="text-lg font-semibold mb-4">Idling Report</h3>
                    <p className="text-sm text-gray-500 mb-9">Idling Report tells you the idling durations and the idling locations for the vehicles selected by you in a date range set by you.</p>
                    <p className="text-sm text-gray-500">
        Last Generated: {lastGeneratedDate ? moment(lastGeneratedDate, 'DD MMM YYYY').tz('Asia/Kolkata').format('DD MMM YYYY') : 'N/A'}
      </p>
                    <button onClick={IdletoggleModal} className="absolute font-bold bottom-0 left-0 right-0 mb-2 mx-auto bg-blue-500 text-white text-sm px-3 py-1 h-9 rounded-xl shadow opacity-0 hover:opacity-100  duration-300">
                        Generate Report
                    </button>
                    
                </div>
                <div className="border border-gray-300 p-4 rounded-md shadow-md hover:bg-gray-100 relative">
                    <h3 className="text-lg font-semibold mb-4">Stoppage Instances Report</h3>
                    <p className="text-sm text-gray-500 mb-5">Stoppage Instances Report tells you the list of all individual stoppage instances and respected stoppage locations for the minimum threshold set by you.</p>
                    <p className="text-sm text-gray-500">
        Last Generated: {lastGeneratedDate ? moment(lastGeneratedDate, 'DD MMM YYYY').tz('Asia/Kolkata').format('DD MMM YYYY') : 'N/A'}
      </p>
                    <button  onClick={StoppagetoggleModal}  className="absolute font-bold bottom-0 left-0 right-0 mb-2 mx-auto bg-blue-500 text-white text-sm px-3 py-1 h-9 rounded-xl shadow opacity-0 hover:opacity-100  duration-300">
                        Generate Report
                    </button>
                </div>
                <div className="border border-gray-300 p-4 rounded-md shadow-md hover:bg-gray-100 relative">
                    <h3 className="text-lg font-semibold">Vehicle Movement Report</h3>
                    <p className="text-sm text-gray-500 mb-14">Vehicle Movement Report tells you the list of all individual movements done by the vehicles selected.</p>
                    <p className="text-sm text-gray-500">
                      Last Generated: {lastGeneratedDate ? moment(lastGeneratedDate, 'DD MMM YYYY').tz('Asia/Kolkata').format('DD MMM YYYY') : 'N/A'}
                        </p>
                    <button  onClick={MovingtoggleModal} className="absolute font-bold bottom-0 left-0 right-0 mb-2 mx-auto bg-blue-500 text-white text-sm px-3 py-1 h-9 rounded-xl shadow opacity-0 hover:opacity-100  duration-300">
                        Generate Report
                    </button>
                </div>
                <div className="border border-gray-300 p-4 rounded-md shadow-md hover:bg-gray-100 relative">
                    <h3 className="text-lg font-semibold">Vehicle Movement Summary Report</h3>
                    <p className="text-sm text-gray-500 mb-14">Vehicle Movement Report tells you the list of all individual movements done by the vehicles selected.</p>
                    <p className="text-sm text-gray-500">
        Last Generated: {lastGeneratedDate ? moment(lastGeneratedDate, 'DD MMM YYYY').tz('Asia/Kolkata').format('DD MMM YYYY') : 'N/A'}
      </p>
                    <button  onClick={SummarytoggleModal} className="absolute font-bold bottom-0 left-0 right-0 mb-2 mx-auto bg-blue-500 text-white text-sm px-3 py-1 h-9 rounded-xl shadow opacity-0 hover:opacity-100  duration-300">
                        Generate Report
                    </button>
                </div>
                <div className="border border-gray-300 p-4 rounded-md shadow-md hover:bg-gray-100 relative">
                    <h3 className="text-lg font-semibold">Daily Distance Travelled by Vehicle Report</h3>
                    <p className="text-sm text-gray-500 mb-14">Distance Travelled by Vehicle Report tells you the total daily distance covered by the vehicles for the selected date range.</p>
                    <p className="text-sm text-gray-500">
        Last Generated: {lastGeneratedDate ? moment(lastGeneratedDate, 'DD MMM YYYY').tz('Asia/Kolkata').format('DD MMM YYYY') : 'N/A'}
      </p>
                    <button   onClick={DistancetoggleModal}className="absolute font-bold bottom-0 left-0 right-0 mb-2 mx-auto bg-blue-500 text-white text-sm px-3 py-1 h-9 rounded-xl shadow opacity-0 hover:opacity-100  duration-300">
                        Generate Report
                    </button>
                </div>
                </div> */}
                 <div className="mt-4 grid grid-cols-3 gap-4 ml-10 mr-10">
                  {cardData.map((card) => (
                    <div key={card.title} className="border border-gray-300 p-4 rounded-md shadow-md hover:bg-gray-100 relative">
                        <h3 className="text-lg font-semibold mb-4">{card.title}</h3>
                        <p className="text-sm text-gray-500 mb-5">{card.description}</p>
                        <p className="text-sm text-gray-500">
                        Last Generated: {card.lastGeneratedDate}
                    </p>
                        < button 
                            onClick={card.onClick} 
                            className="absolute font-bold bottom-0 left-0 right-0 mb-2 mx-auto bg-blue-500 text-white text-sm px-3 py-1 h-9 rounded-xl shadow opacity-0 hover:opacity-100 duration-300"
                        >
                            Generate Report
                        </button>
                    </div>
                ))}
                </div>

            </div>
            <div className="bg-white w-1/4 h-[90vh] overflow-y-auto">
            <h2 className="text-black text-lg mb-5 mt-5 ml-7">Recently Generated Reports</h2>
     
           
           
            <div className="relative w-full mb-5">
                <Select
                    value={rangeOption}
                    onChange={handleDateRangeChange}
                    options={options}
                    className="block w-full border border-gray-300 rounded-md shadow-sm"
                    placeholder="Select a date range"
                />
            </div>
            {rangeOption && rangeOption.value === 'Custom' && (
                <div className="custom-date-pickers flex gap-2 mb-6">
                    <div className="flex flex-col w-full">
                        <label className="text-gray-800 mb-2 font-medium">From:</label>
                        <div className="flex items-center border border-gray-300 rounded-lg shadow-sm p-1">
                            <DatePicker
                                selected={fromDate}
                                onChange={date => {
                                    setFromDate(date);
                                    // Trigger filtering when the fromDate is set
                                    if (rangeOption.value === 'Custom' && date && toDate) {
                                        const filtered = reports.filter(report => {
                                            const createdAt = new Date(report.created_at);
                                            return createdAt >= date && createdAt <= toDate;
                                        });
                                        setFilteredReports(filtered);
                                    }
                                }}
                                maxDate={new Date()}
                                minDate={moment().subtract(6, 'months').toDate()}
                                className="w-full border-none"
                            />
                        </div>
                    </div>
                    <div className="flex flex-col w-full">
                        <label className="text-gray-800 mb-2 font-medium">To:</label>
                        <div className="flex items-center border border-gray-300 rounded-lg shadow-sm p-1">
                            <DatePicker
                                selected={toDate}
                                onChange={date => {
                                    setToDate(date);
                                    // Trigger filtering when the toDate is set
                                    if (rangeOption.value === 'Custom' && date && fromDate) {
                                        const filtered = reports.filter(report => {
                                            const createdAt = new Date(report.created_at);
                                            return createdAt >= fromDate && createdAt <= date;
                                        });
                                        setFilteredReports(filtered);
                                    }
                                }}
                                maxDate={new Date()}
                                minDate={moment().subtract(6, 'months').toDate()}
                                className="w-full border-none"
                                popperClassName="max-w-full overflow-x-hidden whitespace-nowrap"
                                popperPlacement="bottom-end"  // Adjust popper placement
                                
                            />
                        </div>
                    </div>
                </div>
            )}

{filteredReports.length > 0 ? (
  filteredReports.map((report, index) => (
    <div key={index} className="card bg-gray-300 shadow-lg p-4 rounded-lg flex justify-between items-start mb-5 animation-shake">
      {/* Report Info */}
      <div>
        <h3 className="font-semibold text-lg mb-3">{report.report_info.title || 'Untitled Report'}</h3>
        <p className="text-gray-600 mb-4">
          Generated Date: {(moment.utc(report.created_at).tz('Asia/Kolkata').format('YYYY-MM-DD HH:mm:ss'))}
        </p>
        <p className="text-gray-600 text-sm">Date Range:</p>
        <p className="text-gray-600 text-sm">
          From: {moment(report.report_info.fromDate).format('DD MMM YYYY')}
          <span className="mx-2">|</span>
          To: {moment(report.report_info.toDate).format('DD MMM YYYY')}
        </p>
      </div>
      {/* Download Buttons */}
      <div className="relative">
        <button
          className="mt-2 text-gray-600 hover:underline"
          onClick={() => toggleStoppageDropdown(report.id)}
        >
          <FaDownload className="inline mr-1" />
        </button>
        {openDropdownId === report.id && (
          <div className="absolute right-0 bg-white shadow-lg rounded-md mt-1 z-10">
            <button 
              onClick={() => {
                handleDownload('PDF', report);
                toggleStoppageDropdown(report.id);
              }} 
              className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
            >
              PDF
            </button>
            <button 
              onClick={() => {
                handleDownload('XLSX', report);
                toggleStoppageDropdown(report.id);
              }} 
              className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
            >
              XLSX
            </button>
            <button 
              onClick={() => {
                handleDownload('CSV', report);
                toggleStoppageDropdown(report.id);
              }} 
              className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
            >
              CSV
            </button>
          </div>
        )}
      </div>
    </div>
  ))
) : (
  <p>No Reports available.</p>
)}
            
            {/* Downloaded Data Display */}
            {reportData.length > 0 && (
                <div>
                   {showPDFDownload && (
              <PDFDownload reportData={reportData} reportName={reportName}  totalSummary={totalSummary}/>)} 
                   {showExcelDownload && (
    <ExcelDownload reportData={reportData} reportName={reportName}  totalSummary={totalSummary}/>
)}
                    {showCsvDownload && (
    <CSVDownload reportData={reportData} reportName={reportName}  totalSummary={totalSummary}/>
)}
                </div>
            )}
        
    </div>
    
    </div>

        {isidleModalOpen && (
                <GenerateIdleReport isOpen={isidleModalOpen}
                onClose={IdletoggleModal}  fetchReport={fetchReports}  />
      )}
        {isstoppageModalOpen && (
                <StoppageReport isOpen={isstoppageModalOpen}
                onClose={StoppagetoggleModal} fetchReport={fetchReports}/>
      )}
        {ismovingModalOpen && (
                <MovingReport isOpen={ismovingModalOpen}
                onClose={MovingtoggleModal}fetchReport={fetchReports} />
      )}
          {issummaryModalOpen && (
                <SummaryReport isOpen={issummaryModalOpen}
                onClose={SummarytoggleModal} fetchReport={fetchReports} />
      )}
         {isdistanceModalOpen && (
                <DailyDistancereport isOpen={isdistanceModalOpen}
                onClose={DistancetoggleModal} fetchReport={fetchReports} />
      )}
       {isGeoModalOpen && (
                <GeofenceReport isOpen={isGeoModalOpen}
                onClose={GeotoggleModal} fetchReport={fetchReports} />
      )}
        </div>
        
    )
}

export default Report