import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,    Polyline,
} from "@react-google-maps/api";
import axios from "axios";
import { FaCopy, FaMapPin,FaArrowAltCircleLeft   } from "react-icons/fa";
import { RiMapPinLine } from "react-icons/ri";
import gT from "../assests/ioi.png";
import yT from "../assests/yt.png";
import rT from "../assests/jkh.png";
import BT from "../assests/hg.png";
import config from "../config";
import ProfileModule from "./UserProfile";
import "jspdf-autotable";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from 'react-router-dom';
import moment from "moment";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCalendarAlt } from 'react-icons/fa';

const mapStyles = {
  height: "80vh",
  width: "100%",
  border: "1px solid #000",
};


const defaultCenter = {
  lat: 9.939093,
  lng: 78.121719,
};
function VehicleDetails() {
  const [map, setMap] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [mapdata, setMapData] = useState([]);
  const location = useLocation();
  const { vehicle } = location.state;
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('Live');
  const [rangeOption, setRangeOption] = useState('Today');
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [movementData, setMovementData] = useState([]);
  const [historyactiveTab, historysetActiveTab] = useState('All');
  const [reportData, setReportData] = useState([]);
  const [vehiclePosition, setVehiclePosition] = useState(null);
  const [currentPlaybackIndex, setCurrentPlaybackIndex] = useState(0);
  const [playbackInterval, setPlaybackInterval] = useState(null);
  const [historyData, setHistoryData] = useState([]);
 
  const google = window.google;

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

 useEffect(() => {
  fetchData();
}, [rangeOption]);

useEffect(() => {
  if (activeTab === "Live") {
    fetchDirectionData();
  } else {
    fetchData();
      }
}, [activeTab, fromDate, toDate]);

useEffect(() => {
  // Clean up interval on component unmount
  return () => {
    if (playbackInterval) {
      clearInterval(playbackInterval);
    }
  };
}, [playbackInterval]);

useEffect(() => {
  if (
    currentPlaybackIndex !== null &&
    filteredreportData.length > 0 &&
    filteredreportData[currentPlaybackIndex]
  ) {
    const newPosition = {
      lat: parseFloat(filteredreportData[currentPlaybackIndex].lat),
      lng: parseFloat(filteredreportData[currentPlaybackIndex].lon),
    };
    setVehiclePosition(newPosition);
  }
}, [currentPlaybackIndex]);


  console.log("vehicle fetch data", vehicle);

  const setDateRange = (value) => {
    setRangeOption(value);
  };

  const handleTabChange = (tab) => {
    historysetActiveTab(tab);
  };


  // const handleClick = () => {
  //   navigate('/AllVehicles');
  // };

  if (!vehicle) {
    return <div>No vehicle data available</div>;
  }

  const dateTime = vehicle.parseTime_IST ? vehicle.parseTime_IST.replace(/T|\.000Z/g, ' ') : '';

  const handleCopyAddress = (address) => {
    console.log("copy");
    navigator.clipboard.writeText(address);
    // toast.success("Address copied successfully!");
  };
  const vehicleArray = [vehicle];

  const fetchDirectionData = async () => {
    try {
         setMapData(vehicleArray);
      console.log("testData", vehicleArray);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  function getVehicleColorClass(vehicle) {
    if (vehicle.speed > 0 && vehicle.ignition === 1  && vehicle.vehicle_identification_number != null) {
      return "text-green-500";
    } else if (
      vehicle.speed === 0 && vehicle.ignition === 0  && vehicle.vehicle_identification_number != null
    ) {
      return "text-red-500";
    } else if (
      vehicle.speed === 0 && vehicle.ignition === 1 && vehicle.vehicle_identification_number != null
    ) {
      return "text-amber-500";
    } else if (
      vehicle.speed === null && vehicle.ignition === null  && vehicle.vehicle_identification_number === null
    ) {
      return "text-gray-500";
    } else {
      return "text-red-800";
    }
  }

  const apiKey = "AIzaSyDciM17HrWOucxREypzzWE7KJ_wMqTVoZ0";

  function getVehicleColorClass1(vehicle) {
    if (
      vehicle.speed > 0 &&
      vehicle.ignition === 1 &&
 
      vehicle.vehicle_identification_number != null
    ) {
      return "Moving... ";
    } else if (
      vehicle.speed === 0 &&
      vehicle.ignition === 0 &&
     
      vehicle.vehicle_identification_number != null
    ) {
      return "Stopped - ";
    } else if (
      vehicle.speed === 0 &&
      vehicle.ignition === 1 &&
    
      vehicle.vehicle_identification_number != null
    ) {
      return "Idle - ";
    } else if (
      vehicle.speed === null &&
      vehicle.ignition === null &&
    
      vehicle.vehicle_identification_number === null
    ) {
      return "No Device";
    } else {
      return "Device Offline - ";
    }
  }

  const handleMouseEnter = (vehicle) => {
    if (vehicle && vehicle.lat && vehicle.lon) {
      setSelectedMarker(vehicle);
    } else {
      setSelectedMarker(null);
    }
  };


  const calculateIdleTime = (parseTimeIST) => {
    if (!parseTimeIST) {
      return "N/A";
    }

    const parsedTime = moment(parseTimeIST, "YYYY-MM-DD HH:mm:ss");
    const currentTime = moment();
    const duration = moment.duration(currentTime.diff(parsedTime));

    const days = Math.floor(duration.asDays());
    const hours = Math.floor(duration.asHours()) % 24;
    const minutes = Math.floor(duration.asMinutes()) % 60;

    if (days > 0) {
      return `${days} days ${hours} hours ${minutes} minutes`;
    } else if (hours > 0) {
      return `${hours} hours ${minutes} minutes`;
    } else {
      return `${minutes} minutes`;
    }
  };

console.log("mapdata", mapdata);


  // Function to fetch data based on selected date range
  const fetchData = async () => {
    try {
      let formattedFromDate, formattedToDate;
  
      
      switch (rangeOption) {
        case 'Today':
          formattedFromDate = moment().startOf('day').format('YYYY-MM-DD');
          formattedToDate = moment().endOf('day').format('YYYY-MM-DD');
          break;
        case 'Yesterday':
          formattedFromDate = moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD');
          formattedToDate = moment().subtract(1, 'day').endOf('day').format('YYYY-MM-DD');
          break;
        case 'Last 7 Days':
          formattedFromDate = moment().subtract(6, 'days').startOf('day').format('YYYY-MM-DD');
          formattedToDate = moment().endOf('day').format('YYYY-MM-DD');
          break;
        case 'Last 30 Days':
          formattedFromDate = moment().subtract(29, 'days').startOf('day').format('YYYY-MM-DD');
          formattedToDate = moment().endOf('day').format('YYYY-MM-DD');
          break;
        case 'Custom':
          formattedFromDate = moment(fromDate).format('YYYY-MM-DD');
          formattedToDate = moment(toDate).format('YYYY-MM-DD');
          break;
        default:
          formattedFromDate = moment().startOf('day').format('YYYY-MM-DD');
          formattedToDate = moment().endOf('day').format('YYYY-MM-DD');
          break;
      }
  
      // Fetch data from backend using axios
      const [response1, response2, response3] = await Promise.all([
        axios.get(`${config.apiUrl}/vehicle/playback`, {
          params: { fromDate: formattedFromDate, toDate: formattedToDate }
        }),
        axios.get(`${config.apiUrl}/vehicle/report`, {
          params: { fromDate: formattedFromDate, toDate: formattedToDate }
        }),
        axios.get(`${config.apiUrl}/vehicle/history-location`, {
          params: { fromDate: formattedFromDate, toDate: formattedToDate }
        })
      ]);
  
      // Update state with fetched data
      setMovementData(response1.data.totals); // Assuming 'totals' contains the array data
      setReportData(response2.data); 
      setHistoryData(response3.data); // Assuming 'data' contains the array data
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle error state
    }
  };
  

 

 
  // Filter movement data based on active tab
  const filteredreportData = reportData.filter(data => {
    switch (historyactiveTab) {
      case 'All':
        return true;
      case 'Movements':
        return data.status === 'Moving';
      case 'Stopped':
        return data.status === 'Stopped';
      default:
        return true;
    }
  });

  const handlePlayback = () => {
    if (playbackInterval) {
        // Stop Playback
        clearInterval(playbackInterval);
        setPlaybackInterval(null);
    } else {
        // Start Playback
        setCurrentPlaybackIndex(0); // Start from the beginning
        setPlaybackInterval(
            setInterval(() => {
                if (currentPlaybackIndex < filteredreportData.length - 1) {
                    setCurrentPlaybackIndex((prevIndex) => prevIndex + 1);
                    const currentReport = filteredreportData[currentPlaybackIndex];
                    if (currentReport && currentReport.arrow && currentReport.arrow.lat) {
                        // Example: Accessing current report's arrow.lat
                        console.log('Latitude:', currentReport.arrow.lat);
                        // Perform actions using currentReport.arrow.lat
                    } else {
                        console.error('Error: Arrow object or its properties are undefined');
                        // Handle the error scenario, e.g., skip this iteration or show an alert
                    }
                } else {
                    clearInterval(playbackInterval);
                    setPlaybackInterval(null);
                }
            }, 1000) // Adjust speed as needed (milliseconds)
        );
    }
};


  
  return (
    <div className="md:h-screen md:overflow-hidden">
      <div className="flex justify-between h-[10vh] border-b-2 border-gray-300">
        <div  className="flex ">
        <div className="cursor-pointer animate-bounce m-4" onClick={handleClick}>
      <FaArrowAltCircleLeft size={32} />
    </div>
        <div>
          <h3>Live Track</h3>
          <h2 className="text-lg font-semibold text-gray-500  mb-2 text-center">
            Vehicle Details
          </h2>
        </div>
        </div>
     
        <div className="relative pl-4">
          <div className="absolute left-0 top-2 bottom-4 w-0.5 bg-gray-500"></div>
          <ProfileModule className="pl-2" />
        </div>
      </div>


      <div className=" h-[90vh] ">
        <div className="flex justify-evenly">
          <div className="w-full md:w-5/12 max-h-[350px] md:max-h-[500px] overflow-y-auto "
           style={{
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
          }}>
    <div className="border-b border-gray-300 text-gray-400 flex">
        <div
          className={`px-4 py-2 flex-1 text-center border-r border-gray-300 cursor-pointer ${activeTab === 'Live' ? 'text-black bg-blue-200' : ''}`}
          onClick={() => handleTabClick('Live')}
        >
          Live
        </div>
        <div
          className={`px-4 py-2 flex-1 text-center cursor-pointer ${activeTab === 'History' ? 'text-black bg-blue-200' : ''}`}
          onClick={() => handleTabClick('History')}
        >
          History
        </div>
      </div>

      {activeTab === 'Live' && (
        <div>
          {mapdata.map((vehicle, index) => (
            <div
              key={vehicle.vehicle_identification_number}
              className="w-full p-4 bg-white"
              onMouseEnter={() => handleMouseEnter(vehicle)}
            >
              <div className="flex items-center">
                <label className="flex items-center space-x-4 px-4">
                  <span
                    className={`text-lg mt-2 font-semibold mb-2 text-start ${getVehicleColorClass(vehicle)}`}
                  >
                    {vehicle.registration_number}
                  </span>
                </label>
              </div>
              {vehicle.vehicle_identification_number != null ? (
                <div>
                  <div className={`flex justify-start mb-2 ${getVehicleColorClass(vehicle)}`}>
                    <FaMapPin className="w-4 h-4 mr-4 mt-1" />
                    <b>{getVehicleColorClass1(vehicle)} {calculateIdleTime(vehicle.parseTime_IST)}</b>
                  </div>
                  <div className="mb-2">
                    <div className="flex items-start mb-3">
                      <RiMapPinLine className="mr-3 h-5 w-4 mt-0 text-blue-700" />
                      <span className="text-sm break-words mr-4">
                        ( {vehicle.lat}, {vehicle.lon})
                      </span>
                      <FaCopy
                        className={`w-3 h-4 text-blue-500 cursor-pointer`}
                        onClick={() => handleCopyAddress(`(${vehicle.lat}, ${vehicle.lon})`)}
                      />
                    </div>
                  </div>
                  <div className="px-2">
                    <div className="flex items-center space-x-4">
                      <div className="flex flex-col items-center p-2 bg-white rounded-lg shadow shadow-blue-400">
                        <h1>{vehicle.ignition === 1 ? "ON" : "OFF"}</h1>
                        <span className="text-xs">IGNITION</span>
                      </div>
                      <div className="flex flex-col items-center p-2 bg-white rounded-lg shadow shadow-blue-400">
                        <h1>{vehicle.voltageLevel}</h1>
                        <span className="text-xs text-center">BATTERY VOLTAGELEVEL</span>
                      </div>
                      <div className="flex flex-col items-center p-2 bg-white rounded-lg shadow shadow-blue-400">
                        <h1>{vehicle.speed} {vehicle.speedUnit}</h1>
                        <span className="text-xs text-center">SPEED</span>
                      </div>
                    </div>
                    <div className="mt-2">Date & Time: {dateTime}</div>
                    {/* <div className="mt-1">
                      <b>GPS Positioned</b> :{" "}
                      {vehicle.gpsPositioned === 1 ? (
                        <span className="text-green-500">Positioned</span>
                      ) : (
                        <span className="text-red-500">Not Positioned</span>
                      )}
                    </div>
                    <div className="mt-1">
                      Distance Today (KM) : {vehicle.distanceToday}
                    </div>
                    <div className="mt-1">
                      Distance Yesterday (KM): {vehicle.distanceYesterday}
                    </div> */}
                    <div>
                      
                      </div>
                  </div>
                </div>
              ) : (
                <div>No Device</div>
              )}
            </div>
          ))}
        </div>
      )}

{activeTab === 'History' && (
  <div>
    {/* Date Range Selection */}
    <div className="date-options flex flex-wrap gap-2 mb-4">
      <div className="relative w-full">
        <select
          onChange={(e) => setDateRange(e.target.value)}
          value={rangeOption}
          className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
        >
          {['Today', 'Yesterday', 'Last 7 Days', 'Last 30 Days', 'Custom'].map(option => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M7 10l5 5 5-5H7z" />
          </svg>
        </div>
      </div>
    </div>

    {/* Custom Date Pickers */}
    {rangeOption === 'Custom' && (
      <div className="custom-date-pickers flex gap-4 mb-4">
        <div>
          <label className="block text-gray-700 mb-1">From:</label>
          <div className="flex items-center border border-gray-300 rounded p-2 w-full">
            <FaCalendarAlt className="text-gray-600 mr-2" />
            <DatePicker
              selected={fromDate}
              onChange={date => setFromDate(date)}
              maxDate={new Date()}
              minDate={moment().subtract(6, 'months').toDate()} // Set min date to previous 6 months
              className="w-full"
            />
          </div>
        </div>
        <div>
          <label className="block text-gray-700 mb-1">To:</label>
          <div className="flex items-center border border-gray-300 rounded p-2 w-full">
            <FaCalendarAlt className="text-gray-600 mr-2" />
            <DatePicker
              selected={toDate}
              onChange={date => setToDate(date)}
              maxDate={new Date()} // Set max date to current date
              minDate={moment().subtract(6, 'months').toDate()} // Set min date to previous 6 months
              className="w-full"
            />
          </div>
        </div>
      </div>
    )}
  {historyData.map((point, index) => {
        const formattedTime = moment(point.parseTime).format('YYYY-MM-DD HH:mm:ss');
        return (
          <div className="flex items-center justify-between mb-3" key={index}>
            <div className="flex items-start">
              <RiMapPinLine className="mr-3 h-5 w-4 text-blue-700" />
              <span className="text-sm break-words mr-4">
                ( {point.lat}, {point.lon} )
              </span>
            </div>
            <span className="text-sm text-gray-500 mr-8">
              {formattedTime}
            </span>
          </div>
        );
      })}
   

    {/* Movement Data Table */}
    <div className="movement-data-table">
      <table className="min-w-full divide-y divide-gray-200 border border-gray-300">
        <thead>
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-pre-wrap">TravelTime<br/>(HH:mm)</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-pre-wrap">Distance<br/>(kmph)</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-pre-wrap">StoppedTime<br/>(HH:mm)</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {movementData.map((data, index) => (
            <tr key={index}>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{data.movingDuration}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{data.totalDistance}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{data.stoppedDuration}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>

   
   
    <div className="tabs flex gap-24 mb-2 min-w-full ">
      {['All', 'Movements', 'Stopped'].map((tab) => (
        <button
          key={tab}
          onClick={() => handleTabChange(tab)}
          className={`px-4 py-2 rounded ${historyactiveTab === tab ?' text-black border-b-4 border-blue-700' : 'text-gray-700'}`}
        >
          {tab}
        </button>
      ))}
    </div>
    {/* <div className="latitude-longitude-info">
          
            <ul>
              {filteredreportData.map((data, index) => (
                <li key={index}>
                  Latitude: {data.lat}, Longitude: {data.lon}
                </li>
              ))}
            </ul>
          </div> */}
 {filteredreportData.map((point, index) => {
  const formattedStartTime = moment(point.startTime).format('YYYY-MM-DD HH:mm');
  const formattedEndTime = moment(point.endTime).format('YYYY-MM-DD HH:mm');

  return (
    <div className="flex items-start mb-3" key={index}>
      <div className="flex items-center">
        <RiMapPinLine className="mr-3 h-5 w-4 text-blue-700" />
        <span className="text-sm break-words">
          ( {point.lat}, {point.lon} )
        </span>
      </div>
      <div className="ml-6">
        <div className="text-sm text-gray-500">
          Start Time: {formattedStartTime}
        </div>
        <div className="text-sm text-gray-500">
          End Time: {formattedEndTime}
        </div>
        <div className="text-sm text-gray-500">
          Duration(HH:mm): {point.duration}
        </div>
      </div>
    </div>
  );
})}


  
  </div>
)}
 

         
          </div>
          <div className="w-full md:w-9/12 shadow-md">
            <LoadScript googleMapsApiKey={apiKey} onLoad={fetchDirectionData}  libraries={["places"]} >
              
            {mapdata.length > 0 && (
              <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={8}
                center={defaultCenter}
                onLoad={(map) => setMap(map)}
              >
                {map &&
                  mapdata.map((vehicle, index) => {
                    const latitude = parseFloat(vehicle.lat);
                    const longitude = parseFloat(vehicle.lon);
                    if (!isNaN(latitude) && !isNaN(longitude)) {
                      let iconUrl;

                      if (
                        vehicle.ignition === 1 &&
                        vehicle.speed > 0 &&
                       
                        vehicle.vehicle_identification_number != null
                      ) {
                        iconUrl = gT;
                      } else if (
                        vehicle.ignition === 0 &&
                        vehicle.speed === 0 &&
                     
                        vehicle.vehicle_identification_number != null
                      ) {
                        iconUrl = rT;
                      } else if (
                        vehicle.ignition === 1 &&
                        vehicle.speed === 0 &&
                    
                        vehicle.vehicle_identification_number != null
                      ) {
                        iconUrl = yT;
                      } else if (
                        vehicle.ignition === 0 &&
                        vehicle.speed === 0 &&
                       
                        vehicle.vehicle_identification_number != null
                      ) {
                        iconUrl = BT;
                      }

                      return (
                        <Marker
                          key={index}
                          position={{ lat: latitude, lng: longitude }}
                          onClick={() => {
                            setSelectedMarker(vehicle);
                            getVehicleColorClass(vehicle);
                          }}
                          icon={{
                            url: iconUrl,
                           // Example of safe access
                         scaledSize: new (window?.google?.maps?.Size || window.google.maps.Size)(50, 70),

                          }}
                        />
                      );
                    }
                    return null;
                  })}
                {selectedMarker && (
                  <InfoWindow
                    position={{
                      lat: parseFloat(selectedMarker.lat),
                      lng: parseFloat(selectedMarker.lon),
                    }}
                    onCloseClick={() => setSelectedMarker(null)}
                    options={{ pixelOffset: new window.google.maps.Size(0, -50) }}
                  >
                    <div
                      className="w-full h-[16vh] border border-gray-300 rounded-md p-2 bg-white"
                      style={{
                        boxShadow:
                          "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                      }}
                    >
                      <h2
                        style={{ fontSize: "10px", fontWeight: "bold" }}
                        className={`${getVehicleColorClass(selectedMarker)}`}
                      >
                        <b>Vehicle Number:</b>{" "}
                        {selectedMarker.registration_number|| "N/A"}
                      </h2>

                      <div className="flex justify-start">
                        <p className="mr-2">
                          ({selectedMarker.lat}, {selectedMarker.lon})
                        </p>
                        <FaCopy
                          className="text-blue-500 cursor-pointer"
                          onClick={() =>
                            handleCopyAddress(
                              `(${selectedMarker.lat}, ${selectedMarker.lon})`
                            )
                          }
                        />
                      </div>

                      <p>
                        <b>Speed:</b> {selectedMarker.speed || "0"} km/h
                      </p>

                      <p>
                        Ignition:
                        <span
                          style={{
                            color:
                              selectedMarker.ignition === 1 ? "green" : "red",
                          }}
                        >
                           {selectedMarker.ignition !== undefined ? selectedMarker.ignition : "N/A"}
                        </span>
                      </p>
                      <p>
                        <b>Driver: </b>
                        {selectedMarker.driver_name || "N/A"}
                      </p>
                      <p>Route: {selectedMarker.route || "N/A"}</p>
                    </div>
                  </InfoWindow>
                   )}
                    {activeTab === "History"&& reportData && reportData.length > 0 && (
                      <>
                      <Polyline
                        path={reportData.map(point => ({
                          lat: parseFloat(point?.lat || 0), // Use optional chaining and provide fallback value
                          lng: parseFloat(point?.lon || 0),
                        }))}
                        options={{
                          strokeColor: "#FF0000",
                          strokeOpacity: 1.0,
                          strokeWeight: 2,
                          icons: [
                            {
                              icon: {
                                path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                              },
                              offset: "0",
                              repeat: "100px",
                            },
                          ],
                        }}
                        
                      />
                      <Marker
                      position={{
                        lat: parseFloat(reportData[0]?.lat || 0), // Ensure to provide a fallback value if first point is undefined
                        lng: parseFloat(reportData[0]?.lon || 0),
                      }}
                      icon={{
                        url: gT,
                        scaledSize: new window.google.maps.Size(50, 70),
                      }}
                    />
                    <Marker
                      position={{
                        lat: parseFloat(reportData[reportData.length - 1]?.lat||0),
                        lng: parseFloat(reportData[reportData.length - 1]?.lon||0),
                      }}
                      icon={{
                        url: rT,
                     // Example of safe access
                   scaledSize: new (window?.google?.maps?.Size || window.google.maps.Size)(50, 70),

                      }}
                    />
                      <Marker
                  position={vehiclePosition}
                  icon={{
                    path: window.google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
                    scale: 5,
                    fillColor: "blue",
                    fillOpacity: 0.8,
                    strokeColor: "blue",
                    strokeWeight: 2,
                    rotation: reportData[currentPlaybackIndex]?.heading || 0,
                  }}
                />
                   
                    </>
                    )}
              
              </GoogleMap>
              )}
            </LoadScript>
            {reportData.length > 0 && (
        <button
          onClick={handlePlayback}
          className="bg-blue-500 text-white p-2 rounded-md shadow-md mt-4"
        >
          {playbackInterval ? "Stop Playback" : "Start Playback"}
        </button>
      )}
          </div>
        </div>
      </div>
    </div>
  );
}




export default VehicleDetails;


