import React, { useState, useEffect } from "react";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  FaBars,
  FaTimes,
  FaHome,
  FaTruck,
  FaUser,
  FaRoute,FaFileAlt ,FaMapPin 
} from "react-icons/fa";
import logo1 from "../assests/y.jpeg";
import logo2 from "../assests/kjk.png";

function Sidebar() {
  const [sidebarExpanded, setSidebarExpanded] = useState(false);
  const [role, setRole] = useState(null);
  const [showMaster, setShowMaster] = useState(false);
  const [showDriver, setShowDriver] = useState(false);
  const [showTrips, setShowTrips] = useState(false);
  const [showReports, setShowReports] = useState(false);
  const [showGeonfence, setShowGeonfence] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);
  const [activeMaster, setActiveMaster] = useState(false);
  const [activeDriver, setActiveDriver] = useState(false);
  const [activeTrips, setActiveTrip] = useState(false);
  const [activeReport, setActiveReport] = useState(false);
  const [activegeo, setActivegeo] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1020);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const user = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setRole(parsedUser.user_role);
    } else {
      // Handle the case where user is not found in localStorage
      setRole(null); // or some default value or redirect to login
    }
  }, []);
  

  const navigate = useNavigate();

  useEffect(() => {
    const auth = JSON.parse(sessionStorage.getItem("authentication"));

    if (!auth) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    const masterRoutes = ["/AllVehicles", "/VehicleInventory", "/VehicleGroups"];
    const driverRoutes = ["/Drivers", ""];
    const tripsRoutes = ["/AllTrips", "/TripsSchedules" ];
    const reportRoutes = ["/DayWiseHistroryReport","/AllReports"];
    const geofenceRoutes = ["/geofence"];

    setActiveMaster(masterRoutes.includes(location.pathname));
    setActiveDriver(driverRoutes.includes(location.pathname));
    setActiveTrip(tripsRoutes.includes(location.pathname));
    setActiveReport(reportRoutes.includes(location.pathname));
    setActivegeo(geofenceRoutes.includes(location.pathname))
 
  }, [location.pathname]);

  const toggleSidebar = () => {
    setSidebarExpanded(!sidebarExpanded);
  };

  return (
    <>
      <div
        className="h-screen overflow-hidden"
        style={{ fontFamily: "serif", backgroundColor: "rgb(239,240,249)" }}
      >
        <div className="flex h-200">
          {isMobile ? (
            <div className="flex-1">
              <main className="w-full bg-white rounded-lg">
                <FaBars
                  className="absolute top-0 right-0 cursor-pointer block md:hidden"
                  onClick={toggleSidebar}
                />
                <Outlet />
              </main>
            </div>
          ) : (
            <>
              <div
                className={`absolute transition-all duration-300 flex flex-col z-50   border-r-2 overflow-y-auto`}
                onMouseEnter={() => {
                  setSidebarExpanded(true);
                  // setShowMaster(true);
                  // setShowDriver(true);
                  // setShowTrips(true)
                  // setShowReports(true);
                }}
                onMouseLeave={() => {
                  setSidebarExpanded(false);
                  setShowMaster(false);
                  setShowDriver(false);
                  setShowTrips(false);
                  setShowReports(false);
                  setShowGeonfence(false)
                }}
                style={{
                  width: sidebarExpanded ? "17rem" : "4rem",
                }}
              >
                <div className="flex flex-col h-[100vh] rounded bg-white">
                  <div className="flex justify-start p-2">
                    <img className="h-12 W-14 " style={{ borderRadius: "" }} src={logo1} alt="Logo" />
                    {sidebarExpanded && (
                      <img className="h-12 W-10 " src={logo2} alt="Logo" />
                    )}
                  </div>
                  <ul className="flex flex-col ml-2 rounded">
                    <li>
                      <NavLink
                        to="/dashboard"
                        className={({ isActive }) =>
                          isActive ? "flex items-center text-blue-500 " : "flex items-center text-gray-500"
                        }
                      >
                        <FaHome className={`size-11  p-2 rounded cursor-pointer`} />
                        {sidebarExpanded && "Dashboard"}
                      </NavLink>
                    </li>
                    <li className={`${activeMaster ? 'text-blue-500' : 'text-gray-500'}`}>
                      <div
                        className={`flex items-center mb-2 `}
                        onClick={() => setShowMaster(!showMaster)}
                      >
                        <FaTruck className={`size-11  p-2 rounded cursor-pointer `} />
                        {sidebarExpanded && "Vehicle"}
                      </div>
                      {showMaster && (
                        <div className="">
                          <ul className="ml-12">
                            <li>
                              <NavLink
                                to="/AllVehicles"
                                className={({ isActive }) => {
                                  return isActive ? "text-blue-500" : "text-gray-500";
                                }}
                              >
                                All Vehicles
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                to="/VehicleInventory"
                                className={({ isActive }) => {
                                  return isActive ? "text-blue-500" : "text-gray-500";
                                }}
                              >
                                Vehicle Inventory
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                to="/VehicleGroups"
                                className={({ isActive }) => {
                                  return isActive ? "text-blue-500" : "text-gray-500";
                                }}
                              >
                                Vehicle Groups
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      )}
                    </li>
                    <li className={`${activeDriver ? 'text-blue-500' : 'text-gray-500'}`}>
                      <div
                        className={`flex items-center mb-2 `}
                        onClick={() => setShowDriver(!showDriver)}
                      >
                        <FaUser className={`size-11  p-2 rounded  `} />
                        {sidebarExpanded && "Driver"}
                      </div>
                      {showDriver && (
                        <div className="text-start">
                          <ul className="ml-12">
                            <li>
                              <NavLink
                                to="/Drivers"
                                className={({ isActive }) => {
                                  return isActive ? "text-blue-500" : "text-gray-500";
                                }}
                              >
                                My Drivers
                              </NavLink>
                            </li>
                            {/* <li>
                              <NavLink
                                to="/Perfomance"
                                className={({ isActive }) => {
                                  return isActive ? "text-blue-500" : "text-gray-500";
                                }}
                              >
                                Performance
                              </NavLink>
                            </li> */}
                          </ul>
                        </div>
                      )}
                    </li>

                    <li className={`${activeReport ? 'text-blue-500' : 'text-gray-500'}`}>
                      <div
                        className={`flex items-center mb-2 `}
                        onClick={() => setShowReports(!showReports)}
                      >
                        <FaFileAlt  className={`size-11  p-2 rounded cursor-pointer `} />
                        {sidebarExpanded && "Reports"}
                      </div>
                      {showReports && (
                        <div className="text-start">
                          <ul className="ml-12">
                           
                  
                            <li>
                              <NavLink
                                to="/DaywiseHistoryReport"
                                className={({ isActive }) => {
                                  return isActive ? "text-blue-500" : "text-gray-500";
                                }}
                              >
                               DayWiseHistoryReport
                              </NavLink>
                            </li> 
                           
                         
                            <li>
                              <NavLink
                                to="/Report"
                                className={({ isActive }) => {
                                  return isActive ? "text-blue-500" : "text-gray-500";
                                }}
                              >
                             AllReports
                              </NavLink>
                            </li> 
                           
                          </ul>
                        </div>
                      )}
                    </li>
                    <li className={`${activeTrips ? 'text-blue-500' : 'text-gray-500'}`}>
                      <div
                        className={`flex items-center mb-2 `}
                        onClick={() => setShowTrips(!showTrips)}
                      >
                        <FaRoute className={`size-11  p-2 rounded  cursor-pointer `} />
                        {sidebarExpanded && "Trip"}
                      </div>
                      {showTrips && (
                        <div >
                          <ul className="ml-12">
                            <li>
                              <NavLink
                                to="/AllTrips"
                                className={({ isActive }) => {
                                  return isActive ? "text-blue-500" : "text-gray-500";
                                }}
                              >
                              All Trips
                              </NavLink>
                            </li>
                      
                            <li>
                              <NavLink
                                to="/TripsSchedules"
                                className={({ isActive }) => {
                                  return isActive ? "text-blue-500" : "text-gray-500";
                                }}
                              >
                                Trips Schedules
                              </NavLink>
                            </li>
                            {/* <li>
                              <NavLink
                                to="/Routes"
                                className={({ isActive }) => {
                                  return isActive ? "text-blue-500" : "text-gray-500";
                                }}
                              >
                              Routes
                              </NavLink>
                            </li> */}
                            </ul>
                        </div>
                      )}
                    </li>
                    <li className={`${activegeo ? 'text-blue-500' : 'text-gray-500'}`}>
                    <NavLink
                        to="/geofence"
                        className={({ isActive }) =>
                          isActive ? "flex items-center text-blue-500 " : "flex items-center text-gray-500"
                        }
                      >
                        <FaMapPin className={`size-11  p-2 rounded  cursor-pointer `} />
                        {sidebarExpanded && "Geofence"}
                      </NavLink>
                            </li>
                    {/* <li className={`${activeTrips ? 'text-blue-500' : 'text-gray-500'}`}>
                      <div
                        className={`flex items-center mb-2 `}
                        onClick={() => setShowTrips(!showTrips)}
                      >
                        <FaRoute className={`size-11  p-2 rounded  `} />
                        {sidebarExpanded && "Trip"}
                      </div>
                      {showTrips && (
                        <div >
                          <ul className="ml-12">
                            <li>
                              <NavLink
                                to="/AllTrips"
                                className={({ isActive }) => {
                                  return isActive ? "text-blue-500" : "text-gray-500";
                                }}
                              >
                              All Trips
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                to="/ TripsSchedules"
                                className={({ isActive }) => {
                                  return isActive ? "text-blue-500" : "text-gray-500";
                                }}
                              >
                                Trips Schedules
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                to="/Routes"
                                className={({ isActive }) => {
                                  return isActive ? "text-blue-500" : "text-gray-500";
                                }}
                              >
                             Routes
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                to="/consigners"
                                className={({ isActive }) => {
                                  return isActive ? "text-blue-500" : "text-gray-500";
                                }}
                              >
                             Consigners
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      )}
                    </li> */}
                  </ul>
                </div>
              </div>
              <main className="w-full ml-16">
                <Outlet />
              </main>
            </>
          )}
        </div>
        {isMobile && sidebarExpanded && (
          <div className="fixed inset-0 bg-gray-900 bg-opacity-50 z-50">
            <div className="fixed inset-y-0 left-0 max-w-xs w-full bg-white shadow-lg z-50">
              <div className="py-4 px-3 flex justify-end">
                <FaTimes className="text-3xl text-gray-800 cursor-pointer" onClick={toggleSidebar} />
              </div>
              <div className="py-4 px-3">
                <ul className="flex flex-col">
                  {/* Add NavLinks for mobile here if needed */}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Sidebar;
