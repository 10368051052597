import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow, OverlayView 
} from "@react-google-maps/api";
import axios from "axios";
import { FaCopy, FaMapPin, FaMapMarker, FaCar, FaTruck, FaMotorcycle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { CiSearch } from "react-icons/ci";
import { FaRoute } from "react-icons/fa6";
import { RiMapPinLine } from "react-icons/ri";
import { BsPersonStanding } from "react-icons/bs";
import { MdSpeed } from "react-icons/md";
import gT from "../assests/ioi.png";
import yT from "../assests/yt.png";
import rT from "../assests/jkh.png";
import BT from "../assests/hg.png";
import config from "../config";
import ProfileModule from "./UserProfile";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import html2canvas from "html2canvas";
import { toast } from "react-toastify"; 
import "react-toastify/dist/ReactToastify.css";
import VehicleDataTable from "./ListView";


function MapHome() {
  const [directionData, setDirectionData] = useState([]);
  const [map, setMap] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [sortByOpen, setSortByOpen] = useState(false);
  const [downloadOpen, setDownloadOpen] = useState(false);
  const [showMapPinnedVehicles, setShowMapPinnedVehicles] = useState(true);
  const [filtereddata, setFiltereddata] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [sortcolor, setSortColor] = useState();
  const[viewMode,setViewMode]=useState("Map")
  const [selectedCheckbox, setSelectedCheckbox] = useState("all");
  const [allcount, setAllcount] = useState();
  const [movingcount, setMovingcount] = useState();
  const [stopagecount, setStopagecount] = useState();
  const [idlecount, setIdlecount] = useState();
  const [offlinecount, setOfflinecount] = useState();
  const [noDevicecount, setNoDevicecount] = useState();
  const [sortBy, setSortBy] = useState("");
  const [lastSelectedCheckbox, setLastSelectedCheckbox] = useState("all");
  const [selectedVehicleData, setSelectedVehicleData] = useState([]);
  const [showVehicleDataTable, setShowVehicleDataTable] = useState(false);
  const [mapdata, setMapData] = useState([]);
   const navigate = useNavigate();
   const [selectedVehicles, setSelectedVehicles] = useState([]);
   const [checkedVehicles, setCheckedVehicles] = useState([]);
  
  // const handleNavigate = (vehicle) => {
  //   // Navigate to the new route with vehicle data
  //   navigate('/VehicleDetails', { state: { vehicle } });
  //   // window.location.reload()
  // };
  //vehiclename click
  const handleVehicleClick = (vehicle) => {
    console.log("Clicked Vehicle:", vehicle);

    const selectedVehicleData = directionData.filter(
      (item) => item.registration_number === vehicle
    );
    console.log("Filtered Data:", selectedVehicleData);

    setMapData(selectedVehicleData);
  };
 

  // search functionalitty
  const [searchTerm, setSearchTerm] = useState("");
  const handleSearchChange = (e) => {
    const searchTerm = e.target.value.trim(); // Get the search term without leading or trailing spaces
    setSearchTerm(searchTerm);

    if (searchTerm === "") {
      setSelectedVehicleData([]);
      fetchDirectionData(); // Restore full data when search term is empty
    } else {
      const normalizedSearchTerm = searchTerm.toLowerCase().replace(/\s/g, ""); // Normalize the search term for comparison
      const filteredSearchData = directionData.filter((vehicle) =>
        vehicle.registration_number
          .toLowerCase()
          .replace(/\s/g, "")
          .includes(normalizedSearchTerm)
      );
      if (filteredSearchData.length === 0) {
        // Handle the case where no matching data is found
        setDirectionData([]);
        setSelectedVehicleData([]); // Set selected data to empty array or perform any other appropriate action
        setMapData([]); // Set map data to empty array or perform any other appropriate action
      } else {
        setSelectedVehicleData(filteredSearchData);
        setMapData(filteredSearchData);
      }
    }
  };

  //checkboxchange
 
  const handleCheckboxChange = (index) => {
    const updatedChecked = checkedVehicles.map((value, i) =>
      i === index ? !value : value
    );
    setCheckedVehicles(updatedChecked);

    // Update mapdata based on checkbox changes
    const updatedMapData = directionData.filter((_, i) => updatedChecked[i]);
    if (selectedVehicleData.length > 0) {
      console.log("selectedvehicle", selectedVehicleData);
      const updatedselectedMapData = selectedVehicleData.filter(
        (_, i) => updatedChecked[i]
      );
      setMapData(updatedselectedMapData);
    } else {
      setMapData(updatedMapData);
    }
  };

  const handleButtonClick = () => {
    setShowVehicleDataTable(!showVehicleDataTable);
    setViewMode(showVehicleDataTable ? "Map" : "List");
  };
  

  //copy function
  const successToastContainerStyle = {
    background: "rgb(75, 212, 71)",
    color: "white",
    borderRadius: "8px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
    padding: "12px",
    fontSize: "14px",
  };

  const showSuccessToast = (message) => {
    toast.success(`${message}`, {
      position: "top-right",
      autoClose: 2000,
      toastClassName: "success-toast",
      bodyClassName: "success-toast-body",
      style: successToastContainerStyle,
    });
  };

 
  const handleCopyAddress = (address) => {
    console.log("copy");
    navigator.clipboard.writeText(address);
    showSuccessToast("Address copied successfully!");
  };

  // const fetchSuggestions = async () => {
  //   try {
  //     const response = await axios.get(`${config.apiUrl}/location/suggestions`);
  //     setSuggestions(response.data.map((item) => item.registration_number));
  //     console.log(response.data, "data");
  //   } catch (error) {
  //     console.error("Error fetching suggestions:", error);
  //   }
  // };

  const fetchDirectionData = async () => {
    try {
      const response = await axios.get(
        `${config.apiUrl}/vehicle/vehicleBasedData`
      );
      setFiltereddata(response.data);
      console.log("testData", response.data);
      setAllcount(response.data.length);
      setMovingcount(
        response.data.filter(
          (item) =>
            item.ignition === 1 && item.speed > 0 && item.vehicle_identification_number !== null
        ).length
      );
      setStopagecount(
        response.data.filter(
          (item) =>
            item.ignition === 0 &&        
            item.speed === 0 &&                       
            item.vehicle_identification_number !== null  
        ).length
      );
      setIdlecount(
        response.data.filter(
          (item) =>
             item.ignition === 1 &&
            item.speed === 0 
           && item.vehicle_identification_number !== null
        ).length
      );
      setOfflinecount(
        response.data.filter(
          (item) =>
            item.ignition === 0 && item.speed === 0  && item.vehicle_identification_number === null
        ).length
      );
        setNoDevicecount(
        response.data.filter(
          (item) =>
            item.ignition === null && item.speed === null  && item.vehicle_identification_number === null
        ).length
      );
      // if (selectedCheckbox === 'all') {
      //   setDirectionData(response.data)

      // }
      console.log(lastSelectedCheckbox);

      switch (lastSelectedCheckbox) {
        case "all":
          setDirectionData(response.data);
          setMapData(response.data);
          break;
        case "moving":
          const movingData = response.data.filter(
            (item) => item.ignition === 1 && item.speed > 0 && item.vehicle_identification_number != null
          );
          setDirectionData(movingData);
          setMapData(movingData);
          console.log("directiondata", movingData);

          break;
          case "stopage":
            const stopageData = response.data.filter(
              (item) => item.ignition === 0 && item.speed === 0 && item.vehicle_identification_number != null
            );
            setDirectionData(stopageData);
            setMapData(stopageData);
            console.log("directiondata", stopageData);
  
            break;
        case "idle":
          setDirectionData(
            response.data.filter(
              (item) =>
                 item.ignition === 1 && item.speed === 0 && item.vehicle_identification_number !== null
            )
          );
          setMapData(
            response.data.filter(
              (item) =>
                item.ignition === 1 && item.speed === 0 && item.vehicle_identification_number !== null
            )
          );
          console.log("directiondata", directionData);
          break;
        case "offline":
          setDirectionData(
            response.data.filter(
              (item) => item.ignition === 0 && item.speed === 0 && item.vehicle_identification_number === null
            )
          );
          setMapData(
            response.data.filter(
              (item) => item.ignition === 0 && item.speed === 0  && item.vehicle_identification_number === null
            )
          );

          console.log("directiondata", directionData);
          break;
          case "nodevice":
          const nodeviceData = response.data.filter(
            (item) => item.ignition === null && item.speed === null  && item.vehicle_identification_number === null
          );
          setDirectionData(nodeviceData);
          setMapData(nodeviceData);
          console.log("directiondata", nodeviceData);

          break;
          default:
          setDirectionData(response.data);
          setMapData(response.data);
          break;
      }
      if (response.data.length > 0) {
        setCheckedVehicles(response.data.map(() => true));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchDirectionData();

    // Set up automatic refresh every 60 seconds
    const interval = setInterval(() => {
      fetchDirectionData();

      console.log("hello");
    }, 30000);

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, [selectedCheckbox]);

  function getVehicleColorClass(vehicle) {
    if (vehicle.speed > 0 && vehicle.ignition === 1  && vehicle.vehicle_identification_number !== null) {
      return "text-green-500";
    } else if (
      vehicle.speed === 0 && vehicle.ignition === 0 && vehicle.vehicle_identification_number !== null
    ) {
      return "text-red-500";
    } else if (
      vehicle.speed === 0 && vehicle.ignition === 1 && vehicle.vehicle_identification_number !== null
    ) {
      return "text-amber-500";
    }
    else if (
      vehicle.speed === null && vehicle.ignition === null && vehicle.vehicle_identification_number === null
    ) {
      return "text-gray-500";
    }
    else {
      return "text-blue-800";
    }
  }

  const vehicleColor = (vehicle) => {
    console.log("selec", vehicle);
    if (vehicle.speed > 0 && vehicle.ignition === 1 && vehicle.vehicle_identification_number != null) {
      return setSortColor("text-green-500");
    } 
    else if (
      vehicle.speed === 0 && vehicle.ignition === 0  && vehicle.vehicle_identification_number != null
    ) {
      return setSortColor("text-red-500");
    } 
    else if (
      vehicle.speed === 0 && vehicle.ignition === 1 && vehicle.vehicle_identification_number != null
    ) {
      return setSortColor("text-amber-500");
    }
    else if (
      vehicle.speed === null && vehicle.ignition === null && vehicle.vehicle_identification_number === null
    ) {
      return setSortColor("text-gray-800");
    }
    else {
      return setSortColor("text-red-800");
    }
  };
  const handleSuggestionChange = (selectedSuggestion) => {
    // Filter the direction data based on the selected vehicle
    const selectedVehicleData = directionData.filter(
      (vehicle) => vehicle.registration_number === selectedSuggestion
    );

    // Update the state to reflect the filtered data
    setSelectedVehicleData(selectedVehicleData);

    // Log the updated selectedVehicleData
    console.log("Selected Vehicle Data:", selectedVehicleData);
  };
  const filteredDirectionData = mapdata;

  const checboxFilter = (type) => {
  setSelectedMarker(null);

    setSelectedCheckbox(type !== selectedCheckbox ? type : selectedCheckbox);
    setLastSelectedCheckbox(type);
    setSelectedVehicleData([]);
    setSearchTerm("");
    let filteredregistration_numbers = [];

    switch (type) {
      case "moving":
        const movingDirectionData = filtereddata.filter(
          (item) => item.ignition === 1 && item.speed > 0 && item.vehicle_identification_number != null
        );
        setDirectionData(movingDirectionData);
        setMapData(movingDirectionData);
        filteredregistration_numbers = movingDirectionData.map(
          (vehicle) => vehicle.registration_number
        );
        break;
        case "stopage":
          const stopageDirectionData = filtereddata.filter(
            (item) => item.ignition === 0 && item.speed === 0 && item.vehicle_identification_number != null
          );
          setDirectionData(stopageDirectionData);
          setMapData(stopageDirectionData);
          filteredregistration_numbers = stopageDirectionData.map(
            (vehicle) => vehicle.registration_number
          );
          break;
      case "idle":
        const idleDirectionData = filtereddata.filter(
          (item) =>
            (item.ignition === 1) && item.speed === 0 && item.vehicle_identification_number != null
        );
        setDirectionData(idleDirectionData);
        setMapData(idleDirectionData);
        filteredregistration_numbers = idleDirectionData.map(
          (vehicle) => vehicle.registration_number
        );
        break;
      case "offline":
        const offlineDirectionData = filtereddata.filter(
          (item) => item.ignition === 0 && item.speed === 0  && item.vehicle_identification_number === null
        );
        setDirectionData(offlineDirectionData);
        setMapData(offlineDirectionData);
        filteredregistration_numbers = offlineDirectionData.map(
          (vehicle) => vehicle.registration_number
        );
        break;
        case "nodevice":
          const nodeviceData = filtereddata.filter(
            (item) => item.ignition === null && item.speed === null  && item.vehicle_identification_number === null
          );
          setDirectionData(nodeviceData);
          setMapData(nodeviceData);
          filteredregistration_numbers = nodeviceData.map(
            (vehicle) => vehicle.registration_number
          );
          break;
      default:
        setDirectionData(filtereddata);
        setMapData(filtereddata);
        filteredregistration_numbers = filtereddata.map(
          (vehicle) => vehicle.registration_number
        );
        // fetchSuggestions(); // Call fetchSuggestions when 'all' checkbox is selected
        break;
    }

    // Here you can use filteredregistration_numbers according to your requirements
    setSuggestions(filteredregistration_numbers);

    // Optionally, you can set a state with filteredregistration_numbers if needed
  };

  const mapStyles = {
    height: "500px",
    border: "1px solid #000",
  };

  const defaultCenter = {
    lat: 9.939093,
    lng: 78.121719,
  };

  const apiKey = "AIzaSyDciM17HrWOucxREypzzWE7KJ_wMqTVoZ0";

  const toggleSortByDropdown = () => {
    setSortByOpen(!sortByOpen);
  };
 
  // const handleFilter1CheckboxChange = () => {
  //   setShowMapPinnedVehicles(!showMapPinnedVehicles);
  // };
  // function formatVehicleNumber(registration_number) {
  //   return registration_number.replace(
  //     /^([A-Z]{2})(\d{2})([A-Z]{1,2})(\d{1,4})$/,
  //     "$1 $2 $3 $4"
  //   );
  // }
  const handleSortBy = (option) => {
    setSortBy(option);
    setSortByOpen(false);

    if (option === "A-Z") {
      setDirectionData([
        ...directionData.sort((a, b) =>
          a.registration_number.localeCompare(b.registration_number)
        ),
      ]);
      setMapData([
        ...directionData.sort((a, b) =>
          a.registration_number.localeCompare(b.registration_number)
        ),
      ]);
    } else if (option === "Z-A") {
      setDirectionData([
        ...directionData.sort((a, b) =>
          b.registration_number.localeCompare(a.registration_number)
        ),
      ]);
      setMapData([
        ...directionData.sort((a, b) =>
          b.registration_number.localeCompare(a.registration_number)
        ),
      ]);
    }
  };

  const handleDropdownChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);

    if (value === "pdf") {
      exportToPDF();
    } else if (value === "excel") {
      exportToExcel();
    }
  };

  const formatDirectionData = () => {
    return directionData.map((vehicle) => ({
      Vehicle: vehicle.registration_number,
      Location: vehicle.address,
      Time: vehicle.Time,
      Speed: `${vehicle.speed} kmph`,
      "Last Seen": vehicle.lastSeen,
      Ignition: vehicle.ignition,
      Battery: "high", // Assuming this is a constant value for all vehicles
    }));
  };
  const exportToPDF = () => {
    const doc = new jsPDF({
      orientation: "landscape",
    });

    doc.text("Vehicle Data", 20, 10); // Title

    // Prepare table data for autoTable
    const tableColumn = [
      "Vehicle Number",
      "Vehicle Movements",
      "Date & Time",
      "Current Location",
      "Ignition",
      "Speed",
      "Driver Name",
      "Trip Source",
      "Trip Destination",
      "Vehicle Group Name",
      "Vehicle Battery Voltage",
    ];

    const tableRows = directionData.map((vehicle) => [
      vehicle.registration_number || "N/A",
      getVehicleColorClass1(vehicle) + " " + (vehicle.time || "N/A"),
      vehicle.fixTime_IST || "N/A",
      vehicle.lat ? `lat-${vehicle.lat}, lon-${vehicle.lon}` : "N/A",
      vehicle.ignition !== null ? vehicle.ignition : "N/A",
      vehicle.speed != null ? `${vehicle.speed} ${vehicle.speedUnit}` : "N/A",
      vehicle.driver_name || "N/A",
      vehicle.sourceAddress || "N/A",
      vehicle.destinationAddress || "N/A",
      vehicle.vehicleGroupName || "N/A",
      vehicle.voltageLevel || "N/A",
    ]);

    // Add the table to the PDF
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 20,
    });

    doc.save("VehicleData.pdf");
  };

  const exportToExcel = () => {
    // Create a new worksheet with the vehicle data
    const worksheet = XLSX.utils.json_to_sheet(
      directionData.map((vehicle) => ({
        "Vehicle Number": vehicle.registration_number || "N/A",
        "Vehicle Movements": getVehicleColorClass1(vehicle) + " " + (vehicle.time || "N/A"),
        "Date & Time": vehicle.fixTime_IST || "N/A",
        "Current Location": vehicle.lat ? `lat-${vehicle.lat}, lon-${vehicle.lon}` : "N/A",
        Ignition: vehicle.ignition !== null ? vehicle.ignition : "N/A",
        Speed: vehicle.speed != null ? `${vehicle.speed} ${vehicle.speedUnit}` : "N/A",
        "Driver Name": vehicle.driver_name || "N/A",
        "Trip Source": vehicle.sourceAddress || "N/A",
        "Trip Destination": vehicle.destinationAddress || "N/A",
        "Vehicle Group Name": vehicle.vehicleGroupName || "N/A",
        "Vehicle Battery Voltage": vehicle.voltageLevel || "N/A",
      }))
    );

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Vehicle Data");

    // Export to Excel
    XLSX.writeFile(workbook, "VehicleData.xlsx");
  };

  function getVehicleColorClass1(vehicle) {
    if (
      vehicle.speed > 0 &&
      vehicle.ignition === 1 &&
      // vehicle.gpsPositioned === 1 &&
      vehicle.vehicle_identification_number != null
    ) {
      return "Moving... ";
    } else if (
      vehicle.speed === 0 &&
      vehicle.ignition === 0 &&
      // vehicle.gpsPositioned === 1 &&
      vehicle.vehicle_identification_number != null
    ) {
      return "Stopped - ";
    } else if (
      vehicle.speed === 0 &&
      vehicle.ignition === 1 &&
      // vehicle.gpsPositioned === 1 &&
      vehicle.vehicle_identification_number != null
    ) {
      return "Idle - ";
    } else if (
      vehicle.speed === null &&
      vehicle.ignition === null &&
      // vehicle.gpsPositioned === null &&
      vehicle.vehicle_identification_number === null
    ) {
      return "No Device";
    } else {
      return "Device Offline - ";
    }
  }

  // const handleMouseEnter = (vehicle) => {
  //   if (vehicle && vehicle.lat && vehicle.lon) {
  //     setSelectedMarker(vehicle);
  //   } else {
  //     setSelectedMarker(null);
  //   }
  // };

  // const calculateIdleTime = (time) => {
  //   if (!time) {
  //     return "N/A";
  //   }

  //   const parsedTime = moment(time, "YYYY-MM-DD HH:mm:ss");
  //   const currentTime = moment();
  //   const duration = moment.duration(currentTime.diff(parsedTime));

  //   const days = Math.floor(duration.asDays());
  //   const hours = Math.floor(duration.asHours()) % 24;
  //   const minutes = Math.floor(duration.asMinutes()) % 60;

  //   if (days > 0) {
  //     return `${days} days ${hours} hours ${minutes} minutes`;
  //   } else if (hours > 0) {
  //     return `${hours} hours ${minutes} minutes`;
  //   } else {
  //     return `${minutes} minutes`;
  //   }
  // };

  return (
    <div className="md:h-screen md:overflow-hidden">
      <div className="flex justify-between h-[10vh] ">
        <div className="ml-2 flex justify-start items-center">
          <div>
            <h3>Live Track</h3>
            <h2 className="text-lg font-semibold text-gray-500 mb-2 text-center">
              ALL VEHICLES
            </h2>
          </div>

          <div className="flex  items-center ml-[500px] ">
            {/* <input
                type="checkbox"
                id="searchCheckbox"
                className="text-white mr-2"
                onChange={handleFilter1CheckboxChange}
                checked={showMapPinnedVehicles}
              /> */}
            <input
              type="text"
              placeholder="Search by Vehicle No."
              className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <datalist id="suggestionsList">
              {suggestions.map((suggestion, index) => (
                <option key={index} value={suggestion} />
              ))}
            </datalist>
            {/* <div className="relative flex flex-nowrap space-x-2 w-1/2"> */}
            <button
        className="px-4 py-2 border border-gray-300 rounded-md bg-white focus:border-blue-500 focus:outline-none text-gray-400 whitespace-nowrap"
        onClick={handleButtonClick}
      >
        {viewMode === "Map" ? " List View" : " Map View"}
      </button>
{/* </div> */}
          </div>
        </div>

        <div className="relative pl-4">
          <div className="absolute left-0 top-2 bottom-4 w-0.5 bg-gray-500"></div>
          <ProfileModule className="pl-2" />
        </div>
      </div>

      <div className=" h-[90vh] ">
        <div className="flex justify-between p-2 bg-white">
          <div className="flex justify-start ">
            <div className="flex items-center ml-2 space-x-2 mt-2 md:mt-0">
              <div className="flex items-center ml-2 space-x-2 bg-blue-100 p-2 rounded-full" >
                {" "}
                <input
                  type="checkbox"
                  id="all"
                  onChange={() => checboxFilter("all")}
                  checked={selectedCheckbox === "all"}
                />
                <label htmlFor="all" className="text-blue-500 text-base">
                  {allcount} All
                </label>
              </div>
              <input
                type="checkbox"
                id="moving"
                onChange={() => checboxFilter("moving")}
                checked={selectedCheckbox === "moving"}
              />
              <label htmlFor="moving" className="text-green-500 text-base">
                {movingcount} Moving
              </label>
              <input
                type="checkbox"
                id="stopage"
                onChange={() => checboxFilter("stopage")}
                checked={selectedCheckbox === "stopage"}
              />
              <label htmlFor="stopage" className="text-red-500 text-base">
                {stopagecount} Stoppage
              </label>
              <input
                type="checkbox"
                id="idle"
                onChange={() => checboxFilter("idle")}
                checked={selectedCheckbox === "idle"}
              />              
              <label htmlFor="idle" className="text-amber-500 text-base">
                {idlecount} Idle
              </label>
              <input
                type="checkbox"
                id="offline"
                onChange={() => checboxFilter("offline")}
                checked={selectedCheckbox === "offline"}
              />
              <label htmlFor="offline" className="text-red-800 text-base">
                {" "}
                {offlinecount} Offline
              </label>
              <input
                type="checkbox"
                id="nodevice"
                onChange={() => checboxFilter("nodevice")}
                checked={selectedCheckbox === "nodevice"}
              />
              <label htmlFor="nodevice" className="text-gray-500 text-base">
                {" "}
                {noDevicecount} No Device
              </label>
            </div>
          </div>
          <div className="flex items-center mr-2 space-x-2 mt-0 md:mt-0">
            <div className="relative w-1/2 flex justify-end">
              {" "}
              <select
                className="px-2 py-1 border border-gray-300 rounded-md bg-white hover:bg-gray-100 focus:outline-none text-sm"
                onChange={(e) => {
                  if (e.target.value === "asc") {
                    handleSortBy("A-Z");
                  } else if (e.target.value === "desc") {
                    handleSortBy("Z-A");
                  } else {
                    toggleSortByDropdown();
                  }
                }}
                defaultValue=""
              >
                <option value="" disabled>
                  Sort By
                </option>
                <option value="asc">A-Z</option>
                <option value="desc">Z-A</option>
              </select>
            </div>
         

            <div className="relative">
        <select
          className="px-2 py-1 border border-gray-300 rounded-md bg-white hover:bg-gray-100 focus:outline-none text-sm"
          onChange={handleDropdownChange}
          value={selectedOption}
        >
          <option value="" disabled>
            Download
          </option>
          <option value="pdf">PDF</option>
          <option value="excel">Excel</option>
        </select>
      </div>
          </div>
        </div>

        {showVehicleDataTable ? (
          <div className="">
            <VehicleDataTable Direction ={filteredDirectionData} />
          </div>
        ) : (
          <div className="flex justify-evenly mt-3">
            
            <div className="w-full md:w-5/12 max-h-[310px] md:max-h-[500px] overflow-y-auto ">
            <div className="ml-4 mr-4 "  
             >
  {
    filteredDirectionData.map((vehicle, index) => {
      const dateTime = vehicle.fixTime_IST ? vehicle.fixTime_IST.replace(/T|\.000Z/g, ' ') : '';
      console.log(dateTime,"dddd")
      return (
        <div
          key={index}
          // onClick={() => handleVehicleClick(vehicle.registration_number)}
          className="w-full h-[] border border- border-gray-300 rounded-md  hover:scale-105 p-4 mb-4 bg-white"
          // onClick={() => handleNavigate(vehicle)}
          // onMouseEnter={() => handleMouseEnter(vehicle)}
          // onMouseLeave={() => handleMouseEnter(null)}
          style={{
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
          }}
        >
          <div className="flex items-center ">
            <input
              type="checkbox"
              className="h-4 w-4 text-indigo-600"
              checked={checkedVehicles[index]}
              onChange={() => handleCheckboxChange(index)}
            />
            <label className="flex items-center space-x-4 px-4">
              <span
                className={`text-lg mt-2 font-semibold  mb-2 text-start ${getVehicleColorClass(vehicle)}`}
                onClick={() => handleVehicleClick(vehicle.registration_number)}
              >
                {vehicle.registration_number}
              </span>
            </label>
          </div>
          {
            vehicle.vehicle_identification_number != null ? (
              <div>
                <div className={`flex justify-start mb-2 ${getVehicleColorClass(vehicle)}`}>
                  <FaMapPin className="w-4 h-4 mr-4 mt-1 " />
                  <b>{getVehicleColorClass1(vehicle)}</b>
                  {/* {vehicle.fixTime_IST ? (
                    vehicle.speed > 0 ? "Moving" :  */}
                    {(vehicle.time)}
                  {/* ) : ''} */}
                </div>

                <div className="mb-2">
                  <div className="flex items-start mb-3">
                    <RiMapPinLine className="mr-3 h-5 w-4 mt-0 text-blue-700" />
                    <span className="text-sm break-words mr-4">
                      ( {vehicle.lat}, {vehicle.lon})
                    </span>
                    <FaCopy
                      className={`w-3 h-4 text-blue-500 cursor-pointer`}
                      onClick={() => handleCopyAddress(`(${selectedMarker.lat}, ${selectedMarker.lon})`)}
                    />
                  </div>
                </div>

                <div className=" px-2 ">
                  <div className="flex items-center space-x-4">
                    <div className="flex flex-col items-center p-2 bg-white rounded-lg shadow shadow-blue-400">
                      <h1>{vehicle.ignition === 1 ? "ON" : "OFF"}</h1>
                      <span className="text-xs">IGNITION</span>
                    </div>
                    <div className="flex flex-col items-center p-2 bg-white rounded-lg shadow shadow-blue-400">
                      <h1>{vehicle.voltageLevel}</h1>
                      <span className="text-xs text-center">
                        BATTERY VOLTAGELEVEL
                      </span>
                    </div>
                    <div className="flex flex-col items-center p-2 bg-white rounded-lg shadow shadow-blue-400">
                      <h1>
                        {vehicle.speed} {" "}
                        {vehicle.speedUnit}
                      </h1>
                      <span className="text-xs text-center">SPEED</span>
                    </div>
                  </div>

                  {/* <div className="flex items-center">
                        <FaRoute />
                        <span className="text-xs ml-2 font-medium">
                          Route
                        </span>
                      </div>
                      <span className="text-xs flex items-center">
                        <BsPersonStanding className="h-6 w-5 ml-0" />
                        No Stand Nearby
                      </span> */}
                  <div className="mt-2">
                    Date & Time: {dateTime}
                  </div>
                </div>

              </div>
            ) : (
              <div key={index}>No Device</div>
            )
          }
        </div>
      )
    })
  }
</div>

            </div>

            <div className="w-full md:w-9/12 shadow-md">
      <LoadScript
        googleMapsApiKey={apiKey}
        onLoad={fetchDirectionData}
      >
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={8}
          center={defaultCenter}
          onLoad={(map) => setMap(map)}
        >
          {map && filteredDirectionData.map((vehicle, index) => {
            const latitude = parseFloat(vehicle.lat);
            const longitude = parseFloat(vehicle.lon);

            if (!isNaN(latitude) && !isNaN(longitude)) {
              let iconUrl;

              if (vehicle.ignition === 1 && vehicle.speed > 0  && vehicle.vehicle_identification_number != null) {
                iconUrl = gT;
              } else if (vehicle.ignition === 0 && vehicle.speed === 0  && vehicle.vehicle_identification_number != null) {
                iconUrl = rT;
              } else if (vehicle.ignition === 1 && vehicle.speed === 0  && vehicle.vehicle_identification_number != null) {
                iconUrl = yT;
              } else if (vehicle.ignition === 0 && vehicle.speed === 0  && vehicle.vehicle_identification_number != null) {
                iconUrl = BT;
              } 

              return (
                <Marker
                  key={index}
                  position={{ lat: latitude, lng: longitude }}
                  onClick={() => {
                    setSelectedMarker(vehicle);
                    vehicleColor(vehicle);
                  }}
                  icon={{
                    url: iconUrl,
                    scaledSize: new window.google.maps.Size(50, 70),
                  }}
                  visible={showMapPinnedVehicles}
                />
              );
            }
            return null;
          })}
          {selectedMarker && (
            <InfoWindow
              position={{ lat: parseFloat(selectedMarker.lat), lng: parseFloat(selectedMarker.lon) }}
              onCloseClick={() => setSelectedMarker(null)}
              options={{ pixelOffset: new window.google.maps.Size(0, -50) }}
            >
              <div className="w-full h-[16vh] border border-gray-300 rounded-md p-2 bg-white" style={{ boxShadow: "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px" }}>
                <h2 style={{ fontSize: '10px', fontWeight: 'bold' }}  className={`${getVehicleColorClass(selectedMarker)}`}>
                  <b >Vehicle Number:</b> {selectedMarker.registration_number}
                </h2>
              
                <div className="flex justify-start">
                <p className="mr-2">({selectedMarker.lat}, {selectedMarker.lon})</p>
                <FaCopy className="text-blue-500 cursor-pointer" onClick={() => handleCopyAddress(`(${selectedMarker.lat}, ${selectedMarker.lon})`)} />             
                </div>
                     
                <p ><b >Speed:</b> {selectedMarker.speed || "0"} km/h</p>
          
                  <p >
                  Ignition:
                  <span style={{ color: selectedMarker.ignition === 1 ? "green" : "red" }}>
                    {selectedMarker.ignition}
                  </span>
                </p>
                <p><b >Driver: </b>{selectedMarker.driver_name || "N/A"}</p>
                <p >Route: {selectedMarker.route || "N/A"}</p>
             
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </LoadScript>
    </div>

          </div>
        )}



      </div>
    </div>
  );
}

export default MapHome;

