import React, { useState,useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import ProfileModule from "./UserProfile";
import axios from "axios";
import config from "../config";
import { FaTimes,FaEdit,FaTrash } from 'react-icons/fa'; 
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white", // Rich blue background color
    padding: "20px",
    borderRadius: "10px",
    width: "90%", // Adjusted width to fit within the screen
    maxWidth: "600px", // Limiting the maximum width of the modal
    maxHeight: "85vh", // Limiting the modal height to 80% of the viewport height
    overflow: "auto", // Adding overflow auto to enable scrolling if content exceeds maxHeight
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Adding box shadow for depth
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.3)",
  },
};

Modal.setAppElement("#root"); // Ensure this is set for accessibility

function Drivers() {
  const [searchTerm, setSearchTerm] = useState('');
  const [editingDriverId, setEditingDriverId] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState(null); // New state for submission status
  const [driversData, setDriversData] = useState([]); // State variable for storing driver data
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    country: "",
    aadharNumber: "",
    dob: null,
    address: "",
    licenseNumber: "",
    issueDate: null,
    expiryDate: null,
    profilePicture: null,
    panCard: null,
    drivingLicense: null,
    miscellaneous: null,
  });

  // Fetch drivers data when the component mounts
  useEffect(() => {
     fetchDrivers();
  }, []);

  const fetchDrivers = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/mydrivers/drivers`);
      setDriversData(response.data);
    } catch (error) {
      console.error('Error fetching drivers data:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    
  };
  

  const handleDateChange = (date, name) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: date ? date.toISOString().split('T')[0] : null,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
  
    // Check if the user has selected a file
    if (files.length > 0) {
      setFormData((prevState) => ({
        ...prevState,
        [name]: files[0],
      }));
    } else {
      // If no file is selected, retain the previous file data
      // You can optionally set it to null if you want to clear it
      setFormData((prevState) => ({
        ...prevState,
        [name]: prevState[name],
      }));
    }
  };
  

  const handleAddDriver = async () => {
    const data = new FormData();
    for (const key in formData) {
      data.append(key, formData[key]);
    }

    try {
      const response = await axios.post(`${config.apiUrl}/mydrivers/drivers`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log('Data submitted successfully:', response.data);
      setSubmissionStatus('success'); 
      toast.success('Driver Details created successfully!'); // Show success toast
      setFormData({
        name: "",
        phone: "",
        country: "",
        aadharNumber: "",
        dob: null,
        address: "",
        licenseNumber: "",
        issueDate: null,
        expiryDate: null,
        profilePicture: null,
        panCard: null,
        drivingLicense: null,
        miscellaneous: null
      });
      closeModal();
       // Fetch updated drivers data
       const updatedResponse = await axios.get(`${config.apiUrl}/mydrivers/drivers`);
       setDriversData(updatedResponse.data);
    } catch (error) {
      console.error('Error submitting data:', error);
      setSubmissionStatus('error'); // Set submission status to error
    }
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    setSubmissionStatus(null); 
    setFormData("");// Reset submission status on modal close
  }

  const handleKeyPress = (e) => {
    const pattern = /^[A-Za-z\s]*$/; // Regex pattern to match only letters and space
    if (!pattern.test(e.key)) {
      e.preventDefault(); // Prevent input if the key is not a letter or space
    }
  };
  const handleNumKeyPress = (e) => {
    const pattern = /^[0-9]*$/; // Regex pattern to match only numeric characters
    if (!pattern.test(e.key)) {
      e.preventDefault(); // Prevent input if the key is not a number
    }
  };
  const filteredDrivers = driversData.filter((driver) => {
    return (
      driver.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      driver.phone.includes(searchTerm)
    );
  });

  const handleEditDriver = (driver) => {
    console.log(driver); // Log the driver object
    // Set form data including the file paths
    setFormData({ 
      ...driver,
      profilePicture: driver.profilePicture ? { name: driver.profilePicture.split('/').pop() } : null,
      panCard: driver.panCard ? { name: driver.panCard.split('/').pop() } : null,
      drivingLicense: driver.drivingLicense ? { name: driver.drivingLicense.split('/').pop() } : null,
      miscellaneous: driver.miscellaneous ? { name: driver.miscellaneous.split('/').pop() } : null,
    });
    setEditingDriverId(driver.id); // Use driver.id instead of driver._id
    setIsOpen(true); // Open the modal for editing
  };


  const deleteDriver = async (driverId) => {
    try {
      // Send a DELETE request to the backend
      const response = await axios.delete(`${config.apiUrl}/mydrivers/${driverId}`);
  
      if (response.status === 200) {
        toast.success('Driver Details Deleted successfully');
        
        // Optionally, update the local state to remove the deleted driver from the list
        setDriversData(prevDrivers => prevDrivers.filter(driver => driver.id !== driverId));
      }
    } catch (error) {
      console.error('Error deleting driver:', error);
      toast.error('Error deleting driver');
    }
  };
  
  const handleDeleteClick = (driverId) => {
    if (window.confirm('Are you sure you want to delete this driver?')) {
      deleteDriver(driverId);
    }
  };
  
  // Function to handle updating a driver
const handleUpdateDriver = async () => {
  const data = new FormData();
  for (const key in formData) {
    data.append(key, formData[key]);
  }

  try {
    const response = await axios.put(`${config.apiUrl}/mydrivers/drivers/${editingDriverId}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    console.log('Data updated successfully:', response.data);
    setSubmissionStatus('success'); 
    toast.success('Driver details updated successfully!'); // Show success toast
    closeModal();
    setFormData({
      name: "",
      phone: "",
      country: "",
      aadharNumber: "",
      dob: null,
      address: "",
      licenseNumber: "",
      issueDate: null,
      expiryDate: null,
      profilePicture: null,
      panCard: null,
      drivingLicense: null,
      miscellaneous: null
    });
    setEditingDriverId(null); // Reset the editing driver ID
    // Fetch updated drivers data
    const updatedResponse = await axios.get(`${config.apiUrl}/mydrivers/drivers`);
    setDriversData(updatedResponse.data);
  } catch (error) {
    console.error('Error updating data:', error);
    setSubmissionStatus('error'); // Set submission status to error
  }
};

  return (
    <div className="">
        <div className="flex justify-between h-[10vh] border-b-2  border-white">
        <div className="ml-2 flex justify-start items-center">
          <div>
            <h3>Driver</h3>
            <h2 className="text-lg font-semibold text-gray-500 mb-2 text-center">
              My Drivers
            </h2>
          </div>

       
        </div>

        <div className="relative pl-4">
          <div className="absolute left-0 top-2 bottom-4 w-0.5 bg-gray-100"></div>
          <ProfileModule className="pl-2" />
        </div>
      </div>
      <div className="container mx-auto bg-white p-4 mt-2">
        <div className="mb-4 flex justify-between items-center">
          <input
            type="text"
            placeholder="Name or Phone Number"
            className="p-2 border border-gray-300 rounded"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button
            onClick={openModal}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 ml-4"
          >
            Add Driver
          </button>
        </div>

        <table className="min-w-full bg-white mb-4 shadow-md rounded-lg overflow-hidden">
  <thead className="bg-gray-100">
    <tr>
      <th className="py-3 px-4 border-b border-gray-200">Name</th>
      <th className="py-3 px-4 border-b border-gray-200">Phone Number</th>
      <th className="py-3 px-4 border-b border-gray-200">DOB</th>
      <th className="py-3 px-4 border-b border-gray-200">Address</th>
      <th className="py-3 px-4 border-b border-gray-200">License Number</th>
      <th className="py-3 px-4 border-b border-gray-200">Issue Date</th>
      <th className="py-3 px-4 border-b border-gray-200">Expiry Date</th>
      <th className="py-3 px-4 border-b border-gray-200">Edit</th>
    </tr>
  </thead>
  <tbody>
  {filteredDrivers.map((driver, index) => (
    <tr key={index} className={(index % 2 === 0) ? "bg-gray-50" : "bg-white"}>
      <td className="py-3 px-4 border-b text-center border-gray-200">{driver.name}</td>
      <td className="py-3 px-4 border-b text-center border-gray-200">{driver.phone}</td>
      <td className="py-3 px-4 border-b text-center border-gray-200">{new Date(driver.dob).toLocaleDateString()}</td>
      <td className="py-3 px-4 border-b text-center border-gray-200">{driver.address}</td>
      <td className="py-3 px-4 border-b text-center border-gray-200">{driver.licenseNumber}</td>
      <td className="py-3 px-4 border-b text-center border-gray-200">{new Date(driver.issueDate).toLocaleDateString()}</td>
      <td className="py-3 px-4 border-b text-center border-gray-200">{new Date(driver.expiryDate).toLocaleDateString()}</td>
      
      <td className="py-3 px-4 border-b text-center border-gray-200">
        <div className="flex justify-center space-x-2">
          <button 
            className="bg-yellow-500 text-white rounded-full p-1 hover:bg-yellow-600"  
            onClick={() => handleEditDriver(driver)}
          >
            <FaEdit className="h-4 w-4" />
          </button>
          <button 
            className="bg-red-500 text-white rounded-full p-1 hover:bg-red-600"  
            onClick={() => handleDeleteClick(driver.id)}
          >
            <FaTrash className="h-4 w-4" />
          </button>
        </div>
      </td>
    </tr>
  ))}
</tbody>

</table>


        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Add Driver"
         
        >
           {/* Cancel icon */}
           <div className="absolute top-2 right-2 z-50">
    <FaTimes 
      onClick={closeModal} // Close the modal when the cancel icon is clicked
      className="text-red-500 cursor-pointer" // Styling for the cancel icon
    />
  </div>
  <h2 className="text-2xl mb-4">{editingDriverId ? 'Edit Driver' : 'Add Driver'}</h2>
          <form >
            <div className="mb-4">
            <label className="block mb-2 text-gray-700">Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
                className="w-full p-2 border border-gray-300 rounded"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2">Phone Number</label>
              <input
  type="tel"
  name="phone"
  value={formData.phone}
  onChange={handleChange}
  onKeyPress={(e) => {
    
    if (isNaN(Number(e.key))) {
      e.preventDefault();
    }
  }}
  pattern="[0-9]{10}"  
  maxLength="10"        
  className="w-full p-2 border border-gray-300 rounded"
  required
/>


            </div>
            <div className="mb-4">
              <label className="block mb-2">Country</label>
              <input
  type="text"
  name="country"
  value={formData.country}
  onChange={handleChange}
  onKeyPress={handleKeyPress}
  className="w-full p-2 border border-gray-300 rounded"
  required
/>
            </div>
            <div className="mb-4">
              <label className="block mb-2">Aadhar Number</label>
              <input
  type="text"
  name="aadharNumber"
  value={formData.aadharNumber}
  onChange={handleChange}
  onKeyPress={handleNumKeyPress}
  pattern="[0-9]{12}"  // Restrict input to numeric characters only
  maxLength="12"        // Limit maximum length to 12 digits
  className="w-full p-2 border border-gray-300 rounded"
  required
/>
            </div>
            <div className="mb-4">
              <label className="block mb-2">License Number</label>
              <input
  type="text"
  name="licenseNumber"
  value={formData.licenseNumber}
  onChange={handleChange}
  maxLength="16"        // Limit maximum length to 16 characters
  className="w-full p-2 border border-gray-300 rounded"
  required
/>
            </div>
            <div className="mb-4">
      <label className="block mb-2">License Issue Date</label>
      <div className="relative">
        <DatePicker
          showIcon
          toggleCalendarOnIconClick
          selected={formData.issueDate}
          onChange={(date) => handleDateChange(date, "issueDate")}
          dateFormat="dd/MM/yyyy"
          className="p-2 pl-10 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
          isClearable
          showMonthDropdown
          useShortMonthInDropdown
          showYearDropdown
          scrollableYearDropdown
          popperClassName="date-picker-popper"
          required
        />
       
      </div>
    </div>
            <div className="mb-4">
              <label className="block mb-2">License Expiry Date</label>
             
              <DatePicker
               showIcon
               toggleCalendarOnIconClick
                selected={formData.expiryDate}
                onChange={(date) => handleDateChange(date, "expiryDate")}
                dateFormat="dd/MM/yyyy" // Set the date format to dd/MM/yyyy
                className="p-2 border border-gray-300 rounded"
                isClearable
                showMonthDropdown
          useShortMonthInDropdown
          showYearDropdown
          scrollableYearDropdown
          popperClassName="date-picker-popper"
          required
              />
               
            </div>
            <div className="mb-4">
              <label className="block mb-2">Date of Birth</label>
             
              <DatePicker
                showIcon
                toggleCalendarOnIconClick
                selected={formData.dob}
                onChange={(date) => handleDateChange(date, "dob")}
                dateFormat="dd/MM/yyyy" // Set the date format to dd/MM/yyyy
                className="p-2 border border-gray-300 rounded"
                isClearable
                showMonthDropdown
          useShortMonthInDropdown
          showYearDropdown
          scrollableYearDropdown
          popperClassName="date-picker-popper"
          required
              />
              
            </div>
            <div className="mb-4">
            <label className="block mb-2 text-gray-700">Address</label>
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded"
                required
              />
            </div>
            <div>
              <h2 className="text-2xl mb-4">Add Attachments</h2>
              <div className="mb-4">
  <label className="block mb-2">Profile Picture</label>
  <input
    type="file"
    name="profilePicture"
    onChange={handleFileChange}
    className="w-full p-2 border border-gray-300 rounded"
    
  />
  {formData.profilePicture && (
    <div className="mt-2">
      <span className="mr-2">{formData.profilePicture.name}</span>
     
      
      <button
        type="button"
        onClick={() => setFormData({ ...formData, profilePicture: null })}
        className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600 flex items-center"
      >
        <FaTimes className="mr-1" />
       
      </button>
     
    </div>
  )}
</div>


<div className="mb-4">
  <label className="block mb-2">PAN Card</label>
  <input
    type="file"
    name="panCard"
    onChange={handleFileChange}
    className="w-full p-2 border border-gray-300 rounded"
  />
  {formData.panCard && (
    <div className="mt-2">
      <span className="mr-2">{formData.panCard.name}</span>
      <button
        type="button"
        onClick={() => setFormData({ ...formData, panCard: null })}
        className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600 flex items-center"
      >
        <FaTimes className="mr-1" />
      </button>
    </div>
  )}
</div>

<div className="mb-4">
  <label className="block mb-2">Driving License</label>
  <input
    type="file"
    name="drivingLicense"
    onChange={handleFileChange}
    className="w-full p-2 border border-gray-300 rounded"
  />
  {formData.drivingLicense && (
    <div className="mt-2">
      <span className="mr-2">{formData.drivingLicense.name}</span>
      <button
        type="button"
        onClick={() => setFormData({ ...formData, drivingLicense: null })}
        className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600 flex items-center"
      >
        <FaTimes className="mr-1" />
      </button>
    </div>
  )}
</div>

<div className="mb-4">
  <label className="block mb-2">Miscellaneous</label>
  <input
    type="file"
    name="miscellaneous"
    onChange={handleFileChange}
    className="w-full p-2 border border-gray-300 rounded"
  />
  {formData.miscellaneous && (
    <div className="mt-2">
      <span className="mr-2">{formData.miscellaneous.name}</span>
      <button
        type="button"
        onClick={() => setFormData({ ...formData, miscellaneous: null })}
        className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600 flex items-center"
      >
        <FaTimes className="mr-1" />
      </button>
    </div>
  )}
</div>

            </div>
            <div className="flex justify-between">
  <button
    type="button"
    onClick={closeModal}
    className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
  >
    Cancel
  </button>
  {editingDriverId ? (
    <button
      type="button"
      onClick={handleUpdateDriver}
      className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
    >
      Update
    </button>
  ) : (
    <button
      type="button"
      onClick={handleAddDriver}
      className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
    >
      Submit
    </button>
  )}
</div>

          </form>
          {submissionStatus === 'success' && <p className="text-green-500 mt-4">Data submitted successfully!</p>}
          {submissionStatus === 'error' && <p className="text-red-500 mt-4">Error submitting data. Please try again.</p>}
        </Modal>
      </div>
    </div>
  );
}

export default Drivers;