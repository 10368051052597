import React, { useState,useEffect } from 'react';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import moment from 'moment-timezone';

const CSVDownload = ({ reportData, reportName, totalSummary }) => {
    const [isDownloading, setIsDownloading] = useState(false);

    
    const handleCSVDownload = () => {
        if (isDownloading || !reportData || reportData.length === 0) return; // Prevent multiple downloads or invalid data
        setIsDownloading(true);


        let formattedData = [];

        // Structure the data based on reportName
        switch (reportName) {
            case 'Stoppage Report':
                formattedData = reportData.map(item => ({
                    'Display Number': item.displayNumber,
                    'Start Stop Time': item.startStopTime,
                    'End Stop Time': item.endStopTime,
                    'Duration': item.duration,
                    'Duration (Minutes)': item.durationInMinutes,
                    'Location Coordinates': `${item.lat}, ${item.lon}`,
                }));
                break;

            case 'Idle Report':
                formattedData = reportData.map(item => ({
                    'Display Number': item.displayNumber,
                    'Start Idle Time': item.startIdleTime,
                    'End Idle Time': item.endIdleTime,
                    'Duration': item.duration,
                    'Duration (Minutes)': item.durationInMinutes,
                    'Location Coordinates': `${item.lat}, ${item.lon}`,
                }));
                break;

            case 'Moving Report':
                formattedData = reportData.map(item => ({
                    'Display Number': item.displayNumber,
                    'Start Moving Time': item.startMovingTime,
                    'End Moving Time': item.endMovingTime,
                    'Distance': item.distance,
                    'Duration': item.duration,
                    'Stoppage Duration': item.stoppageDuration,
                    'Stoppage Duration (Minutes)': item.stoppageDurationInMinutes,
                    'Start Location': `${item.startLocation.lat}, ${item.startLocation.lon}`,
                    'End Location': `${item.endLocation.lat}, ${item.endLocation.lon}`,
                }));

                // Add total summary at the end
                formattedData.push({
                    'Display Number': 'Total',
                    'Start Moving Time': '',
                    'End Moving Time': '',
                    'Distance': totalSummary.totalDistance,
                    'Duration': totalSummary.totalMovingDuration,
                    'Duration (Minutes)': totalSummary.totalMovingDurationInMinutes,
                    'Stoppage Duration': totalSummary.totalStoppageDuration,
                    'Stoppage Duration (Minutes)': totalSummary.totalStoppageDurationInMinutes,
                    'Start Location': '',
                    'End Location': '',
                });
                break;

            case 'Movement Summary Report':
                formattedData = reportData.map(item => ({
                    'Display Number': item.displayNumber,
                    'Date': item.date,
                    'Start Time': item.startTime,
                    'End Time': item.endTime,
                    'Idle Time': item.idle,
                    'Moving Time': item.moving,
                    'Stopped Time': item.stopped,
                    'Total Distance': item.totalDistance,
                    'Fuel Efficiency': item.fuelEfficiency || 'N/A',
                    'FuelConsumed':item.FuelConsumed || 'N/A'
                }));
                break;

            case 'Distance Report':
                formattedData = reportData.map(item => ({
                    'Date': moment(item.date).format('YYYY-MM-DD'),
                    'Vehicle Number': item.vehicle,
                    'Total Distance': item.totalDistance
                }));

                // Add total distance for all dates at the end
                formattedData.push({
                    'Date': 'Total',
                    'Vehicle Number': '',
                    'Total Distance': totalSummary
                });
                break;
                case 'Geofence Report':
                    formattedData =reportData.map(item =>({
                        'Vehicle Number' :item.display_number,
                        'Geofence Name':item.geofence_name,
                        'EntryTime':item.entryTime,
                        'exitTime':item.exitTime,
                        'duration':item.totalDuration
                    }))
    break;

            default:
                console.error('Unknown report name');
                setIsDownloading(false); // Reset downloading state
                return;

                
        }

        // Convert formatted data to CSV
        const csv = Papa.unparse(formattedData);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', `${reportName}.csv`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setIsDownloading(false);
            
        };
    
        
    

  
    useEffect(() => {
        // Only call handleExcelDownload when reportData is valid and available
        if (!isDownloading && reportData && reportData.length > 0) {
            handleCSVDownload();
        }
    }, [reportData]); // Call when reportData changes
    return null; // Don't render anything
};

export default CSVDownload;
