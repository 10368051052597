import React, { useState, useEffect } from "react";
import moment from "moment";


const VehicleTable = ({ Direction }) => {
  const [filteredVehicleData, setFilteredVehicleData] = useState([]);

  useEffect(() => {
    setFilteredVehicleData(Direction);
  }, [Direction]);

  function getVehicleColorClass(vehicle) {
    if (
      vehicle.speed > 0 &&
      vehicle.ignition === 1 &&
      // vehicle.gpsPositioned === 1 &&
      vehicle.vehicle_identification_number != null
    ) {
      return "text-green-500";
    } else if (
      vehicle.speed === 0 &&
      vehicle.ignition === 0 &&
      // vehicle.gpsPositioned === 1 &&
      vehicle.vehicle_identification_number != null
    ) {
      return "text-red-500";
    } else if (
      vehicle.speed === 0 &&
      vehicle.ignition === 1 &&
      // vehicle.gpsPositioned === 1 &&
      vehicle.vehicle_identification_number != null
    ) {
      return "text-amber-500";
    } else if (
      vehicle.speed === 0 &&
      vehicle.ignition === 0 &&
      // vehicle.gpsPositioned === null &&
      vehicle.vehicle_identification_number === null
    ) {
      return "text-red-500";
    } else {
      return "text-gray-800";
    }
  }

  function getVehicleColorClass1(vehicle) {
    if (
      vehicle.speed > 0 &&
      vehicle.ignition === 1 &&
      // vehicle.gpsPositioned === 1 &&
      vehicle.vehicle_identification_number != null
    ) {
      return "Moving... ";
    } else if (
      vehicle.speed === 0 &&
      vehicle.ignition === 0 &&
      // vehicle.gpsPositioned === 1 &&
      vehicle.vehicle_identification_number != null
    ) {
      return "Stopped - ";
    } else if (
      vehicle.speed === 0 &&
      vehicle.ignition === 1 &&
      // vehicle.gpsPositioned === 1 &&
      vehicle.vehicle_identification_number != null
    ) {
      return "Idle - ";
    } else if (
      vehicle.speed === 0 &&
      vehicle.ignition === 0 &&
      // vehicle.gpsPositioned === null &&
      vehicle.vehicle_identification_number === null
    ) {
      return "Offline";
    } else {
      return "No Device - ";
    }
  }

  // const calculateIdleTime = (time) => {
  //   if (!time) {
  //     return "N/A";
  //   }

  //   const parsedTime = moment(time, "YYYY-MM-DD HH:mm:ss");
  //   const currentTime = moment();
  //   const duration = moment.duration(currentTime.diff(parsedTime));

  //   const days = Math.floor(duration.asDays());
  //   const hours = Math.floor(duration.asHours()) % 24;
  //   const minutes = Math.floor(duration.asMinutes()) % 60;

  //   if (days > 0) {
  //     return `${days} days ${hours} hours ${minutes} minutes`;
  //   } else if (hours > 0) {
  //     return `${hours} hours ${minutes} minutes`;
  //   } else {
  //     return `${minutes} minutes`;
  //   }
  // };



  return (
    <div
      className="m-4 mx-auto bg-white"
      style={{
        maxWidth: "94vw",
        maxHeight: "78vh",
        overflowX: "auto",
        overflowY: "auto",
      }}
    >
      <table className="w-full text-sm">
        <thead className="sticky top-0 bg-sky-800 text-white ">
          <tr>
            <th className="px-20 py-4 sticky left-0 bg-sky-800 whitespace-nowrap ">
              Vehicle Number
            </th>
            <th className="px-20 py-4 text-left whitespace-nowrap">
              Vehicle Movements
            </th>
            <th className="px-8 py-4 text-left whitespace-nowrap">
              Date & Time
            </th>
            <th className="px-8 py-4 text-left whitespace-nowrap">
              Current Location
            </th>
            <th className="px-8 py-4 text-left whitespace-nowrap">Ignition</th>
            <th className="px-10 py-4 text-left whitespace-nowrap">Speed</th>
            <th className="px-8 py-4 text-left whitespace-nowrap">
              Driver Name
            </th>
           
            <th className="px-8 py-4 text-left whitespace-nowrap">
              Trip Source
            </th>
            <th className="px-8 py-4 text-left whitespace-nowrap">
              Trip Destination
            </th>
            <th className="px-8 py-4 text-left whitespace-nowrap">
              Vehicle Group Name
            </th>
            <th className="px-8 py-4 text-left whitespace-nowrap">
              Vehicle Battery Voltage
            </th>
           
          </tr>
        </thead>
        <tbody>
          {filteredVehicleData.map((vehicle, index) => (
            <tr
              key={index}
              className="w-full border-b-2 text-gray-500 border-gray-300 rounded-md bg-white mb-4 transition-transform duration-200 hover:scale-100 hover:shadow-lg"
              style={{ margin: "8px 0" }}
            >
              <td className="px-6 py-4 sticky left-0 bg-white">
                <b className={`${getVehicleColorClass(vehicle)} text-lg`}>
                  {vehicle.registration_number
                    ? vehicle.registration_number
                    : "N/A"}
                </b>
                <br />
                <div className="absolute right-0 top-2 bottom-4 w-0.5 bg-gray-400"></div>
              </td>
              <td className={`px-6 py-4  ${getVehicleColorClass(vehicle)}`}>
                <div style={{ fontWeight: "bold" }}>
                  {getVehicleColorClass1(vehicle)} {(vehicle.time)}
                </div>
               </td>
              <td className="px-2 py-4">{vehicle.fixTime_IST}</td>
              <td className="px-8 py-4">
                {vehicle.lat ? `lat-${vehicle.lat}, lon-${vehicle.lon}` : "N/A"}
              </td>
              <td className={`px-8 py-4 `}>
                <b>{vehicle.ignition !== null ? vehicle.ignition : "N/A"}</b>
              </td>
              <td className={`px-8 py-4 `}>
                <b>
                  {vehicle.speed != null
                    ? `${vehicle.speed} ${vehicle.speedUnit} `
                    : "N/A"}
                </b>
              </td>

              <td className="px-8 py-4">
                {vehicle.driver_name ? vehicle.driver_name : "N/A"}
              </td>
           
              <td className="px-8 py-4">
                {vehicle.sourceAddress ? vehicle.sourceAddress : "N/A"}
              </td>
              <td className="px-8 py-4">
                {vehicle.destinationAddress ? vehicle.destinationAddress : "N/A"}
              </td>
              <td className="px-8 py-4">
                {vehicle.vehicleGroupName ? vehicle.vehicleGroupName : "N/A"}
              </td>
              <td className="px-8 py-4">
                {vehicle.voltageLevel ? vehicle.voltageLevel : "N/A"}
              </td>
              
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default VehicleTable;
