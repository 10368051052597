import React, { useState } from "react";
import axios from "axios";
import Background1 from "../assests/ll.jpeg";
import bg from "../assests/5y.jpeg";
import { useNavigate } from "react-router-dom";
import config from "../config";
import logo1 from '../assests/y.jpeg';
import logo2 from '../assests/kjk.png';

const Login = () => {
  const [loginData, setLoginData] = useState({
    loginIdentifier: "",
    password: "",
  });
  const navigate = useNavigate();
  const [alertMessage, setAlertMessage] = useState(null);
  const [color, setcolor] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value.trim() });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);
    try {
      const response = await axios.post(
        `${config.apiUrl}/user/login`,
        loginData
      );
      if (response.data.status === 200) {
        setAlertMessage("Login Successful!");
        setcolor(true);
        setTimeout(() => {
          setAlertMessage(null);
          localStorage.setItem("user", JSON.stringify(response.data.data));
          sessionStorage.setItem('authentication',true);

          const userRole = response.data.data.user.user_role;
          console.log(userRole);

          navigate("/AllVehicles");
          setIsButtonDisabled(false);
        }, 2000);
      }
    } catch (error) {
      console.error("Login failed:", error);
      if (error.response && error.response.status === 401) {
        const errorMessage = error.response.data.message;
        if (errorMessage === "Invalid username or password") {
          setAlertMessage("Invalid username or password");
        } else if (errorMessage === "Invalid username") {
          setAlertMessage("Invalid username or password");
        } else if (errorMessage === "Invalid password") {
          setAlertMessage("Invalid password.");
        } else {
          setAlertMessage("Invalid username or password");
        }
        setTimeout(() => {
          setAlertMessage(null);
          setIsButtonDisabled(false);
        }, 2000);
      } else {
        console.error("An unexpected error occurred:", error);
        setAlertMessage("An unexpected error occurred. Please try again.");
        setTimeout(() => {
          setAlertMessage(null);
          setIsButtonDisabled(false);
        }, 2000);
      }
    }
  };

  const alertStyle = {
    position: "fixed",
    bottom: "48px",
    left: "50%",
    transform: "translateX(-50%)",
    backgroundColor: color ? "transparent" : "red",
    color: "white",
    padding: "10px",
    borderRadius: "5px",
    boxShadow: "0 0 10px rgba(255, 255, 255, 1)",
    zIndex: "9999",
    display: alertMessage ? "block" : "none",
    opacity: alertMessage ? 1 : 0,
    transition: "opacity 0.5s ease-in-out",
  };

  return (
    <div className="relative h-[100vh] overflow-hidden">
       <style>
        {`
       @media only screen and (max-width: 767px) {
       
      }
@media screen and (min-width: 1200px) {
  .try {
     width:950px;
  }
       
        `}
      </style>
      <div className=""
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSize: "100% 100%",
          minHeight: "100vh",
          backgroundRepeat: "no-repeat",
          fontFamily: "serif",
        }}
      ></div>
 <div className="absolute top-0 flex flex-column justify-center  left-0 ml-4 mt-4">
      <img className="h-20 W-10 " src={logo1} alt="Logo" />
      <img className="h-20 W-10 " src={logo2} alt="Logo" />

    </div>
      <div className="absolute top-8 left-0 w-full h-full flex flex-column justify-center items-center">
        <div className="" >
          {/* <div className="flex justify-end items-center">
            <img className="h-20 W-24" src={logo1} alt="Logo" />
          </div> */}
          <div className="text-center mb-4 text-gray-200 text-3xl">
      <h1>
        <b>Fleet Management System</b>
      </h1>
    </div>
          <div className="try bg-slate-700 p-12 border-5 flex" style={{  boxShadow: "0 0 10px rgba(255, 255, 255, 1)",}}>
  <div
    className="flex-1 mr-6"
    style={{
    
      backgroundImage: `url(${Background1})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      borderRadius:'10%'
    }}
  ></div>

  {/* Right side with login heading and form */}
  <div className="flex-1 p-4 ">
  

    <div className="text-center text-gray-100 m-3">
      <h2 className="text-3xl mb-0">
        <b>User Login</b>
      </h2>
      <p className="text-xl">
        Welcome back! Please enter your details
      </p>
    </div>
    <div style={alertStyle}>{alertMessage}</div>

    <form onSubmit={handleLogin} className="text-center">
      <div className="">
        <label htmlFor="loginIdentifier">
          <b className="text-lg text-gray-200">
            User Name / Mobile Number
          </b>
        </label>
        <br />
        <input
          type="text"
          id="loginIdentifier"
          name="loginIdentifier"
          placeholder="User Name or Mobile Number"
          className="p-3 rounded w-full bg-gray-200" /* Updated width */
          value={loginData.loginIdentifier}
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-4">
        <label htmlFor="password" className="form-label">
          <b className="text-lg text-gray-200">Password</b>
        </label>
        <br />
        <input
          type="password"
          id="password"
          name="password"
          placeholder="Password"
          className="p-3 rounded w-full bg-gray-200" /* Updated width */
          value={loginData.password}
          onChange={handleChange}
          required
        />
      </div>
      <div className="text-center">
        <button
          type="submit"
          className="btn bg-shitw text-white text-lg p-2 rounded"
          style={{
            boxShadow: "0 0 10px rgba(255, 255, 255, 1)",
          }}
          disabled={isButtonDisabled}
        >
          Sign in
        </button>
      </div>
    </form>
  </div>
</div>

        </div>
      </div>
    </div>
  );
};

export default Login;
