import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Circle, useMap } from 'react-leaflet';
import L from 'leaflet';

// Importing the images
import markerIconPng from 'leaflet/dist/images/marker-icon.png';
import markerShadowPng from 'leaflet/dist/images/marker-shadow.png';

const Geofence = ({ center, radius, zoom, onGeofenceEnter, onGeofenceExit }) => {
  const [insideGeofence, setInsideGeofence] = useState(false);
  const [showGeofence, setShowGeofence] = useState(false);

  const SetViewOnChange = ({ center }) => {
    const map = useMap();
    useEffect(() => {
      if (center) {
        map.setView(center);
        map.setZoom(zoom);
      }
    }, [center, map]);
    return null;
  };

  useEffect(() => {
    if (center && radius && showGeofence) {
      const distance = L.latLng(center).distanceTo(L.latLng(center));
      if (distance <= radius) {
        if (!insideGeofence) {
          setInsideGeofence(true);
          // onGeofenceEnter();
        }
      } else {
        if (insideGeofence) {
          setInsideGeofence(false);
          // onGeofenceExit();
        }
      }
    }
  }, [center, radius, onGeofenceEnter, onGeofenceExit, insideGeofence, showGeofence]);

  useEffect(() => {
    if (center && radius) {
      setShowGeofence(true);
    }
  }, [center, radius]);

  const defaultCenter = [20.5937, 78.9629]; // Default center for India
  const defaultZoom = 5; // Default zoom for India

  const customIcon = L.icon({
    iconUrl: markerIconPng,
    shadowUrl: markerShadowPng,
    iconSize: [25, 41], // size of the icon
    shadowSize: [41, 41], // size of the shadow
    iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
    shadowAnchor: [12, 41],  // the same for the shadow
    popupAnchor: [0, -41] // point from which the popup should open relative to the iconAnchor
  });

  return (
    <MapContainer center={center || defaultCenter} zoom={zoom || defaultZoom} className="h-full w-full">
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <SetViewOnChange center={center || defaultCenter} />
      {showGeofence && center && radius && (
        <>
          <Marker position={center} icon={customIcon} />
          <Circle center={center} radius={radius || 500} />
        </>
      )}
    </MapContainer>
  );
};

export default Geofence;
